/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { postFormData } from "../../../apiGeneralFunction";
import ImgComp from "../../ReusableComp/ImgComp";
import * as Images from '../../../assets';
// import LogInNavbar from "../../ReusableComp/LogInNavbar";
// import Footer from "../../ReusableComp/Layout/Footer";


const OptimizeVerification = () => {

    const numberOfDigits = 4;
    const navigate = useNavigate();

    const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
    const otpRef = useRef(new Array(numberOfDigits).fill(""));
    const otpBoxReference = useRef([]);
    const [timer, setTimer] = useState(60);
    const [isResendEnabled, setIsResendEnabled] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [isResending, setIsResending] = useState(false); // for multiple time resend otp toggle

    const register_number = localStorage?.getItem("register api response");
    const user_phone_no = JSON?.parse(register_number);

    const handleSubmit = async () => {

        const otp = otpRef.current.join("");

        // if (otp === '') {
        //     toast.error('Please enter the  OTP', {
        //         position: 'top-center',
        //         autoClose: 1500,
        //     });
        //     return;
        // }

        if (otp === '') {
            // Show error only if it's not already visible
            if (!errorVisible) {
                setErrorVisible(true); // Set error visible to prevent multiple toasts
                toast.error('Please enter the  OTP', {
                    position: 'top-center',
                    autoClose: 1500,
                });

                // Reset error visibility after 2 seconds
                setTimeout(() => setErrorVisible(false), 2000);
            }
            return;
        }

        const register_number = localStorage?.getItem("register api response");
        const user_phone_no = JSON?.parse(register_number);


        const formdata = new FormData();
        formdata.append("country_code", "+91");
        formdata.append("phone", user_phone_no);
        formdata.append("verification_code", otp);
        formdata.append("device_token", "");
        formdata.append("device_type", "Web");
        formdata.append("api_version", "");
        formdata.append("app_version", "");
        formdata.append("os_version", "");
        formdata.append("device_model_name", "");
        formdata.append("app_language", "");

        try {

            const responsePromise = postFormData('api/v1/verifyotp', formdata);


            toast.dismiss();

            const response = await toast.promise(
                responsePromise,
                {
                    // pending: {
                    //     render() {
                    //         return "Sending OTP...";
                    //     },
                    //     position: 'top-center',
                    //     autoClose: false,
                    // },
                    success: {
                        render({ data }) {
                            toast.dismiss();
                            if (data.status === false) {
                                throw new Error(data.message);
                            }
                            return data.message || "OTP sent successfully!";
                        },
                        position: 'top-center',
                        autoClose: 2000,
                    },
                    error: {
                        render({ data }) {
                            return data?.message || "Failed to send OTP.";
                        },
                        position: 'top-center',
                        autoClose: 1500,
                    }
                }
            );

            // Debugging response
            console.log("API Response: ", response);

            if (response.status === true) {
                if (response.data.status === "deactive") {
                    // User is deactivated
                    // toast.error("Your account is deactivated. Redirecting...", {
                    //   position: 'top-center',
                    //   autoClose: 3000,
                    // });
                    localStorage.removeItem("login api response token"); // Remove token from storage
                    localStorage.removeItem("user_data"); // Remove user data for safety
                    // localStorage.setItem("login api response token", JSON.stringify(response?.data?.token));
                    // localStorage.setItem("user_data", JSON.stringify(response?.data));

                    setTimeout(() => {
                        navigate('/deactive_user'); // Ensure this route exists
                    }, 3000);
                } else {
                    // User is active
                    localStorage.setItem("login api response token", JSON.stringify(response?.data?.token));
                    localStorage.setItem("user_data", JSON.stringify(response?.data));

                    // toast.success("Login successful!", {
                    //   position: 'top-center',
                    //   autoClose: 3000,
                    // });

                    setTimeout(() => {
                        navigate('/location'); // Redirect to location page
                    }, 3000);
                }
            }
        } catch (error) {
            toast.dismiss();
            toast.error("An unexpected error occurred. Please try again.", {
                position: 'top-center',
                autoClose: 3000,
            });
        }
    };


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //     timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
    //     console.log(timer, "timer");
    //   }, [timer]);

    useEffect(() => {
        // Run countdown only if timer is above 0
        if (timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        } else {
            // Enable resend when timer reaches 0
            setIsResendEnabled(true);
        }
    }, [timer]);

    const handleResend = async () => {
        if (isResending) return;

        const register_number = localStorage?.getItem("register api response");
        const user_phone_no = JSON?.parse(register_number);

        const formdata = new FormData();
        formdata.append("country_code", "+91");
        formdata.append("phone", user_phone_no);
        formdata.append("device_token", "");
        formdata.append("device_type", "Web");
        formdata.append("api_version", "");
        formdata.append("app_version", "");
        formdata.append("os_version", "");
        formdata.append("device_model_name", "");
        formdata.append("app_language", "");

        try {

            setIsResending(true);

            const responsePromise = postFormData('api/v1/sendotp', formdata);

            await toast.promise(
                responsePromise,
                {
                    // pending: "Resending OTP...",
                    success: {
                        render({ data }) {
                            if (data.status === false) {
                                throw new Error(data.message);
                            }
                            return data.message || "OTP resent successfully!";
                        },
                        position: 'top-center',
                        autoClose: 2000,
                    },
                    error: {
                        render({ data }) {
                            return data?.message || "Failed to resend OTP.";
                        },
                        position: 'top-center',
                        autoClose: 1500,
                    }
                }
            );

            // Reset timer to 60 seconds and disable the resend button
            setTimer(60);
            setIsResendEnabled(false);

        } catch (error) {
            toast.error("An unexpected error occurred while resending the OTP. Please try again.", {
                position: 'top-center',
                autoClose: 3000,
            });
        }
        // finally {
        //     // Reset the resending flag after completion
        //     setIsResending(false);
        // }

    };



    const handleChange = useCallback((value, index) => {
        otpRef.current[index] = value;

        if (value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }, [numberOfDigits]);

    const handleBackspaceAndEnter = useCallback((e, index) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus();
        }
        if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }, [numberOfDigits]);

    const handleKeyPress = useCallback((e) => {
        if (e?.key === 'Enter') {
            handleSubmit();
        }
    }, [handleSubmit]);

    const OTPVerificationImage = useCallback(() => (
        <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/33182bb4a34d51254c94257e2b3e52809ccb4863687eb854d868e5b9fc8a3488?apiKey=8e5983036f284a13866aae97819cdb73&"
            alt="OTP Verification"
            className="max-w-full aspect-[0.99] w-[114px]"
        />
    ), [Images]);

    const PolicyLinks = () => (
        <>
            <div className="flex gap-5 justify-between self-stretch mt-5 text-sm text-center text-neutral-400 max-lg:flex-wrap max-lg:max-w-full">
                <a href="/#/privacy-policy">Privacy Policy</a>
                <a href="/#/terms-conditions">Terms &amp; Conditions</a>
            </div>
            <span className="text-red-800 text-sm mt-5">Powered by Headway Business Solutions LLP</span>
        </>
    );

    const ImageSection = useCallback(() => (
        <>

            <ImgComp
                src={Images.verification_otp}
                srcSet={Images.verification_otp}
                alt={'verification otp'}
                className={'h-full w-full rounded-r-2xl max-lg:rounded-t-none max-lg:rounded-b-2xl'}
            />

        </>
    ), [Images]);

    return (
        <>
            {/* <LogInNavbar /> */}
            <div className="flex justify-center items-center px-16 py-28 bg-white max-lg:px-5">
                <div className="max-w-full rounded-2xl shadow-2xl w-[1096px]">
                    <div className="flex gap-5 max-lg:flex-col max-lg:gap-0 ">
                        <div className="flex flex-col w-6/12 max-lg:ml-0 max-lg:w-full hide-at-1021px">
                            <ImageSection />
                        </div>
                        <div className="flex flex-col w-6/12 max-lg:ml-0 max-lg:w-full  rounded-2xl">

                            <div className="flex flex-col grow justify-between rounded-2xl items-center self-stretch px-10 py-6 w-full bg-white max-lg:px-5 max-lg:max-w-full">
                                <OTPVerificationImage />
                                <h1 className="mt-2 text-3xl leading-10 text-center ">
                                    OTP <span className="text-red-700 font-bold">Verification</span>
                                </h1>
                                <p className="mt-2 text-base leading-6 text-center text-neutral-400 max-lg:max-w-full">
                                    The confirmation code was sent on {user_phone_no}
                                </p>

                                <div className="flex gap-5 self-stretch px-20 mt-4 max-lg:flex-wrap max-lg:px-5 items-center justify-center">
                                    <div className='flex items-center gap-4'>
                                        {otpRef.current.map((digit, index) => (
                                            <input
                                                type="text"
                                                pattern="^[0-9]"
                                                key={index}
                                                defaultValue={digit}
                                                maxLength={1}
                                                onChange={(e) => handleChange(e.target.value, index)}
                                                onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                                inputMode="numeric"
                                                onKeyPress={(e) => {
                                                    const charCode = e.which || e.keyCode;
                                                    if (charCode < 48 || charCode > 57) {
                                                        e.preventDefault();
                                                    }
                                                    handleKeyPress(e);
                                                }}
                                                ref={(reference) => (otpBoxReference.current[index] = reference)}
                                                className={`border w-16 h-16 max-sm:w-12 max-sm:h-12 text-center text-black p-3 rounded-md block bg-white focus:border-2 focus:outline-none appearance-none border border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] focus:border-gray-200 focus:ring-0`}
                                                autoFocus={index === 0}
                                            />
                                        ))}
                                    </div>
                                </div>

                                {/* <p className="mt-5 text-base leading-6 text-neutral-900">
                                    <span className="font-medium text-neutral-400">Code expires in: </span>{" "}
                                    <span className="font-medium text-neutral-900">{timer} seconds</span>
                                </p>
                                <p className="mt-5 text-base leading-6 text-red-700">
                                    <span className="font-medium text-neutral-400">Dont get the code? </span>{" "}
                                    <button

                                        // onClick={handleResend}
                                        className="font-medium text-red-700 ">
                                        Resend
                                    </button>
                                </p>
                                <button

                                    onClick={handleSubmit}
                                    className=" justify-center items-center px-16 py-4 mt-5 max-w-full text-lg font-medium text-center text-white whitespace-nowrap bg-red-700 rounded max-lg:px-5 max-lg:py-2">
                                    Verify
                                </button> */}
                                <p className="mt-3 text-base leading-6 text-neutral-900">
                                    <span className="font-medium text-neutral-400">Code expires in: </span>
                                    <span className="font-medium text-neutral-900">{timer} seconds</span>
                                </p>
                                <p className="mt-3 text-base leading-6 text-red-700">
                                    <span className="font-medium text-neutral-400">Don't get the code? </span>
                                    <button
                                        onClick={handleResend}
                                        disabled={!isResendEnabled} // Disable button if resend is not allowed
                                        className={`font-medium text-red-700 ${!isResendEnabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
                                        Resend
                                    </button>
                                </p>
                                <button
                                    onClick={handleSubmit}
                                    className="justify-center items-center px-16 py-4 mt-3 max-w-full text-lg font-medium text-center text-white whitespace-nowrap bg-red-700 rounded max-lg:px-5 max-lg:py-2">
                                    Verify
                                </button>



                                <PolicyLinks />
                            </div>
                        </div>
                        {/* <div className="flex flex-col ml-5 w-6/12 max-lg:ml-0 max-lg:w-full  hidden sm:block">
                            <ImageSection />
                        </div> */}
                    </div>
                </div>
                <ToastContainer />
            </div>
            {/* <Footer /> */}
        </>
    );
}

export default OptimizeVerification;