import React from 'react'
import FAQAccordianSection from '../FAQAccordianSection'


const FAQContent = () => {
    return (
        <>
            <FAQAccordianSection />
        </>
    )
}

export default FAQContent