import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const PrivacyImgSection = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5 max-md:px-5`}>
                <div className='flex flex-row max-md:flex-col'>
                    <div className='my-auto mx-6'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            Fulfilment By MyBzB
                        </div>
                        <div className="my-2 text-4xl font-bold capitalize text-neutral-900 max-md:text-3xl  home_heading  ">
                        Fulfilment By MyBzB:
                        </div>
                        <HeadingTextImageWrapper
                        text={`Elevating Your Jewellery`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />
                        <div className="my-2 text-4xl font-bold capitalize text-neutral-900 max-md:text-3xl  home_heading  ">
                        Business
                        </div>
                    
                        <div className="mt-6 text-lg  max-md:max-w-full">
                        Welcome to{" "}
                            <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar (MyBzB)
                                </span>
                            , a comprehensive solution designed to empower jewellers and enhance their online selling experience. {" "}
                            <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar (MyBzB)
                                </span> takes pride in introducing this innovative programme, bringing convenience, efficiency, and global reach to your jewellery business.
                        </div>
                    </div>    
                    <div className='footer-fulfilment-image'>
                        <ImgComp
                        src={Images.fulfilment_by_mybzb}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default PrivacyImgSection