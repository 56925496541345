import React from 'react'
import SellerGuideImgSection from '../SellerGuideImgSection'
import SellerGuideTextSection from '../SellerGuideTextSection'
import { width } from '../../../ReusableComp/Buttons'


const SellerGuideContent = () => {
    return (
        <>

            <SellerGuideImgSection />
            <div className="flex flex-col py-10">
                <div className={`flex flex-col items-start self-center px-5 mt-5 w-full max-w-${width} max-md:max-w-full mx-auto`}>
                <SellerGuideTextSection />
                </div>
            </div>
        </>
    )
}

export default SellerGuideContent