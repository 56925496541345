import React from 'react'
import FulfilmentByMyBzBImgSection from '../FulfilmentByMyBzBImgSection'
import FulfilmentByMyBzBTextSection from '../FulfilmentByMyBzBTextSection'
import { width } from '../../../ReusableComp/Buttons'


const FulfilmentByMyBzBContent = () => {
    return (
        <>

            <FulfilmentByMyBzBImgSection />
            <div className="flex flex-col py-10">
                <div className={`flex flex-col items-start self-center px-5 mt-5 w-full max-w-${width} max-md:max-w-full mx-auto`}>
                <FulfilmentByMyBzBTextSection />
                </div>
            </div>
        </>
    )
}

export default FulfilmentByMyBzBContent