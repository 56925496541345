import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const TermsImgCondition = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5  max-md:px-5`}>
                <div className='flex flex-row max-md:flex-col'>
                    <div className='my-auto mx-6'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                           Terms & Conditions
                        </div>
                        <HeadingTextImageWrapper
                        text={`Terms Of Service:`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />
                        <div className="my-2  text-4xl font-bold capitalize text-neutral-900 max-md:text-3xl  home_heading  ">
                            Your Use Of Our Platform
                        </div>
                        
                        <div className="mt-6 text-lg  max-md:max-w-full">
                        Welcome to{" "}
                            <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar (MyBzB)!
                                </span>{" "}
                                 Before using our website, please carefully read the following Terms and Conditions. 
                                By accessing or using MyBzB, you agree to comply with and be bound by these terms. 
                                If you do not agree to these terms, please refrain from using our website.
                        </div>
                    </div>    
                    <div className='footer-terms-image'>
                        <ImgComp
                        src={Images.terms_condition}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default TermsImgCondition