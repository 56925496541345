import React from 'react'
import AboutImgSection from '../AboutImgSection'
import { width } from '../../../ReusableComp/Buttons'


const AboutMyBzBContent = () => {
    return (
        <>
            <AboutImgSection />
        </>
    )
}

export default AboutMyBzBContent