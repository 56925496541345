import React from 'react';
import { useNavigate } from 'react-router-dom';


const FulfilmentByMyBzBTextSection = () => {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/');
    };
    return (
        <>

            <div className="flex  flex-col py-5 ">
            <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">What is Fulfilment by</span> MyBzB?
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                    Fulfilment by MyBzB is a service that enables jewellers to streamline their order fulfilment process. By leveraging our extensive infrastructure and resources, you can entrust us with the storage, packaging, and shipping of your jewellery, allowing you to focus on what you do best – crafting exquisite pieces.
                    </div>
                </div>
                <div className="flex flex-col mt-12 max-md:mt-10 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Key Features of Fulfilment by </span> MyBzB
                    </div>
                    <div className="w-full flex flex-col grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Warehousing and Storage</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Store your inventory in our state-of-the-art warehouses, equipped with advanced security measures and climate control to ensure the safety of your precious jewellery.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>

                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Efficient Order Processing</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Benefit from our efficient order processing system. As soon as an order is placed, our dedicated team ensures prompt pick-and-pack processes, minimizing turnaround time.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Global Reach</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Extend your reach globally with Fulfilment by{" "}<span className="font-semibold text-red-700">MyBzB</span>. We facilitate international shipping, allowing your jewellery to captivate audiences around the world.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col p-6 bg-white rounded-xl border border-gray-200 border-solid  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Professional Packaging</span>
                                    </div>
                                    <div className="flex-1 shrink gap-2.5 mt-3 w-full text-base text-zinc-600">
                                    Your jewellery deserves to make a statement. Our professional packaging ensures that each piece is presented in a way that reflects its elegance and craftsmanship.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col p-6 bg-white rounded-xl border border-gray-200 border-solid  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Shipping Logistics</span>
                                    </div>
                                    <div className="flex-1 shrink gap-2.5 mt-3 w-full text-base text-zinc-600">
                                    We handle the intricacies of shipping logistics, from choosing the best carriers to managing customs clearance for international orders. Your customers receive their orders seamlessly.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Real-time Inventory Management</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Keep track of your inventory in real-time through our user-friendly dashboard. Stay informed about stock levels and make informed decisions to manage your business effectively.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Customer Service Support</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Our dedicated customer service team is here to assist with any queries or concerns related to orders fulfilled by{" "}
                                    <span className="font-semibold text-red-700">MyBzB</span>. Your customers receive the same excellent support they expect from MyBzB.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold max-md:max-w-full">
                    How Fulfilment by <span className="text-red-700">MyBzB Works</span>
                    </div>
                    <div className="flex flex-col mt-6 w-full max-md:max-w-full gap-5">
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>&nbsp; Enrollment
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                                Jewellers can enroll in the Fulfilment by {" "}
                                <span className="font-semibold text-red-700">MyBzB</span> programme through our easy-to-use platform.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>&nbsp; Send Your Inventory
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                                Ship your inventory to our designated fulfilment centres. Our team receives, stores, and manages your jewellery in preparation for fulfilment.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>&nbsp; List Your Products
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                                List your jewellery on the {" "}<span className="font-semibold text-red-700">MyBzB</span> platform, specifying that you want to use Fulfilment by {" "}
                                <span className="font-semibold text-red-700">MyBzB</span>.  
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>&nbsp; Orders Processed Automatically
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                                When a customer places an order, our system automatically processes it, and our team picks, packs, and ships the order on your behalf.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>&nbsp; Real-time Monitoring
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                                Monitor your inventory levels, sales, and other crucial metrics through our intuitive dashboard.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 max-md:mt-10 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Benefits of Fulfilment by </span> MyBzB
                    </div>
                    <div className="w-full flex flex-col grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Time and Cost Efficiency</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Save time and reduce operational costs associated with warehousing, packaging, and shipping.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>

                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Global Expansion</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Tap into new markets worldwide and grow your customer base globally.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Professional Presentation</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Present your jewellery professionally with our expert packaging, enhancing the overall customer experience.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col p-6 bg-white rounded-xl border border-gray-200 border-solid  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Focus on Creativity</span>
                                    </div>
                                    <div className="flex-1 shrink gap-2.5 mt-3 w-full text-base text-zinc-600">
                                    Spend more time on what you love – crafting beautiful jewellery – while we handle the logistics.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold">
                        <span className="text-neutral-900">Join Fulfilment by</span>MyBzB Today
                    </div>
                    <div className='flex flex-row mt-5 items-start gap-2 '>
                        <i className="fa fa-circle-o mybzb_text_clr mt-1 text-xs" ></i>
                        <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                        Elevate your jewellery business to new heights with Fulfilment by <span className="font-semibold text-red-700">MyBzB</span>{" "}. Take advantage of our end-to-end solution, allowing you to focus on your craft while we take care of the rest. To enroll or learn more about Fulfilment by <span className="font-semibold text-red-700">MyBzB</span>{" "}, please contact our dedicated team through the <span className="font-semibold text-red-700">'Contact Us'</span>{" "}page.
                        </div>
                    </div>
                    <div className="mt-4 text-lg max-md:max-w-full text-neutral-900">
                    <span className="font-semibold text-red-700">My Bharatiya Zaveri Bazaar</span> is committed to supporting jewellers in achieving their business goals. Join us in the next evolution of your online selling journey!
                    </div>
                </div>
                <div className="flex gap-5 mt-6 me-4 mb-16 my-auto" >
                    <button className="focus:border-red-800 justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 me-2" onClick={handleHome}>
                        Back to Home<i className="fa fa-arrow-right ml-2" style={{ color: '#ffffff' }}></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default FulfilmentByMyBzBTextSection