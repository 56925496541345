import React from 'react'
import { useNavigate } from 'react-router-dom';


const CancellationReturnTextSection = () => {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/');
    };
    return (
        <>

            <div className="flex  flex-col py-7 ">
                <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Order</span> Cancellation
                    </div>
                    <div
                        className='grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-3 mt-6'
                    >
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; Cancellation Period
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                You can cancel your order within{" "}
                                <span className="font-semibold text-red-700">24 hours</span> of placing it.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Please note that some products may not be eligible for cancellation due to their bespoke or personalized nature.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; How to Cancel
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Go to your{" "}
                                    <span className="font-semibold text-red-700">
                                    "Order History"
                                    </span> in your MyBzB account.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Locate the specific order you wish to cancel.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Click on the{" "}
                                    <span className="font-semibold text-red-700">
                                    "Cancel"
                                    </span> button and follow the prompts.
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">3.</span>&nbsp; Cancellation Confirmation
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Upon successful cancellation, you will receive a{" "}
                                    <span className="font-semibold text-red-700">
                                    confirmation email
                                    </span>
                                    .
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Refund details will be provided in accordance with{" "}
                                    <span className="font-semibold text-red-700">
                                    our refund process
                                    </span>
                                    .
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold max-md:max-w-full">
                        Return and <span className="text-red-700">Refund</span>
                    </div>
                    <div className="flex flex-col mt-6 w-full max-md:max-w-full gap-5">
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; Eligibility for Return
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                You may initiate a return within 15 days of receiving your order.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Returns are accepted for items that are damaged, defective, or significantly different from the product description.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Non-Returnable Items
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Personalized or bespoke items may not be eligible for return unless damaged or defective.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Earrings (for hygiene reasons) are non-returnable unless they arrive damaged or defective.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">3.</span>&nbsp; How to Initiate a Return
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Log in to your{" "}
                                    <span className="font-semibold text-red-700">
                                    MyBzB
                                    </span> account and go to{" "}
                                    <span className="font-semibold text-red-700">
                                    "Order History."
                                    </span> 
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Select the order containing the{" "}
                                <span className="font-semibold text-red-700">item(s)</span> you wish to return.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Click on{" "}<span className="font-semibold text-red-700">"Return Item"</span> and follow the instructions.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">4.</span>&nbsp; Return Confirmation
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Once your{" "}
                                    <span className="font-semibold text-red-700">
                                    return request
                                    </span> is approved, you will receive a confirmation email.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Detailed instructions for{" "}
                                <span className="font-semibold text-red-700">returning</span> the item(s) will be provided.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">5.</span>&nbsp; Return Shipping
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-semibold text-red-700">MyBzB</span>{" "}may provide a prepaid shipping label for eligible returns.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Follow the provided instructions for{" "}
                                    <span className="font-semibold text-red-700">secure packaging</span>.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">6.</span>&nbsp; Refund Process
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Refunds will be processed within{" "}
                                <span className="font-semibold text-red-700">20 business days</span> of receiving the returned item.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Refunds will be issued to the{" "}
                                    <span className="font-semibold text-red-700">original payment method</span>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full mt-12 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Additional</span> Information 
                    </div>
                    <div
                        className='grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-3 mt-6'
                    >
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; Condition of Returned Items
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Items must be returned in their original condition with all tags and packaging intact.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                <span className="font-semibold text-red-700">
                                    MyBzB
                                    </span> reserves the right to reject returns that do not meet these conditions.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Damaged or Defective Items
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                If you receive a damaged or defective item, contact our Customer Care team within{" "}
                                    <span className="font-semibold text-red-700">
                                    48 hours
                                    </span> of receiving the order.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Provide clear images of the damaged or defective item for assessment.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">3.</span>&nbsp; Non-Receipt of Refund
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                If you have not received your refund within the specified period, contact our Customer Care team for assistance.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold">
                        <span className="text-red-700">Contact Us </span>
                    </div>
                    <div className='flex flex-row mt-5 items-start gap-2 '>
                        <i className="fa fa-circle-o mybzb_text_clr mt-1 text-xs" ></i>
                        <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                        For inquiries or to learn more about building your jewellery brand with <span className="font-semibold text-red-700">MyBzB</span>{" "}and accessing exclusive benefits from <span className="font-semibold text-red-700">Headway Business Solutions LLP</span>{" "}, please contact our Seller Support team through the <span className="font-semibold text-red-700">'Contact Us'</span>{" "}page.
                        </div>
                    </div>
                    <div className="mt-4 text-lg text-base text-zinc-600 max-md:max-w-full">
                    <span className="font-semibold text-red-700">My Bharatiya Zaveri Bazaar</span> is committed to delivering not just jewellery but a delightful experience to your doorstep. Thank you for choosing <span className="font-semibold text-red-700">MyBzB</span> for your jewellery needs. <span className="font-semibold text-red-700">Happy shopping!</span> 
                    </div>
                </div>
                <div className="flex gap-5 mt-6 me-4 mb-8 my-auto" >
                    <button className="focus:border-red-800 justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 me-2" onClick={handleHome}>
                        Back to Home<i className="fa fa-arrow-right ml-2" style={{ color: '#ffffff' }}></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default CancellationReturnTextSection