export const BgButton = ({name,onClick}) => {
    return (
        <button className="focus:border-red-800  justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1"
        onClick={onClick}
        >
            {name} 
        </button> 
    )
}

export const OutlineButton = ({name,onClick}) => {
    return (
        <button className="focus:border-red-800 self-center mt-4 text-sm font-semibold leading-5 text-red-700"
        onClick={onClick}
        >
            {name}
        </button>
    )
}

export const TransperentButton = ({name,onClick}) => {
    return (
        <button className="focus:border-red-800  justify-center px-12 py-2 text-lg font-bold text-red-900 bg-red-100 rounded max-lg:px-5 max-lg:mx-1"
        onClick={onClick}
        >
            {name}
        </button>
    )
}

export const width = "[1296px]"