import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import StarRating from '../../ReusableComp/StarRating';
import * as Images from '../../../assets';
import { fetchWithFormDataToken } from '../../../apiGeneralFunction';
import { TwoBreadcrumb } from '../../ReusableComp/Breadcrumb';
import Layout from '../../ReusableComp/Layout';
import { width } from '../../ReusableComp/Buttons';
import ImgComp from '../../ReusableComp/ImgComp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastPromise from '../../../services/toastService';

const WishlistPage = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
        if (!storedToken) {
            navigate('/register');
            return;
        }

        const user_id = localStorage?.getItem('user_data');
        const userId = JSON.parse(user_id);

        const abortController = new AbortController();

        try {
            const formdata = new FormData();
            formdata.append("user_id", userId?.user_id);

            const result = await fetchWithFormDataToken(
                `api/v1/list_wishlist`,
                formdata,
                storedToken,
                abortController,
                null
            );

            console.log("new api", result?.data?.productData);
            if (result?.status === true) {
                // Filter duplicate entries (if applicable)
                const uniqueWishlist = result?.data?.productData?.reduce((acc, item) => {
                    if (!acc.some((product) => product.product_id === item.product_id)) {
                        acc.push(item);
                    }
                    return acc;
                }, []);
                console.log("Filtered uniqueWishlist:", uniqueWishlist);

                setData(uniqueWishlist); // Save unique wishlist data
            } else {
                if (isEmptyObject(result?.data) || result?.data === null) {
                    setData(null);
                }
            }
        } catch (error) {
            console.error('Error fetching wishlist:', error);
        } finally {
            abortController.abort();
        }
    };


    // const fetchData = async () => {

    //     const tokenValue = localStorage?.getItem('login api response token');
    //     const storedToken = JSON?.parse(tokenValue);
    //     if (!storedToken) {
    //         navigate('/register');
    //         // return;
    //     }

    //     const user_id = localStorage?.getItem('user_data');
    //     const userId = JSON.parse(user_id);

    //     const abortController = new AbortController();

    //     try {

    //         const formdata = new FormData();
    //         formdata.append("user_id", userId?.user_id);

    //         const result = await fetchWithFormDataToken(`api/v1/list_wishlist`, formdata, storedToken, abortController, null);
    //         if (result?.status === true) {
    //             setData(result?.data)
    //         }
    //         else {
    //             if (isEmptyObject(result?.data) || result?.data === null) {
    //                 setData(null);
    //             }
    //         }
    //     } catch (error) {
    //     }
    //     finally {
    //         abortController.abort();
    //     }
    // };



    const isEmptyObject = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };


    // const removeData = async (id) => {

    //     const tokenValue = localStorage?.getItem('login api response token');
    //     const storedToken = JSON?.parse(tokenValue);
    //     if (!storedToken) {
    //         navigate('/');
    //         // return;
    //     }

    //     const user_id = localStorage?.getItem('user_data');
    //     const userId = JSON.parse(user_id);
    //     const abortController = new AbortController();
    //     try {
    //         const formdata = new FormData();
    //         formdata.append("user_id", userId?.user_id);
    //         formdata.append("product_id", id);
    //         formdata.append("is_wish", "0");
    //         formdata.append("device_token", "");
    //         formdata.append("device_type", "Web");
    //         formdata.append("api_version", "");
    //         formdata.append("app_version", "");
    //         formdata.append("os_version", "");
    //         formdata.append("device_model_name", "");

    //         const result = await fetchWithFormDataToken(`api/v1/remove_wishlist`,storedToken, formdata, abortController, null);
    //         if (result?.status === true) {
    //             toast.success(result?.message || '', {
    //                 position: 'top-center',
    //                 autoClose: 2000,
    //             });
    //             fetchData();
    //             if (isEmptyObject(data) || data === null) {
    //                 setData(null);
    //             }
    //         }
    //         else {
    //             toast.error(result?.message || '', {
    //                 position: 'top-center',
    //                 autoClose: 2000,
    //             });
    //         }
    //         // const response = await toastPromise(
    //         //     fetchWithFormDataToken('api/v1/remove_wishlist', formdata, abortController, null),
    //         //     {
    //         //         pending: "Removing Product...",
    //         //         success: (data) => data.message || "Product is removed",
    //         //         error: (data) => data.message || "Failed to remove product",
    //         //     }
    //         // );

    //         // if (response.status === true) {
    //         //     fetchData();
    //         //     if (isEmptyObject(data) || data === null) {
    //         //         setData(null);
    //         //     }
    //         // }
    //     } catch (error) {

    //     }
    //     finally {

    //         abortController.abort();
    //     }
    // };

    // const removeData = async (id) => {
    //     const tokenValue = localStorage?.getItem('login api response token');
    //     const storedToken = JSON?.parse(tokenValue);

    //     if (!storedToken) {
    //         navigate('/');
    //         return;
    //     }

    //     const user_id = localStorage?.getItem('user_data');
    //     const userId = JSON.parse(user_id);
    //     const abortController = new AbortController();

    //     // Define a unique toast ID for loading toast
    //     const toastId = `remove-toast-${id}`;

    //     try {
    //         // Show a loading toast
    //         toast.loading('Removing product...', { toastId });

    //         const formdata = new FormData();
    //         formdata.append("user_id", userId?.user_id);
    //         formdata.append("product_id", id);
    //         formdata.append("is_wish", "0");
    //         formdata.append("device_token", "");
    //         formdata.append("device_type", "Web");
    //         formdata.append("api_version", "");
    //         formdata.append("app_version", "");
    //         formdata.append("os_version", "");
    //         formdata.append("device_model_name", "");

    //         const result = await fetchWithFormDataToken(
    //             `api/v1/remove_wishlist`,
    //             formdata,
    //             storedToken,
    //             abortController,
    //             null
    //         );

    //         // Update state and dismiss the loading toast
    //         toast.dismiss(toastId);

    //         if (result?.status === true) {
    //             // Show success toast
    //             toast.success(result?.message || 'Product removed successfully', {
    //                 position: 'top-center',
    //                 autoClose: 2000,
    //             });

    //             // Update state to remove the product
    //             setData((prevData) => ({
    //                 ...prevData,
    //                 productData: prevData?.productData?.filter((product) => product.product_id !== id),
    //             }));
    //         } else {
    //             // Show error toast
    //             toast.error(result?.message || 'Failed to remove product', {
    //                 position: 'top-center',
    //                 autoClose: 2000,
    //             });
    //         }
    //     } catch (error) {
    //         console.error('Error removing product:', error);

    //         // Dismiss loading toast and show error toast
    //         toast.dismiss(toastId);
    //         toast.error('An unexpected error occurred.', {
    //             position: 'top-center',
    //             autoClose: 2000,
    //         });
    //     } finally {
    //         // Abort the request if needed
    //         abortController.abort();
    //     }
    // };

    const removeData = async (id) => {
        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
        if (!storedToken) {
            navigate('/');
            return;
        }
    
        const user_id = localStorage?.getItem('user_data');
        const userId = JSON.parse(user_id);
        const abortController = new AbortController();
    
        try {
            const formdata = new FormData();
            formdata.append("user_id", userId?.user_id);
            formdata.append("product_id", id);
            formdata.append("is_wish", "0");
            formdata.append("device_token", "");
            formdata.append("device_type", "Web");
            formdata.append("api_version", "");
            formdata.append("app_version", "");
            formdata.append("os_version", "");
            formdata.append("device_model_name", "");
    
            const result = await fetchWithFormDataToken(
                `api/v1/remove_wishlist`,
                formdata,
                storedToken,
                abortController,
                null
            );
    
            toast.dismiss();
            if (result?.status === true) {
                // Display success toast
                toast.success(result?.message || 'Product removed successfully', {
                    position: 'top-center',
                    autoClose: 2000,
                    onClose: () => {
                        // Optional: Reset or update state after toast closes
                    }
                });
    
                // Update state by filtering out the removed product
                setData((prevData) => {
                    console.log('Before update:', prevData);
    
                    const updatedData = prevData.filter(
                        (product) => product.product_id !== id
                    );
    
                    console.log('Updated data:', updatedData);
                    return updatedData;
                });
            } else {
                toast.error(result?.message || 'Failed to remove product', {
                    position: 'top-center',
                    autoClose: 2000,
                });
            }
        } catch (error) {
            console.error('Error removing product:', error);
            toast.dismiss();
        } finally {
            abortController.abort();
        }
    };
    

    // const removeData = async (id) => {
    //     const tokenValue = localStorage?.getItem('login api response token');
    //     const storedToken = JSON?.parse(tokenValue);
    //     if (!storedToken) {
    //         navigate('/');
    //         return;
    //     }

    //     const user_id = localStorage?.getItem('user_data');
    //     const userId = JSON.parse(user_id);
    //     const abortController = new AbortController();

    //     try {
    //         const formdata = new FormData();
    //         formdata.append("user_id", userId?.user_id);
    //         formdata.append("product_id", id);
    //         formdata.append("is_wish", "0");
    //         formdata.append("device_token", "");
    //         formdata.append("device_type", "Web");
    //         formdata.append("api_version", "");
    //         formdata.append("app_version", "");
    //         formdata.append("os_version", "");
    //         formdata.append("device_model_name", "");

    //         const result = await fetchWithFormDataToken(
    //             `api/v1/remove_wishlist`,
    //             formdata,
    //             storedToken,
    //             abortController,
    //             null
    //         );

    //         toast.dismiss();
    //         if (result?.status === true) {
    //             // Display success toast
    //             toast.success(result?.message || 'Product removed successfully', {
    //                 position: 'top-center',
    //                 autoClose: 2000,
    //             });

    //             // Log data before updating
    //             setData((prevData) => {
    //                 console.log('Before update:', prevData);

    //                 // Ensure data and productData exist
    //                 if (!prevData || !prevData.productData) {
    //                     console.error('Data structure is invalid:', prevData);
    //                     return prevData;
    //                 }

    //                 const updatedData = {
    //                     ...prevData,
    //                     productData: prevData.productData.filter(
    //                         (product) => product.product_id !== id
    //                     ),
    //                 };

    //                 console.log('Updated data:', updatedData);
    //                 return updatedData;
    //             });
    //         } else {
    //             toast.error(result?.message || 'Failed to remove product', {
    //                 position: 'top-center',
    //                 autoClose: 2000,
    //             });
    //         }
    //     } catch (error) {
    //         console.error('Error removing product:', error);
    //     } finally {
    //         abortController.abort();
    //     }
    // };



    // const removeData = async (id) => {
    //     const tokenValue = localStorage?.getItem('login api response token');
    //     const storedToken = JSON?.parse(tokenValue);
    //     if (!storedToken) {
    //         navigate('/');
    //         return;
    //     }

    //     const user_id = localStorage?.getItem('user_data');
    //     const userId = JSON.parse(user_id);
    //     const abortController = new AbortController();
    //     try {
    //         const formdata = new FormData();
    //         formdata.append("user_id", userId?.user_id);
    //         formdata.append("product_id", id);
    //         formdata.append("is_wish", "0");
    //         formdata.append("device_token", "");
    //         formdata.append("device_type", "Web");
    //         formdata.append("api_version", "");
    //         formdata.append("app_version", "");
    //         formdata.append("os_version", "");
    //         formdata.append("device_model_name", "");

    //         const result = await fetchWithFormDataToken(
    //             `api/v1/remove_wishlist`,
    //             formdata,
    //             storedToken,
    //             abortController,
    //             null
    //         );
    //         toast.dismiss();
    //         if (result?.status === true) {
    //             // Display success toast
    //             toast.success(result?.message || 'Product removed successfully', {
    //                 position: 'top-center',
    //                 autoClose: 2000,
    //             });

    //             // Update local state to remove the product card
    //             setData((prevData) => ({
    //                 ...prevData,
    //                 productData: prevData?.productData?.filter((product) => product.product_id !== id),
    //             }));
    //         } else {
    //             toast.error(result?.message || 'Failed to remove product', {
    //                 position: 'top-center',
    //                 autoClose: 2000,
    //             });
    //         }
    //     } catch (error) {
    //         console.error('Error removing product:', error);
    //     } finally {
    //         abortController.abort();
    //     }
    // };





    const ProductCard = ({ product_id, is_favorite, seller_id, product_image, name, price, selling_price, discount, total_review, rating_avg, total_rating, id, discount_type, weight }) => {


        return (
            <>
                <div
                // className="cursor-pointer"
                // onClick={() => navigate('/product_detail', { state: { id } })}
                >
                    <div className="relative flex flex-col  max-md:ml-0 max-md:w-full h-full">

                        <div
                            className=" flex justify-between flex-col grow mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid hover:shadow-2xl hover:border-0 max-md:pl-5 max-md:mt-6"

                        >

                            <div className='absolute flex justify-end w-full  right-0'>
                                {is_favorite !== false ?
                                    <i
                                        className="fa fa-heart text-red-800 text-lg pe-2 pt-2 cursor-pointer z-3"
                                        onClick={() => removeData(product_id)}
                                    ></i>

                                    :
                                    <i className="fa fa-heart-o text-red-800 text-lg pe-2 pt-2"></i>
                                }

                            </div>


                            <div className=" p-5 flex  flex-col h-full cursor-pointer" onClick={() => navigate('/product_detail', { state: { id: product_id || id } })}>
                                <div

                                >
                                    <ImgComp
                                        src={product_image}
                                        alt={name}
                                        className="object-contain xl:w-full  xl:h-32 2xl:w-full 2xl:h-32 max-sm:w-40 max-sm:h-40 md:w-48 md:h-48 lg:w-full lg:h-40 max-sm:mx-auto"
                                    />
                                </div>
                                <div className="  flex  flex-col h-full pt-0">
                                    <div className="mt-2.5 text-lg font-semibold text-neutral-900 w-full md:text-base">
                                        {name}
                                    </div>
                                    <div className="flex gap-2 justify-between mt-2">
                                        <div className="text-lg text-neutral-900 md:text-sm">
                                            {price !== selling_price &&
                                                <>
                                                    <span className="line-through text-neutral-400">
                                                        {price?.toLocaleString('en-US')}
                                                    </span>{" "} &nbsp;
                                                    <span className="line-through text-neutral-400">₹</span>{" "}
                                                </>
                                            }

                                            <>
                                                <span className="font-[510] text-neutral-900">
                                                    {selling_price?.toLocaleString('en-US')} ₹</span>
                                            </>
                                            {discount > 0 && (
                                                <div className="justify-center text-xs w-fit  px-1.5 py-1 mt-3 my-auto font-medium text-green-600 rounded-lg bg-green-600 bg-opacity-10">
                                                    {/* {discount}% off */}
                                                    {discount_type === 'flat' ? (
                                                        <span>
                                                            {discount < 0 ? '' : 'Flat ₹'}
                                                            {Math.abs(discount)} off
                                                        </span>
                                                    )
                                                        :

                                                        <span>

                                                            {Math.abs(discount)}
                                                            {discount < 0 ? '' : '%'} off
                                                        </span>
                                                    }
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="flex gap-2 justify-between mt-2">

                                        <div className="w-fit">
                                            <StarRating value={rating_avg} />
                                        </div>

                                        {(total_rating !== 0 || total_rating === "0" || total_rating === "") && (

                                            <div className="text-base leading-6 text-center text-neutral-400">
                                                ({total_rating}) reviews
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }

    return (
        <Layout>
            <TwoBreadcrumb
                href3={`/`}
                name3={`Wishlist`}
            />
            <div className="flex flex-col py-10">
                <div className={`flex flex-col items-start self-center px-5 mt-5 w-full max-w-${width} max-md:max-w-full`}>
                    <div className='font-bold text-4xl '>My Wishlist</div>
                    <div className='flex w-full flex-col gap-5 h-full '>
                        {/* {isEmptyObject(data) || data === null ?
                            <>
                                <div className='flex items-center  justify-center h-96 max-sm:h-full max-sm:py-10 flex-col '>

                                    <i className='fa fa-shopping-bag text-lg text-neutral-500'></i>
                                    <h1 className='text-xl mybzb_text_clr mt-3'>Wishlist is empty</h1>
                                </div>
                            </>
                            :
                            <section
                                className="flex flex-col grid  2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-3 xs:grid-cols-1 justify-center rounded-xl max-md:mt-10  py-5 gap-5"
                            >
                                {data && data?.productData?.length > 0 && data?.productData.map((card, index) => (
                                    <ProductCard key={index} {...card} removeData={removeData} />
                                ))}


                            </section>
                        } */}

                        {(!data || data.length === 0) ? (
                            <div className="flex items-center justify-center h-96 max-sm:h-full max-sm:py-10 flex-col">
                                <i className="fa fa-shopping-bag text-lg text-neutral-500"></i>
                                <h1 className="text-xl mybzb_text_clr mt-3">Wishlist is empty</h1>
                            </div>
                        ) : (
                            <section
                                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 py-5"
                            >
                                {data.map((card, index) => (
                                    <ProductCard
                                        key={index}
                                        {...card}
                                        removeData={removeData} // Ensure this function updates the state properly
                                    />
                                ))}
                            </section>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer />

        </Layout>
    )
}

export default WishlistPage