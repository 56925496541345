import React from 'react';
import { useNavigate } from 'react-router-dom';


const SellerGuideTextSection = () => {
    const navigate = useNavigate();

    const handleSupport = () => {
        navigate('/contact');
    };
    return (
        <>

            <div className="flex  flex-col py-16 ">
                <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        1. <span className="text-neutral-900">Seller Registration</span>
                    </div>
                    <div className="w-full flex flex-col grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-5 justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Getting Started</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Begin your journey by registering as a seller on MyBzB. Provide accurate and verifiable information during the registration process.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>

                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Eligibility Criteria:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Ensure that you meet the eligibility criteria outlined by MyBzB for seller registration.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        2. <span className="text-neutral-900">Creating Product Listings</span>
                    </div>
                    <div className="w-full flex flex-col grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>High-Quality Imagery:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Capture the beauty of your jewellery with high-quality images. Showcase different angles and details to give buyers a comprehensive view.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Detailed Descriptions:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Craft detailed and compelling product descriptions. Highlight the unique features, materials, and craftsmanship of each jewellery piece.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Pricing:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Set competitive prices for your products. Consider market trends, your production costs, and the perceived value of your jewellery.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-16 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        3. <span className="text-neutral-900">Order Fulfilment</span>
                    </div>
                    <div className="w-full justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                            <div className='flex items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Efficient Order Processing:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Fulfil orders promptly. You can opt for the Fulfilment by <span className="font-semibold text-red-700">MyBzB </span>program to streamline your order processing, ensuring timely shipping and professional packaging.
                                    </div>
                                </div>
                            </div>
                            <div className='flex mt-3 items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Real-time Inventory Management:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Keep track of your inventory in real-time through the seller dashboard. Update stock levels regularly to avoid overselling.
                                    </div>
                                </div>
                                
                            </div>
                    </div>
                </div>
                <div className="flex flex-col mt-16 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        4. <span className="text-neutral-900">Customer Communication</span>
                    </div>
                    <div className="w-full justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                            <div className='flex items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Prompt Responses:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Respond to customer inquiries and messages promptly. Clear and timely communication fosters trust and positive buyer experiences.
                                    </div>
                                </div>
                            </div>
                            <div className='flex mt-3 items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Addressing Customer Reviews</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Encourage satisfied customers to leave positive reviews. Address any negative reviews professionally and seek resolutions.
                                    </div>
                                </div>
                                
                            </div>
                    </div>
                </div>
                <div className="flex flex-col mt-16 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        5. <span className="text-neutral-900">Platform Features</span>
                    </div>
                    <div className="w-full justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                            <div className='flex items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Utilize Features:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Explore and utilize platform features such as promotions, discounts, and festive offers to attract more buyers.
                                    </div>
                                </div>
                            </div>
                            <div className='flex mt-3 items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Multi-language and Currency Support:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Take advantage of multi-language and currency support to cater to a diverse global audience.
                                    </div>
                                </div>
                                
                            </div>
                    </div>
                </div>
                <div className="flex flex-col w-full mt-16 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        6. <span className="text-neutral-900">Seller Analytics</span>
                    </div>
                    <div className="w-full flex flex-col grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-5 justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Monitor Performance:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Regularly check the seller analytics provided by <span className="font-semibold text-red-700">MyBzB </span>. Gain insights into your sales performance, customer demographics, and popular products.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>

                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Adapt Strategies:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Use analytics to adapt your marketing and sales strategies. Identify trends and capitalize on what works best for your brand.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-16 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        7. <span className="text-neutral-900">Platform Updates and Announcements</span>
                    </div>
                    <div className="w-full justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                            <div className='flex items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Stay Informed:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Keep yourself informed about platform updates, announcements, and policy changes. <span className="font-semibold text-red-700">MyBzB </span> communicates important information through seller Notifications.
                                    </div>
                                </div>
                            </div>
                            <div className='flex mt-3 items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Participate in Workshops:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Participate in workshops and webinars organized by <span className="font-semibold text-red-700">MyBzB </span> to stay updated on industry trends and best practices.
                                    </div>
                                </div>
                                
                            </div>
                    </div>
                </div>
                <div className="flex flex-col mt-16 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        8. <span className="text-neutral-900">Security Measures</span>
                    </div>
                    <div className="w-full justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                            <div className='flex items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Account Security:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Prioritize the security of your <span className="font-semibold text-red-700">MyBzB </span> account. Use strong, unique passwords, and report any suspicious activities to our support team.
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="flex flex-col mt-16 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        9. <span className="text-neutral-900">Contacting Seller Support</span>
                    </div>
                    <div className="w-full justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                            <div className='flex items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Need Assistance?</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    If you have questions, concerns, or require assistance, contact our dedicated Seller Support team through the <span className="font-semibold text-red-700">'Contact Us'</span> page.
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="flex flex-col mt-16 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        10. <span className="text-neutral-900">Terms and Policies</span>
                    </div>
                    <div className="w-full justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                            <div className='flex items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Review Terms:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Regularly review and familiarize yourself with MyBzB's <span className="font-semibold text-red-700">Terms & Conditions</span> and <span className="font-semibold text-red-700">Privacy Policy</span>. Adherence to these policies ensures a smooth and compliant selling experience.
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="flex flex-col mt-16 w-full  max-md:mt-10 max-md:max-w-full">
                    <div className="mt-4 text-lg max-md:max-w-full text-neutral-900">
                    <span className="font-semibold text-red-700">My Bharatiya Zaveri Bazaar</span> is committed to providing a platform that empowers jewellers to succeed. We invite you to leverage these guidelines to enhance your online selling experience. Happy selling!
                    </div>
                </div>
                <div className="flex gap-5 mt-6 me-4 my-auto" >
                                    <button className="focus:border-red-800 justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 me-2" onClick={handleSupport}>
                                       Contact Us <i className="fa fa-arrow-right ml-2" style={{ color: '#ffffff' }}></i> 
                                    </button>
                                </div>
            </div>
        </>
    )
}

export default SellerGuideTextSection