import React from 'react'
import { useNavigate } from 'react-router-dom';


const GrievanceRedressalTextSection = () => {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/');
    };
    return (
        <>

            <div className="flex  flex-col py-7 ">
                <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Understanding</span> Grievances
                    </div>
                    <div className="text-lg mt-6 text-neutral-900 max-md:max-w-full">
                    A grievance refers to any issue related to the product or service availed by the consumer from the MyBzB platform, for which the consumer seeks a resolution.
                    </div>
                </div>
                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold max-md:max-w-full">
                    How to <span className="text-red-700">Reach Us</span>
                    </div>
                    <div className="text-lg mt-6 text-neutral-900 max-md:max-w-full">
                    If you have any queries or complaints, we encourage you to approach us through the{" "}
                                    <span className="font-semibold text-red-700">
                                    'Contact Us'
                                    </span> or {" "}
                                    <span className="font-semibold text-red-700">
                                    'Help Centre'
                                    </span> tab available on this page. Here's a simple guide to initiate the process:
                    </div>
                    <div className="flex flex-col mt-6 w-full max-md:max-w-full gap-5">
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base max-md:max-w-full">
                                Click on the{" "}
                                    <span className="font-semibold text-red-700">
                                    'Contact Us'
                                    </span> page.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base max-md:max-w-full">
                                Fill up the form
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base max-md:max-w-full">
                                Choose the relevant{" "}
                                    <span className="font-semibold text-red-700">
                                    'Type of Issue'
                                    </span>
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base max-md:max-w-full">
                                Submit your query or complaint.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold max-md:max-w-full">
                    Escalation <span className="text-red-700">Process</span>
                    </div>
                    <div className="text-lg mt-6 text-neutral-900 max-md:max-w-full">
                    In the event that your query or complaint is not resolved and needs further attention, MyBzB has appointed a dedicated "<span className="font-semibold text-red-700">
                                    Grievance Manager
                                    </span>" to address your concerns. Here are the details for our Grievance Manager:
                                    
                    </div>
                    <div className="flex flex-col mt-6 w-full max-md:max-w-full gap-5">
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base max-md:max-w-full">
                                Mr. Paresh Rajpara
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base max-md:max-w-full">
                                Designation: CMD
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base max-md:max-w-full">
                                My Bharatiya Zaveri Bazaar
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base max-md:max-w-full">
                                Contact: +91 9737600096
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base max-md:max-w-full">
                                Email: <span className="font-semibold text-red-700">
                                info@mybzb.com
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold">
                    Grievance Redressal <span className="text-red-700">Process</span>
                    </div>
                    <div className="text-lg mt-6 text-neutral-900 max-md:max-w-full">
                    Our Grievance Redressal Mechanism follows a systematic approach:                    
                    </div>
                    <div className='flex flex-row mt-5 items-start gap-2 '>
                        <i className="fa fa-circle-o mybzb_text_clr mt-1 text-xs" ></i>
                        <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                        Upon receiving a consumer grievance through the specified channels, the consumer will receive an acknowledgment within <span className="font-semibold text-red-700">48 hours</span>{" "}through email or phone call.
                        </div>
                    </div>
                    <div className='flex flex-row mt-5 items-start gap-2 '>
                        <i className="fa fa-circle-o mybzb_text_clr mt-1 text-xs" ></i>
                        <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                        The consumer will be regularly updated on the status of their grievance.
                        </div>
                    </div>
                    <div className='flex flex-row mt-5 items-start gap-2 '>
                        <i className="fa fa-circle-o mybzb_text_clr mt-1 text-xs" ></i>
                        <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                        Our dedicated<span className="font-semibold text-red-700">"Consumer Care"</span>{" "}and <span className="font-semibold text-red-700">"Grievance Redressal"</span>{" "}will make all best endeavors to resolve the grievance expeditiously within the timelines prescribed by applicable laws.
                        </div>
                    </div>
                    <div className='flex flex-row mt-5 items-start gap-2 '>
                        <i className="fa fa-circle-o mybzb_text_clr mt-1 text-xs" ></i>
                        <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                        A grievance will be considered closed and disposed of when the consumer is communicated by  <span className="font-semibold text-red-700">Consumer Care</span>, the Grievance Officer, or any other person associated with the website, offering solutions to the grievance.
                        </div>
                    </div>
                    
                    <div className="mt-12 text-lg text-base text-zinc-600 max-md:max-w-full">
                    For additional details, please refer to our <span className="font-semibold text-red-700">'Terms of Use'</span> page. 
                    </div>
                    <div className="mt-4 text-lg text-base text-zinc-600 max-md:max-w-full">
                    At <span className="font-semibold text-red-700">MyBzB</span>, we value your feedback and are committed to ensuring a seamless and satisfactory experience for all our users.
                    </div>
                    <div className="mt-4 text-lg text-base text-zinc-600 max-md:max-w-full">
                    Your trust is essential to us, and we are here to address your concerns and enhance your journey with <span className="font-semibold text-red-700">My Bharatiya Zaveri Bazaar</span>.
                    </div>
                </div>
                <div className="flex gap-5 mt-6 me-4 mb-8 my-auto" >
                    <button className="focus:border-red-800 justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 me-2" onClick={handleHome}>
                        Back to Home<i className="fa fa-arrow-right ml-2" style={{ color: '#ffffff' }}></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default GrievanceRedressalTextSection