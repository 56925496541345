import React, { useEffect, useState } from "react";
import ImgComp from "../../ReusableComp/ImgComp";
import * as Images from '../../../assets';
import { BgButton, OutlineButton } from "../../ReusableComp/Buttons";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../ReusableComp/Layout";



const DeactiveUserPage = () => {

    const navigate = useNavigate('');


    const DeactiveUserImg = () => {
        return (
            <>
                <ImgComp
                    src={Images.deactive_user_img}
                    className={`w-[580px] h-[487px] mx-auto deactive-user-img`}
                />
            </>
        );
    }

    const DeactiveUserText = () => {
        return (
            <>
                <p className="mt-2 text-xl font-medium leading-8 text-red-800 max-lg:max-w-full">
                Your Account Has Been Deactivated
                </p>
                <p className="self-center mt-2 text-base leading-7 text-neutral-400 w-full">
                We're sorry, but your access to the platform has been deactivated.
                </p>
            </>
        );
    }

    const DeactiveUserButtons = () => {
        const handleContact = () => {
            navigate('/contact');
        }
        const handleHomePage = () => {
            navigate('/');
        }

        return (
            <>
          <div
    className="flex flex-col mt-10 max-lg:max-w-full md:w-full md:px-0"
>
    <button
        onClick={handleContact}
        className="focus:outline-0 justify-center px-12 py-4 text-lg font-medium text-red-700 bg-white rounded shadow-sm shadow-red-500 max-lg:px-5 max-lg:mx-1 hover:bg-red-50"
    >
        Contact to Our Support Team {" "}
        <i className="fa fa-external-link mt-1 ml-2"></i>
    </button>
</div>

            {/* <div
                className="flex flex-col pb-2 mt-3 max-lg:max-w-full md:w-full md:px-0"
            >
                <button
                    onClick={handleHomePage}
                    className="focus:outline-0 justify-center px-12 py-4 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 hover:bg-red-100 hover:text-red-700 hover:shadow-sm hover:shadow-red-500">
                    Go To Homepage
                </button>
            </div> */}
            </>
        ); 
    }




    return (
        // <Layout>
            <main className="flex justify-center items-center mb-16 px-6 bg-white max-lg:px-5">
                    <div className="flex max-lg:flex-col max-lg:gap-0 rounded-2xl ">
                        <div className="flex flex-col w-full max-lg:ml-0 max-lg:w-full rounded-2xl">
                            <div className="rounded-2xl flex flex-col grow justify-between self-stretch px-5 py-5 w-full text-center bg-white max-lg:px-5 max-lg:max-w-full">
                                <DeactiveUserImg />
                                <div className="flex flex-col px-16 mt-1 max-lg:px-5 max-lg:mt-1 max-lg:max-w-full">
                                    <DeactiveUserText />
                                    <DeactiveUserButtons />
                                </div>
                            </div>
                        </div>
                    </div>
            </main>
        // </Layout>
    );
}

export default DeactiveUserPage;