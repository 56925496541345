import React, { useEffect, useState } from 'react';
import ImgComp from '../../../ReusableComp/ImgComp';
import StarRating from "../../../ReusableComp/StarRating";
import { fetchWithFormDataToken } from "../../../../apiGeneralFunction";
import { isEmptyObject } from '../../../ReusableComp/Validations';
import Pagination from '../../../ReusableComp/Pagination';

const RatingAndReviews = ({ data1 }) => {
  const [data, setData] = useState(null);
  const [totalPages, setTotalPages] = useState(0); // Default to 0
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const fetchData = async (page = 1) => {
    const ProductId = localStorage.getItem("ProductDetail id") || '';
    const abortController = new AbortController();

    try {
      const formdata = new FormData();
      formdata.append("product_id", ProductId);
      formdata.append("page", page);

      const result = await fetchWithFormDataToken(`api/v1/list_ratings_review`, formdata, abortController, null);

      if (result?.status === true) {
        setData(result?.data?.review_data || []); // Set the review data
        setTotalPages(result?.data?.total_pages || 1); // Set total pages
      } else {
        console.error("Error fetching data:", result?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const UserReview = ({ name = '', date = '', avatarSrc = '', text = '', images = [], rating = 0 }) => {
    let formattedDate = '';

    if (date) {
      const dateValue = Date.parse(date);
      if (!isNaN(dateValue)) {
        formattedDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(new Date(dateValue));
      }
    }

    return (
      <article className="flex flex-col mt-3">
        <div className="flex gap-4 leading-[150%] max-md:flex-wrap">
          <ImgComp
            src={avatarSrc || 'https://via.placeholder.com/48'}
            className="shrink-0 w-12 rounded-full aspect-square"
          />
          <div className="flex flex-col flex-1 my-auto max-md:max-w-full">
            <div className="text-sm font-bold text-neutral-900 max-md:max-w-full">{name}</div>
            <div className="flex gap-3 justify-between mt-1 text-sm text-neutral-400 max-md:flex-wrap max-md:max-w-full">
              <StarRating value={rating} />
              {formattedDate && <time dateTime={new Date(date).toISOString()}>{formattedDate}</time>}
            </div>
          </div>
        </div>
        <div className="flex gap-3 self-start mt-4 max-sm:flex-col">
          {images?.map((imageSrc, index) => (
            <ImgComp
              key={index}
              src={imageSrc}
              className="shrink-0 aspect-square w-[72px]"
            />
          ))}
        </div>
        <p className="mt-4 text-sm leading-5 text-neutral-400 max-md:max-w-full">{text}</p>
      </article>
    );
  };

  return (
    <>
      <section className="flex flex-col self-stretch px-8 py-6 mx-6 my-4 bg-white rounded-xl border border-gray-200 border-solid max-w-full">
        <div>
          <h1 className="font-bold text-4xl">Reviews & Ratings</h1>
        </div>
        <hr className="my-5" />
        {data?.map((review, index) => (
          <React.Fragment key={index}>
            <UserReview
              name={review?.user_name}
              date={review?.date}
              avatarSrc={review?.user_image}
              text={review?.comment}
              images={review?.images}
              rating={parseInt(review?.rating, 10)}
            />
            {index !== data.length - 1 && <hr className="my-5" />}
          </React.Fragment>
        ))}
      </section>
      {totalPages > 0 && (
        <div className="flex w-full justify-center items-center my-5">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default RatingAndReviews;
