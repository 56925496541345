import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const CancellationReturnImgSection = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5 max-md:px-5`}>
                <div className='flex flex-row max-md:flex-col'>
                    <div className='my-auto mx-6'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            Cancellation & Return
                        </div>
                        <div className="my-2  text-4xl font-bold capitalize text-neutral-900 max-md:text-3xl  home_heading  ">
                            Cancellation And
                        </div>
                        <HeadingTextImageWrapper
                        // text=" Our Products" 
                        // text=" Featured Item"
                        text={`Return Policy`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />
                    
                        <div className="mt-6 text-lg  max-md:max-w-full">
                        Welcome to{" "}
                            <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar (MyBzB)
                                </span>
                            , where your satisfaction is our priority. We understand that occasionally circumstances may arise where you need to cancel an order or initiate a return. To make your experience as seamless as possible, we have outlined our detailed Cancellation and Return Policy below:
                        </div>
                    </div>    
                    <div className='footer-cancellation-image'>
                        <ImgComp
                        src={Images.cancellation_return_policy}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default CancellationReturnImgSection