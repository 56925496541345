import './App.css';
import React, { useEffect } from 'react';
// import Layout from './component/ReusableComp/Layout';
import { Route, Routes } from 'react-router-dom';
import Register from './component/UserAuth/Register';
// import UserAuth from './component/UserAuth';
import Homepage from './component/Pages/Homepage';
import AddToCart from './component/Pages/AddToCart';
// import Verification from './component/UserAuth/Verification';
import LocationVerification from './component/UserAuth/LocationVerification';
import UserProfile from './component/Pages/UserProfile';
import ProductDetail from './component/Pages/ProductDetail';
// import CartContent from './component/Pages/AddToCart/EmptyCartContent';
import ListingPage from './component/Pages/ListingPage';
import ViewShoppingCart from './component/Pages/ShoppingCart/ViewShoppingCart';
// import ShoppingCartContent from './component/Pages/ShoppingCart/ShoppingCartpage/ShoppingCartContent';
import ShoppingCartpage from './component/Pages/ShoppingCart/ShoppingCartpage';
import BecomeASeller from './component/UserAuth/BecomeASeller';
import NoApproved from './component/UserAuth/BecomeASeller/NoApproved';
import AllSellerpage from './component/Pages/Homepage/TopSeller/AllSellerpage';
// import ListingContent from './component/Pages/ListingPage/ListingContent';
import AllCategoryListpage from './component/Pages/Homepage/PopularCategories/AllCategoryListpage';
import MyAddressTabContent from './component/Pages/UserProfile/UserProfileContent/MyAddressTabContent';
import MyWishlistTabContent from './component/Pages/UserProfile/UserProfileContent/MyWishlistTabContent';
import WebPage from './component/WebPage';
import { SearchProvider } from './component/ReusableComp/SearchContext';
import Navbar from './component/ReusableComp/Layout/NavbarComp/Navbar';
import WishlistPage from './component/Pages/WishlistPage';
// import LanguageSelector from './component/Pages/LanguageSelector';
import { ToastContainer } from 'react-toastify';
import OptimizeVerification from './component/UserAuth/OptimizeVerification';
import PrivacyPolicy from './component/Pages/PrivacyPolicy';
import TermsConditions from './component/Pages/Terms&Conditions';
import AboutMyBzB from './component/Pages/AboutMyBzB'
import OrderDetailPage from './component/Pages/UserProfile/UserProfileContent/MyOrderTabContent/OrderDetailPage';
import FAQPage from './component/Pages/FAQPage';
import ContactUs from './component/Pages/ContactUs';
import SellerGuidePage from './component/Pages/SellerGuidePage';
import SellerTermsPage from './component/Pages/SellerTermsPage';
import FulfilmentByMyBzBPage from './component/Pages/FulfilmentByMyBzBPage';
import BuildBrandPage from './component/Pages/BuildBrandPage';
import PrivacyPolicyContent from './component/Pages/PrivacyPolicy/PrivacyPolicyContent';
import TermsConditionContent from './component/Pages/Terms&Conditions/TermsConditionContent';
import FAQContent from './component/Pages/FAQPage/FAQContent';
import AboutMyBzBContent from './component/Pages/AboutMyBzB/AboutMyBzBContent';
import SellerGuideContent from './component/Pages/SellerGuidePage/SellerGuideContent';
import SellerTermsContent from './component/Pages/SellerTermsPage/SellerTermsContent';
import FulfilmentByMyBzBContent from './component/Pages/FulfilmentByMyBzBPage/FulfilmentByMyBzBContent';
import BuildBrandContent from './component/Pages/BuildBrandPage/BuildBrandContent';
import PurchaseProtectionPage from './component/Pages/PurchaseProtectionPage';
import ShippingTermsPage from './component/Pages/ShippingTermsPage';
import PurchaseProtectionContent from './component/Pages/PurchaseProtectionPage/PurchaseProtectionContent';
import ShippingTermsContent from './component/Pages/ShippingTermsPage/ShippingTermsContent';
import CancellationReturnPage from './component/Pages/CancellationReturnPage';
import CancellationReturnContent from './component/Pages/CancellationReturnPage/CancellationReturnContent';
import PaymentRefundPage from './component/Pages/PaymentRefundPage';
import PaymentRefundContent from './component/Pages/PaymentRefundPage/PaymentRefundContent';
import GrievanceRedressalPage from './component/Pages/GrievanceRedressalPage';
import GrievanceRedressalContent from './component/Pages/GrievanceRedressalPage/GrievanceRedressalContent';
import FraudWarningPage from './component/Pages/FraudWarningPage';
import FraudWarningContent from './component/Pages/FraudWarningPage/FraudWarningContent';
import JobsAtMyBzBPage from './component/Pages/JobsAtMyBzBPage';
import DeactiveUserPage from './component/UserAuth/DeactiveUserPage';
import RatingAndreviews from './component/Pages/ProductDetail/RatingAndReviews';

function App() {
      useEffect(() => {
          setTimeout(() => {
              window.scrollTo(0, 0);
          }, 0);
      }, []);
  return (
    <>
      <ToastContainer />
      <SearchProvider>
        <div className='hidden'>
          <Navbar />
        </div>
        <Routes>

          <Route path="/" element={<Homepage />} />
          {/* <Route path="/register" element={<UserAuth />} /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/verification" element={<OptimizeVerification />} />
          <Route path="/location" element={<LocationVerification />} />
          <Route path="/deactive_user" element={<DeactiveUserPage />} />

          <Route path="/add_to_cart" element={<AddToCart />} />
          <Route path="/wishlist" element={<WishlistPage />} /> 
          <Route path="/reviews" element={<RatingAndreviews />} />

          {/* Footer */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about_us" element={<AboutMyBzB />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/seller-guide" element={<SellerGuidePage />} />
          <Route path="/seller-terms" element={<SellerTermsPage />} />
          <Route path="/fulfilment-by-mybzb" element={<FulfilmentByMyBzBPage />} />
          <Route path="/build-your-brand" element={<BuildBrandPage />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/purchase-protection" element={<PurchaseProtectionPage />} />
          <Route path="/shipping" element={<ShippingTermsPage />} />
          <Route path="/cancellation-return" element={<CancellationReturnPage />} />
          <Route path="/payment-refunds" element={<PaymentRefundPage />} /> 
          <Route path="/grievance-redressal" element={<GrievanceRedressalPage />} />
          <Route path="/fraud-warning-disclaimer" element={<FraudWarningPage />} />
          <Route path="/job-listing" element={<JobsAtMyBzBPage />} />

          {/* For Mobile App */}
          <Route path="/app/privacy-policy" element={<PrivacyPolicyContent />} />
          <Route path="/app/terms-conditions" element={<TermsConditionContent />} />
          <Route path="/app/faq" element={<FAQContent />} />
          <Route path="/app/about_us" element={<AboutMyBzBContent />} />
          <Route path="/app/seller-guide" element={<SellerGuideContent />} />
          <Route path="/app/seller-terms" element={<SellerTermsContent />} />
          <Route path="/app/fulfilment-by-mybzb" element={<FulfilmentByMyBzBContent />} />
          <Route path="/app/build-your-brand" element={<BuildBrandContent />} />
          <Route path="/app/purchase-protection" element={<PurchaseProtectionContent />} />
          <Route path="/app/shipping" element={<ShippingTermsContent />} />
          <Route path="/app/cancellation-return" element={<CancellationReturnContent />} />
          <Route path="/app/payment-refunds" element={<PaymentRefundContent />} />
          <Route path="/app/grievance-redressal" element={<GrievanceRedressalContent />} />
          <Route path="/app/fraud-warning-disclaimer" element={<FraudWarningContent />} />


          {/* <Route path="/product_detail/*" element={<ProductDetail />} /> */}
          <Route path="product_detail" element={<ProductDetail />} >
            <Route index element={<ProductDetail />} />
            <Route path="product_detail" element={<ProductDetail />} />
            {/* <Route path="shoppingcart" element={<ShoppingCartpage />} /> */}

          </Route>
          <Route path="/seller-list" element={<AllSellerpage />} />
          <Route path="/category-list" element={<AllCategoryListpage />} />
          <Route path="/listing" element={<ListingPage />} />
          {/* <Route path="/all-listing" element={<ListingContent />} /> */}
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/withoutsign-shopping" element={<ViewShoppingCart />} />
          <Route path="/shoppingcart" element={<ShoppingCartpage />} />
          <Route path="/seller" element={<BecomeASeller />} />
          <Route path="/no_approved" element={<NoApproved />} />
          <Route path="myaddress" element={<MyAddressTabContent />} />
          <Route path="mywishlist" element={<MyWishlistTabContent />} />
          {/* <Route path="/orders/:orderId" element={<OrderDetailPage />} /> */}
          <Route path="/order-detail" element={<OrderDetailPage />} />

          {/* LISTING PAGES API ENDPOINTS*/}
        

          <Route
            path="/webpage"
            element={<WebPage
              title="MyBzb"
              description="A brief description of my website."

            />} />
        </Routes>
      </SearchProvider>


    </>

  );
}

export default App;
