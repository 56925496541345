import React from 'react'
import { useNavigate } from 'react-router-dom';


const FraudWarningTextSection = () => {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/');
    };
    return (
        <>

            <div className="flex  flex-col py-7 ">
                <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        Warning <span className="text-neutral-900">Signs</span>
                    </div>
                    <div
                        className='grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-3 mt-6'
                    >
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; False Representations:
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Be cautious if someone claims to be associated with{" "}
                                <span className="font-semibold text-red-700">MyBzB</span> and seeks money, valuables, or personal information from you.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Gift Offers:
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Exercise caution if individuals promise gifts, sales opportunities, or business ventures on behalf of{" "}
                                <span className="font-semibold text-red-700">MyBzB</span>, especially if they request payment or personal information in return.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">3.</span>&nbsp; Unsolicited Communications:
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Fraudulent offers may be received via email, the internet, text messages, or phone calls. Be wary of unexpected contact that seems suspicious.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <div className="flex flex-col w-full mt-12 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        Our <span className="text-neutral-900">Response</span> 
                    </div>
                    <div
                        className='grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-3 mt-6'
                    >
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; Invalid Claims:
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Disregard any claims or offers received through suspicious channels.{" "}
                                <span className="font-semibold text-red-700">MyBzB</span> does not engage in such activities, and any representation otherwise is fraudulent.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Exercise Caution:
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Be cautious when encountering messages that request clicking on links or sharing personal information. Always verify the authenticity of such requests.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">3.</span>&nbsp; Report Suspicious Activity:
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                If you receive a suspicious offer or invitation, please contact us immediately at{" "}
                                <span className="font-semibold text-red-700">info@mybzb.com</span>. Additionally, notify the police or relevant authorities to take appropriate action.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full mt-12 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        Legal <span className="text-neutral-900">Implications</span> 
                    </div>
                    <div
                        className='grid 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-3 mt-6'
                    >
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; Cooperation with Authorities:
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                <span className="font-semibold text-red-700">MyBzB</span> is committed to cooperating with competent law enforcement authorities to address fraudulent activities.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Criminal and Civil Liabilities:
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Individuals engaging in fraudulent actions may be subject to criminal and/or civil liabilities.{" "}
                                <span className="font-semibold text-red-700">MyBzB</span> will take legal action against imposters to protect our community.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold">
                        Disclaimer
                    </div>
                    <div className="mt-4 text-lg text-base text-zinc-600 max-md:max-w-full">
                     Under no circumstances shall <span className="font-semibold text-red-700">My Bharatiya Jewellery Bazaar, Headway Business Solutions LLP,</span> and/or its affiliates and subsidiaries be held liable or responsible for any claims, losses, damages, expenses, or other inconvenience resulting from or connected to the actions of these imposters.
                    </div>
                    <div className="mt-4 text-lg text-base text-zinc-600 max-md:max-w-full">
                    Your safety and security are of utmost importance to us. Please remain vigilant and report any suspicious activities to us and the appropriate authorities.
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                    <div className="text-lg text-base text-zinc-600 max-md:max-w-full">
                    Thank you for your cooperation.
                    </div>
                    <div className="text-lg text-base text-zinc-600 max-md:max-w-full">
                    Sincerely,
                    </div>
                    <div className="text-lg text-base text-zinc-600 max-md:max-w-full">
                    <span className="font-semibold text-red-700">My Bharatiya Jewellery Bazaar</span>
                    </div>
                </div>
                <div className="flex gap-5 mt-12 me-4 mb-12 my-auto" >
                    <button className="focus:border-red-800 justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 me-2" onClick={handleHome}>
                        Back to Home<i className="fa fa-arrow-right ml-2" style={{ color: '#ffffff' }}></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default FraudWarningTextSection