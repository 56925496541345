import React, {useEffect} from 'react'
import Layout from '../../ReusableComp/Layout'
import CancellationReturnContent from './CancellationReturnContent'
import { TwoBreadcrumb } from '../../ReusableComp/Breadcrumb'

const CancellationReturnPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Layout>
                <TwoBreadcrumb
                    href3={`/`}
                    name3={`Cancellation & Return`}
                />

                <CancellationReturnContent />

            </Layout>
        </>
    )
}

export default CancellationReturnPage