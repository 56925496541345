import React from 'react'
import { useNavigate } from 'react-router-dom';


const PaymentRefundTextSection = () => {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/');
    };
    return (
        <>

            <div className="flex flex-col">
            <div className="flex flex-col w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Payment </span>Methods
                    </div>
                    <div className="flex flex-col grid 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 mt-6 w-full max-md:max-w-full gap-5">
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; Secure Payment Gateway
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                You can pay for your{" "}
                                <span className="font-semibold text-red-700">MyBzB</span> purchases using a secured payment gateway with various options:
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base font-bold text-neutral-900 max-md:max-w-full">
                                Credit/Debit Cards
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base font-bold text-neutral-900 max-md:max-w-full">
                                UPI
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base font-bold text-neutral-900 max-md:max-w-full">
                                Cash on Delivery (C-o-D)
                                </div>
                            </div>
                        </div>
                        <div className="text-lg text-neutral-900 max-md:max-w-full">
                        Stay tuned for more payment options coming soon, including Internet Banking, MyBzB Wallet, and others. We will keep you informed about new payment methods as they become available.
                    </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Credit/Debit Card Payments
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900">How to Pay:</span> Simply choose the{" "}<span className="font-semibold text-red-700">Credit/Debit Card</span> option during checkout.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900">EMI Option:</span> Currently, we accept full payments only, but an EMI option will be available very soon.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900">Safety Assurance:</span> Your credit/debit card transactions on{" "}<span className="font-semibold text-red-700">MyBzB</span> are completely safe and secured, ensuring a worry-free shopping experience.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">How to pay using a </span>credit/debit card
                    </div>
                    <div className="text-lg mt-6 text-neutral-900 max-md:max-w-full">
                    Paying for your favorite jewellery on My Bharatiya Zaveri Bazaar (MyBzB) with a credit or debit card is a straightforward process. Here's a step-by-step guide:
                    </div>
                    <div className="flex flex-col grid 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 mt-6 w-full max-md:max-w-full gap-5">  
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className='flex flex-row items-start gap-2 '>
                            <span className="text-red-700">1.</span>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900">Add Items to Your Cart:</span> Browse through our exquisite collection and add the desired jewellery items to your shopping cart.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <span className="text-red-700">2.</span>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900"> Proceed to Checkout:</span> Once you've completed your selection, click on the shopping cart icon, and then proceed to checkout.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <span className="text-red-700">3.</span>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900">Billing Information:</span> Enter your billing details, including your shipping address and contact information.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <span className="text-red-700">4.</span>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900">Payment Options:</span> In the payment section, choose the "Credit/Debit Card" option from the available payment methods.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <span className="text-red-700">5.</span>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900">Card Details:</span> Enter your credit/debit card details, including the card number, expiry date, and CVV code.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <span className="text-red-700">6.</span>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900">Authorization:</span> You may be redirected to the bank's secure portal for additional authentication. Follow the prompts to complete the payment.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <span className="text-red-700">7.</span>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900">Confirmation:</span> Once the payment is successfully processed, you'll receive an order confirmation and payment receipt.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-lg mt-6 text-neutral-900 max-md:max-w-full">
                    Now you're all set to enjoy your exquisite jewellery from MyBzB, conveniently paid for with your credit or debit card.
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Credit Card </span>EMI Option
                    </div>
                    <div className="text-lg mt-6 text-neutral-900 max-md:max-w-full">
                    At{" "}<span className="font-semibold text-red-700">MyBzB</span>, we understand the value of flexibility in payments. While we currently accept the full payment for your purchases, we are excited to announce that an EMI (Equated Monthly Installment) option will be made available very soon.
                    </div>
                    <div className="flex flex-col grid 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 mt-6 w-full max-md:max-w-full gap-5">  
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                        <div className="text-lg text-neutral-900 max-md:max-w-full">
                        Here's a glimpse of what you can expect: 
                    </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900">Pay the Full Amount Now:</span> Currently, we accept the full payment upfront when you make a purchase.
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-bold text-neutral-900"> Upcoming EMI Option:</span> In the near future, we will introduce an EMI option, allowing you to spread the cost of your jewellery purchases over convenient monthly installments.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-lg mt-6 text-neutral-900 max-md:max-w-full">
                    Keep an eye out for updates from MyBzB, as we work to bring you more payment options, making your jewellery shopping experience even more delightful.
                    </div>
                </div>

                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold max-md:max-w-full">
                    Cash on Delivery <span className="text-red-700">(C-o-D)</span>
                    </div>
                    <div className="flex flex-col mt-6 w-full max-md:max-w-full gap-5">
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; What is C-o-D?
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Cash on Delivery is a payment option where you pay in cash upon the delivery of your order.
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Placing a C-o-D Order:
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Look for items with the{" "}
                                    <span className="font-semibold text-red-700">
                                    "Cash on Delivery Available"
                                    </span> icon.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Add items to your cart and proceed to checkout.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Choose{" "}
                                    <span className="font-semibold text-red-700">
                                    "Pay By Cash on Delivery"
                                    </span> during payment selection.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Enter the{" "}
                                    <span className="font-semibold text-red-700">
                                    CAPTCHA
                                    </span> text for validation.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Upon verification, your order will be processed for shipment, and you pay in cash to our courier partner upon delivery.
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">3.</span>&nbsp; Terms & Conditions for C-o-D:
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Maximum order value for C-o-D is ₹50,000.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Cash-only payment at the time of delivery.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-5 flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Safety of credit/debit card</span> transactions on MyBzB:
                    </div>
                    <div className="text-lg mt-2 text-neutral-900 max-md:max-w-full">
                    Your security is our utmost priority at My Bharatiya Zaveri Bazaar, and we want you to shop with confidence. Here's why you can trust the safety and security of credit/debit card transactions on our website:
                    </div>
                    <div
                        className='grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5'
                    >
                        <div className="flex gap-2 px-6 py-5 w-full rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-2" ></i>
                            <div >
                                <div className="text-lg font-bold text-neutral-900 ">
                                Secure Payment Gateway:
                                </div>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full mt-1">
                                MyBzB uses a robust and secured payment gateway to ensure the confidentiality and integrity of your card details.
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2 px-6 py-5 w-full rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-2" ></i>
                            <div>
                                <div className="text-lg font-bold text-neutral-900 ">
                                Encryption Technology:
                                </div>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full mt-1">
                                All sensitive information is encrypted using industry-standard SSL (Secure Socket Layer) technology, preventing unauthorized access during transmission.
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2 px-6 py-5 w-full rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-2" ></i>
                            <div>
                                <div className="text-lg font-bold text-neutral-900 ">
                                Continuous Monitoring:
                                </div>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full mt-1">
                                Our platform and payment partners continuously monitor transactions for any suspicious activity, promptly flagging and investigating potential fraud.
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2 px-6 py-5 w-full rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-2" ></i>
                            <div>
                                <div className="text-lg font-bold text-neutral-900 ">
                                Customer Identity Verification:
                                </div>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full mt-1">
                                In rare cases where there is uncertainty about a transaction, our team may request additional identity documents from the customer to ensure the purchase is legitimate.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-lg mt-2 text-neutral-900 max-md:max-w-full">
                    Rest assured, your credit/debit card transactions on MyBzB are completely safe and secure, providing you with a worry-free and enjoyable shopping experience.
                    </div>
                </div>  
                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Fraud</span> Prevention
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                    We prioritize your security and employ robust measures to prevent card fraud:
                    </div>
                    <div className="flex flex-col grid 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 mt-6 w-full max-md:max-w-full gap-5">  
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className='flex flex-row items-start gap-2 '>
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="text-neutral-900">Continuous monitoring for suspicious activity.</span> 
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="text-neutral-900">Flagging potentially fraudulent transactions for manual verification.</span>
                                </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="text-neutral-900">In rare cases, holding transactions for customer identity verification.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                    We apologize for any inconvenience caused and appreciate your cooperation in maintaining a safe online transaction environment.
                    </div>
                </div>
                {/* <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">3D Secure</span> Password
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                    Register for the 3D Secure password for your credit/debit card by visiting your bank's website. Below are registration links for some banks:
                    </div>
                    <div className="flex flex-col grid 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 mt-6 w-full max-md:max-w-full gap-5">  
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className='flex flex-row items-start gap-2 '>
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                            <div className="w-full font-semibold text-red-700 max-md:max-w-full">
                <a href="https://www.hdfcbank.com" target="_blank" rel="noopener noreferrer">HDFC Bank</a> 
                <span className="text-neutral-900"> | </span>
                <a href="https://www.axisbank.com" target="_blank" rel="noopener noreferrer">Axis Bank</a>
                <span className="text-neutral-900"> | </span>
                <a href="https://www.icicibank.com" target="_blank" rel="noopener noreferrer">ICICI Bank</a>
            </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                            <div className="w-full font-semibold text-red-700 max-md:max-w-full">
                <a href="https://www.kotak.com" target="_blank" rel="noopener noreferrer">Kotak Mahindra Bank</a>
                <span className="text-neutral-900"> | </span>
                <a href="https://www.bankofbaroda.in" target="_blank" rel="noopener noreferrer">Bank of Baroda</a>
                <span className="text-neutral-900"> | </span>
                <a href="https://www.centralbankofindia.co.in" target="_blank" rel="noopener noreferrer">Central Bank of India</a>
            </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                            <div className="w-full font-semibold text-red-700 max-md:max-w-full">
                <a href="https://www.indusind.com" target="_blank" rel="noopener noreferrer">IndusInd Bank</a>
                <span className="text-neutral-900"> | </span>
                <a href="https://www.idbibank.in" target="_blank" rel="noopener noreferrer">IDBI Bank</a>
                <span className="text-neutral-900"> | </span>
                <a href="https://www.federalbank.co.in" target="_blank" rel="noopener noreferrer">Federal Bank</a>
            </div>
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                            <div className="w-full font-semibold text-red-700 max-md:max-w-full">
                <a href="https://www.unionbankofindia.co.in" target="_blank" rel="noopener noreferrer">Union Bank of India</a>
                <span className="text-neutral-900"> | </span>
                <a href="https://www.canarabank.com" target="_blank" rel="noopener noreferrer">Canara Bank</a>
                <span className="text-neutral-900"> | </span>
                <a href="https://www.andhrabank.in" target="_blank" rel="noopener noreferrer">Andhra Bank</a>
            </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                    We apologize for any inconvenience caused and appreciate your cooperation in maintaining a safe online transaction environment.
                    </div>
                </div> */}
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Instant Cashback </span> Feature
                    </div>
                    <div className="mt-4 text-lg max-md:max-w-full text-neutral-900">
                    While it's not available currently, we're working to introduce an Instant Cashback feature in the future. 
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                <div className="text-3xl font-semibold text-neutral-900">
                        <span>Refunds</span>
                    </div>
                    <div className="mt-4 text-lg max-md:max-w-full text-neutral-900">
                    You can return the product and ask for refunds within the first 3 days of product delivery. Refunds for cancellations are processed within 20 business days upon successful verification of your order cancellation.
                    </div>
                    <div className="mt-4 text-lg max-md:max-w-full text-neutral-900">
                    My Bharatiya Zaveri Bazaar is committed to providing a secure, transparent, and enjoyable shopping experience. If you have any queries or need assistance, feel free to reach out to our Customer Care team through the 'Contact Us' or 'Help Centre' tab. Your satisfaction is our priority. Happy shopping!
                    </div>
                </div>
                <div className="flex gap-5 mt-6 me-4 mb-16 my-auto" >
                    <button className="focus:border-red-800 justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 me-2" onClick={handleHome}>
                        Back to Home<i className="fa fa-arrow-right ml-2" style={{ color: '#ffffff' }}></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default PaymentRefundTextSection