import React from 'react'
import GrievanceRedressalImgSection from '../GrievanceRedressalImgSection'
import GrievanceRedressalTextSection from '../GrievanceRedressalTextSection'
import { width } from '../../../ReusableComp/Buttons'


const GrievanceRedressalContent = () => {
    return (
        <>

            <GrievanceRedressalImgSection />
            <div className="flex flex-col py-10">
                <div className={`flex flex-col items-start self-center px-5 mt-5 w-full max-w-${width} max-md:max-w-full mx-auto`}>
                <GrievanceRedressalTextSection />
                </div>
            </div>
        </>
    )
}

export default GrievanceRedressalContent