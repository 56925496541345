import React from 'react'
import ContactFormSection from '../ContactFormSection'


const ContactUsContent = () => {
    return (
        <>
            <ContactFormSection />
        </>
    )
}

export default ContactUsContent