import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TwoBreadcrumb } from '../../../../ReusableComp/Breadcrumb'
import { BgButton, OutlineButton, width } from '../../../../ReusableComp/Buttons';
import ImgComp from '../../../../ReusableComp/ImgComp';
import * as Images from '../../../../../assets';
import { Link, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../../apiGeneralFunction';
import { AddressModalBox, PaymentFail, PaymentSuccess, Thankyou } from '../../../../ReusableComp/PaymentResponseModalBox';
import ViewShoppingCart from '../../ViewShoppingCart';
import useRazorpay from "react-razorpay";
import { toBeRequired } from '@testing-library/jest-dom/matchers';
import toastPromise from '../../../../../services/toastService';

const ShoppingCartContent = ({ }) => {

  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCodOpen, setIsModalCodOpen] = useState(false);
  const [isModalFailOpen, setIsModalFailOpen] = useState(false);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [address, setAddress] = useState();
  const [note, setNote] = useState();
  const [showNote, setShowNote] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [successData, setSuccessData] = useState([]);
  const [isAddressOpen, setIsAddressOpen] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const [addressData, setAddressData] = useState([]);

  const [pickupData, setPickupData] = useState([]);
  const [selectedStoreOrder, setSelectedStoreOrder] = useState();
  const [orderId, setOrderId] = useState();

  const addressCardFetchData = async () => {
    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);
    // if (!storedToken) {
    //   navigate('/register');
    //   // return;
    // }
    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);
    try {
      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      const result = await fetchWithFormDataToken(`api/v1/list_user_address`, formdata, storedToken);
      if (result?.status === true) {
        setAddressData(result?.data)
      }
      else {
      }
    } catch (error) {
    }
  };

  const tokenValue = localStorage?.getItem('login api response token');
  const storedToken = JSON?.parse(tokenValue);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
    addressCardFetchData();
  }, [])

  const fetchData = async () => {
    const tokenValue = localStorage?.getItem('login api response token');

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
      // return;
    }

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);

      const result = await fetchWithFormDataToken(`api/v1/product_list_cart_item`, formdata, storedToken, abortController);
      if (result?.status === true) {
        setData(result?.data);
        setIsCartEmpty(false);
        setAddress(result?.data?.default_address)
      }
      else {
        setData();
        setIsCartEmpty(true);
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };


  // const removeWishlistItem = async () => {
  //   const tokenValue = localStorage?.getItem('login api response token');

  //   const user_id = localStorage?.getItem('user_data');
  //   const userId = JSON.parse(user_id);

  //   const storedToken = JSON?.parse(tokenValue);
  //   if (!storedToken) {
  //     navigate('/register');
  //     return;
  //   }

  //   const abortController = new AbortController();

  //   try {

  //     const formdata = new FormData();
  //     formdata.append("user_id", userId?.user_id);
  //     formdata.append("is_wish", "0");
  //     formdata.append("product_id", data?.id);

  //     // const result = await fetchWithFormDataToken(`api/v1/remove_wishlist`, formdata, abortController, null);
  //     // if (result?.status === true) {
  //     //   // setTimeout(() => {
  //     //   //   toast.success(result?.message || '', {
  //     //   //     position: 'top-center',
  //     //   //     autoClose: 2000,
  //     //   //   });
  //     //   // }, 1000);
  //     //   fetchData();
  //     // }
  //     // else {
  //     //   // setTimeout(() => { 
  //     //   //   toast.error(result?.message, {
  //     //   //     position: 'top-center',
  //     //   //     autoClose: 2000,
  //     //   //   });
  //     //   // }, 1000);
  //     // }

  //     const response = await toastPromise(
  //       fetchWithFormDataToken('api/v1/remove_wishlist', formdata, storedToken, abortController, null),
  //       {
  //         pending: "Removing Product...",
  //         success: (data) => data.message || "Product is removed",
  //         error: (data) => data.message || "Failed to remove product",
  //       }
  //     );

  //     if (response.status === true) {
  //       fetchData();
  //     }
  //   } catch (error) {

  //   }
  //   finally {

  //     abortController.abort();
  //   }
  // };




  const ProductItem = ({ product, fetchData, is_favorite }) => {

    const [quantity, setQuantity] = useState(product?.qty || 0);
    // const [quantity, setQuantity] = useState(product?.qty || 1);
    const [productId, setProductId] = useState();
    const [price, setPrice] = useState(product?.selling_price);

    const handleIncrement = () => {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      // setPrice(newQuantity * product?.selling_price);
      updateCart(newQuantity);
    }

    const handleDecrement = () => {
      const newQuantity = quantity - 1;
      // if (newQuantity >= 1) {
      //   setQuantity(newQuantity);
      //   // setPrice(newQuantity * product?.selling_price);
      // }
      setQuantity(newQuantity);
      if (newQuantity > 0) {
        // Update the cart with the new quantity
        updateCart(newQuantity);
      } else {
        // Delete item from cart if quantity is 0
        deleteItem();
      }
    }

    // const addWishlistItem = async () => {

    //   const tokenValue = localStorage?.getItem('login api response token');

    //   const user_id = localStorage?.getItem('user_data');
    //   const userId = JSON.parse(user_id);

    //   const storedToken = JSON?.parse(tokenValue);
    //   if (!storedToken) {
    //     navigate('/register');
    //     return;
    //   }

    //   const abortController = new AbortController();

    //   try {

    //     const formdata = new FormData();
    //     formdata.append("id", product?.id);
    //     // formdata.append("is_wish", "1");
    //     formdata.append("product_id", product?.id);

    //     //  const result = await fetchWithFormDataToken(`api/v1/add_wishlist`, formdata, abortController, null);
    //     //  if (result?.status === true) {
    //     //   //  setTimeout(() => {
    //     //   //    toast.success(result?.message || '', {
    //     //   //      position: 'top-center',
    //     //   //      autoClose: 2000,
    //     //   //    });
    //     //   //  }, 1000);

    //     //    fetchData();
    //     //  }
    //     //  else {
    //     //   //  setTimeout(() => {
    //     //   //    toast.error(result?.message, {
    //     //   //      position: 'top-center',
    //     //   //      autoClose: 2000,
    //     //   //    });
    //     //   //  }, 1000);
    //     //  }
    //     const response = await toastPromise(
    //       fetchWithFormDataToken('api/v1/add_wishlist', formdata, storedToken, abortController, null),
    //       {
    //         pending: "Adding wishlist...",
    //         success: (data) => data.message || "Product is added to wishlist",
    //         error: (data) => data.message || "Failed to added in wishlist",
    //       }
    //     );

    //     if (response.status === true) {
    //       fetchData();
    //     }
    //   } catch (error) {

    //   }
    //   finally {

    //     abortController.abort();
    //   }
    // };

    const updateCart = async (newQuantity) => {
      const tokenValue = localStorage?.getItem('login api response token');

      const storedToken = JSON?.parse(tokenValue);
      if (!storedToken) {
        navigate('/register');
        // return;
      }

      const user_id = localStorage?.getItem('user_data');
      const userId = JSON.parse(user_id);

      const abortController = new AbortController();

      try {


        const formdata = new FormData();
        formdata.append("id", product?.id);
        formdata.append("quantity", newQuantity);

        const result = await fetchWithFormDataToken(`api/v1/product_update_to_cart`, formdata, storedToken, abortController, null);
        if (result?.status === true) {
          //  toast.success(result?.message, {
          //    position: 'top-center',
          //    autoClose: 5000,
          //  });
          fetchData();
        }
        else {
        }
        // const response = await toastPromise(
        //   fetchWithFormDataToken('api/v1/product_update_to_cart', formdata, abortController, null),
        //   {
        //     pending: "Updating Product...",
        //     success: (data) => data.message || "Product is updated",
        //     error: (data) => data.message || "Failed to update product",
        //   }
        // );

        // if (response.status === true) {
        //   fetchData();
        // }
      } catch (error) {

      }
      finally {

        abortController.abort();
      }
    }

    const deleteItem = async () => {
      const tokenValue = localStorage?.getItem('login api response token');

      const storedToken = JSON?.parse(tokenValue);
      if (!storedToken) {
        navigate('/register');
        // return;
      }

      const user_id = localStorage?.getItem('user_data');
      const userId = JSON.parse(user_id);

      const abortController = new AbortController();

      try {

        const formdata = new FormData();
        formdata.append("id", product?.id);

        const result = await fetchWithFormDataToken(`api/v1/product_delete_to_cart`, formdata, storedToken, abortController, null);

        if (result?.status === true) {
          //  toast.success(result?.message, {
          //    position: 'top-center',
          //    autoClose: 2000,
          //  });
          fetchData();
        }
        else {

        }
        // const response = await toastPromise(
        //   fetchWithFormDataToken('api/v1/product_delete_to_cart', formdata),
        //   {
        //     pending: "Deleting Product...",
        //     success: (data) => data.message || "Product is deleted",
        //     error: (data) => data.message || "Failed to delete Product",
        //   }
        // );

        // if (response.status === true) {
        //   fetchData();
        // }

      } catch (error) {

      }
      finally {

        abortController.abort();
      }
    };

    return (
      <>
        <section className="flex gap-4 max-md:flex-wrap max-md:mt-5 relative">
          <ImgComponent
            src={product?.product_image || Images.home_product_ring}
            className='w-40 rounded-lg border max-sm:h-36'
            alt={`home_product_ring`}
          />
          <article className="flex flex-1 gap-4 justify-between max-md:flex-wrap ">
            <div className="flex flex-col my-auto max-md:max-w-full">
              <h2 className="text-lg font-medium text-zinc-900 max-md:max-w-full">{product?.name}</h2>
              <div className="flex gap-5 justify-between self-start mt-2 max-md:flex-col">
                {product?.weight && product?.weight?.trim() !== "0" && (
                  <p className="text-sm text-zinc-900">
                    <span className="leading-5 text-gray-500">Weight: </span>
                    <span className="font-semibold leading-5 text-zinc-900">{product?.weight}</span>
                  </p>
                )}
                {product?.size && product?.size?.trim() !== "0" && (
                  <div className="flex gap-2 whitespace-nowrap">
                    <span className="text-sm text-gray-500">Size:</span>
                    <span className="justify-center px-3 py-1 text-xs leading-4 text-red-700 bg-rose-50 rounded-[360px]">{product.size}</span>
                  </div>
                )}

              </div>
              <div className="flex gap-4 self-start mt-2">
                <div className="flex gap-2 whitespace-nowrap leading-[150%]">
                  <p className="text-lg font-bold text-red-700">₹  {Number(price).toLocaleString('en-IN')}</p>
                  {price !== product?.price &&
                    <p className="my-auto text-sm text-neutral-400 line-through">₹  {Number(product?.price).toLocaleString('en-IN')}</p>
                  }
                </div>
                {product?.discount > 0 && (
                  <div className="justify-center p-1 my-auto text-xs font-medium text-emerald-500 rounded-lg bg-green-600 bg-opacity-10">
                    {product?.discount_type === 'flat' ? (
                      <span>
                        {product?.discount < 0 ? '' : 'Flat ₹'}
                        {Math.abs(product?.discount)} off
                      </span>
                    )
                      :
                      <span>
                        {Math.abs(product?.discount)}
                        {product?.discount < 0 ? '' : '%'} off
                      </span>
                    }
                  </div>
                )}
              </div>
            </div>
            <aside className="flex flex-col justify-end">
              <div className="flex flex-col justify-center p-2 mt-16 text-xs font-semibold text-center whitespace-nowrap rounded border border-gray-200 border-solid text-neutral-900 max-md:mt-0">
                <div className="flex gap-3 justify-between">
                  {product?.qty === 0 ?
                    <>
                      <div className='px-2'></div>
                    </>
                    :
                    <div onClick={() => handleDecrement()}>
                      <i className="fa fa-minus text-xs"></i>
                    </div>
                  }
                  <span>{quantity}</span>
                  <div onClick={handleIncrement}>
                    <i className="fa fa-plus text-xs"></i>
                  </div>
                </div>
              </div>
            </aside>
          </article>
          {/* {data?.wishlist_status === true ?
                      <div
                        className="absolute  flex gap-3 ml-5 top-0 right-0 max-md:ml-2.5 items-center justify-end cursor-pointer">
                        <i className="fa fa-heart text-red-800 text-base"
                          onClick={() => removeWishlistItem()}
                        ></i>
                      </div>
                      :
                      <div
                        className="absolute cursor-pointer top-0 right-10">
                        <i className="fa fa-heart-o text-red-800 text-base"
                          onClick={() => addWishlistItem()}
                        ></i>
                      </div>
                    } */}
          <div className="absolute  flex gap-3 ml-5 top-0 right-0 max-md:ml-2.5 items-center justify-end cursor-pointer"
            onClick={() => deleteItem()}
          >
            <ImgComp

              src={Images.icn_delete}
              className="shrink-0 w-5 h-5 aspect-square"
            />
          </div>

        </section>
        <hr className='my-5 ' />
      </>
    );
  }


  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  //Payment Lots Of files display in network response

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  //   script.async = 'true'
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);


  const handlePayment = async () => {

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);
    const options = {
      key: 'rzp_test_nqF5dOkbjWufx8',
      amount: 1 * 100,
      currency: 'INR',
      name: 'MyBzb',
      image: Images.logo,
      description: 'Test Transaction',
      // email: '',
      order_id: "",
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        placeOrder(response?.razorpay_payment_id);
      },
      prefill: {

        contact: '1234567890',
        // contact: phoneNo,
      },
      notes: {
        address: 'Your address',
      },
      theme: {
        color: '#AC3533',
      },
    }

    const rzp = new window.Razorpay(options);
    rzp.open();
  }


  const placeOrder = async (paymentId) => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/');
      // return;
    }

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      formdata.append("note", note || '');
      formdata.append("payment_id", paymentId || '');
      formdata.append("address_id", addressId);
      formdata.append("order_type", selectedValue || '');

      const result = await fetchWithFormDataToken(`api/v1/place_order`, formdata, storedToken, abortController, null);
      if (result?.status === true) {
        if (result?.data?.payment_id === null) {
          setIsModalCodOpen(true)

        } else {
          setIsModalOpen(true)
          setSuccessData(result?.data)
          setIsAddressOpen(false)
          setIsPaymentMethod(false)
        }
      }
      else {
        if (selectedValue === 0) {
          setIsModalCodOpen(true)
        } else {
          setIsModalFailOpen(true)
        }
      }
    } catch (error) {
    }
    finally {
      abortController.abort();
    }
  };


  // const placePickUpOrder = async () => {

  //   console.log("selectedStoreOrder", selectedStoreOrder?.id, selectedStoreOrder, selectedStoreOrder?.store_id);

  //   const tokenValue = localStorage?.getItem('login api response token');
  //   const storedToken = JSON?.parse(tokenValue);
  //   if (!storedToken) {
  //     navigate('/');
  //     // return;
  //   }

  //   const user_id = localStorage?.getItem('user_data');
  //   const userId = JSON.parse(user_id);

  //   const abortController = new AbortController();

  //   try {

  //     const formdata = new FormData();
  //     formdata.append("user_id", userId?.user_id);
  //     formdata.append("note", note || '');
  //     formdata.append("payment_id", '');
  //     formdata.append("store_address_id", selectedStoreOrder?.id || '');
  //     formdata.append("store_id", selectedStoreOrder?.store_id || '');
  //     formdata.append("address_id", addressId);
  //     formdata.append("order_type", 0);

  //     const result = await fetchWithFormDataToken(`api/v1/place_order`, formdata, storedToken, abortController, null);
  //     if (result?.status === true) {
  //       setIsModalCodOpen(true)
  //       setIsPaymentMethod(false)
  //       setTimeout(() => {
  //         navigate('/profile', { state: { openOrderTab: true } });
  //       }, 2000)
  //     }
  //     else {
  //     }
  //   } catch (error) {
  //   }
  //   finally {
  //     abortController.abort();
  //   }
  // };

  const failedButton = () => {
    return (
      <>
        <div>
          <BgButton name={`Retry`} />
        </div>
        <OutlineButton name={`Cancel`} />
      </>
    )
  }

  const ImgComponent = ({ src, alt, className }) => {
    return <img src={src} alt={alt} className={className} />;
  }

  // const AddressModalList = () => {

  //   const [selectedAddressData, setSelectedAddressData] = useState(null);

  //   const AddressCard = ({ selectedAddressData, setSelectedAddressData, onAddressChange, addressId, addressAddData, default_address, flatno_building, address_type, onEdit, onDelete, onSetDefault, street_area, landmark, city, state, country, pincode }) => {

  //     console.log("addressAddData----", addressAddData, selectedAddressData);
  //     const address = [
  //       flatno_building || '',
  //       street_area || '',
  //       landmark || '',
  //       city || '',
  //       state || '',
  //       country || '',
  //       pincode || ''
  //     ].filter(part => part.trim() !== '').join(', ');

  //     const Home = () => {
  //       return (
  //         <div className='flex items-center gap-2'>
  //           <ImgComponent
  //             src={Images.icn_home_black}
  //             className='w-5'
  //             alt={`home`}
  //           />
  //           <div>Home</div>
  //         </div>
  //       )
  //     }

  //     const Office = () => {
  //       return (
  //         <div className='flex items-center gap-2'>
  //           <ImgComponent
  //             src={Images.icn_office_black}
  //             className='w-5'
  //             alt={`office`}
  //           />
  //           <div>Office</div>
  //         </div>
  //       )
  //     }

  //     const Others = () => {
  //       return (
  //         <div className='flex items-center gap-2'>
  //           <ImgComponent
  //             src={Images.icn_location_black}
  //             className='w-5'
  //             alt={`others`}
  //           />
  //           <div>Others</div>
  //         </div>
  //       )
  //     }

  //     const renderContent = (title) => {
  //       switch (title) {
  //         case '1':
  //           return <Home />;
  //         case '2':
  //           return <Office />;
  //         case '3':
  //           return <Others />;
  //         default:
  //           return null;
  //       }
  //     }

  //     const handleRadioChange = (addressAddData) => {
  //       console.log("handleRadioChange", addressAddData);
  //       // setAddressId(addressAddData?.id);
  //       setSelectedAddressData(addressAddData);
  //     }

  //     return (
  //       <section
  //         key={addressAddData?.id}
  //         className="flex flex-row max-sm:items-start justify-start p-4 mt-4 text-xs leading-5 bg-white rounded-lg border border-gray-200 border-solid w-full items-center gap-3 "
  //       >
  //         <input
  //           type="radio"
  //           id={addressAddData?.id}
  //           name="default_address"
  //           value={addressAddData?.id}
  //           // checked={addressAddData?.id || addressAddData?.default_address === "1"}
  //           // checked={addressAddData?.default_address === "1"}
  //           checked={selectedAddressData?.id === addressAddData?.id || addressAddData?.default_address === "1"}
  //           onClick={() => handleRadioChange(addressAddData)}
  //           className='max-sm:mt-2 focus:border-0 focus:outline-0 focus:ring-0'
  //         />

  //         <div className='flex flex-row w-full'>
  //           <div className='w-full'>
  //             <div className="flex gap-2 self-start text-base font-medium whitespace-nowrap text-neutral-900">
  //               <div>{renderContent(address_type)}</div>
  //             </div>
  //             <div className="mt-2 text-neutral-400 text-start">{address}</div>
  //           </div>
  //           {default_address === "1" &&
  //             <div className='text-amber-500 whitespace-nowrap text-xs font-bold'>
  //               <i className="fa fa-check border border-amber-500 rounded-full px-0.5 py-0.5 me-2"></i>
  //               Set as Default
  //             </div>
  //           }
  //         </div>
  //       </section>
  //     );
  //   }


  const AddressModalList = () => {

    const [selectedAddressData, setSelectedAddressData] = useState(null);

    const AddressCard = ({ selectedAddressData, setSelectedAddressData, onAddressChange, addressId, addressAddData, default_address, flatno_building, address_type, onEdit, onDelete, onSetDefault, street_area, landmark, city, state, country, pincode }) => {

      console.log("addressAddData----", addressAddData, selectedAddressData);
      const address = [
        flatno_building || '',
        street_area || '',
        landmark || '',
        city || '',
        state || '',
        country || '',
        pincode || ''
      ].filter(part => part.trim() !== '').join(', ');

      const Home = () => {
        return (
          <div className='flex items-center gap-2'>
            <ImgComponent
              src={Images.icn_home_black}
              className='w-5'
              alt={`home`}
            />
            <div>Home</div>
          </div>
        )
      }

      const Office = () => {
        return (
          <div className='flex items-center gap-2'>
            <ImgComponent
              src={Images.icn_office_black}
              className='w-5'
              alt={`office`}
            />
            <div>Office</div>
          </div>
        )
      }

      const Others = () => {
        return (
          <div className='flex items-center gap-2'>
            <ImgComponent
              src={Images.icn_location_black}
              className='w-5'
              alt={`others`}
            />
            <div>Others</div>
          </div>
        )
      }

      const renderContent = (title) => {
        switch (title) {
          case '1':
            return <Home />;
          case '2':
            return <Office />;
          case '3':
            return <Others />;
          default:
            return null;
        }
      }

      const handleRadioChange = (addressAddData) => {
        console.log("handleRadioChange", addressAddData);
        // setAddressId(addressAddData?.id);
        setSelectedAddressData(addressAddData);
      }

      return (
        <section
        key={addressAddData?.id}
        className="flex flex-row max-sm:items-start justify-start p-4 mt-4 text-xs leading-5 bg-white rounded-lg border border-gray-200 border-solid w-full items-center gap-3 "
      >
        <input
          type="radio"
          id={addressAddData?.id}
          name="default_address"
          value={addressAddData?.id}
          checked={selectedAddressData?.id === addressAddData?.id || (selectedAddressData === null && default_address === "1") }
          onChange={() => handleRadioChange(addressAddData)}
          className='max-sm:mt-2 focus:border-0 focus:outline-0 focus:ring-0'
        />


        <div className='flex flex-row w-full'>
          <div className='w-full'>
            <div className="flex gap-2 self-start text-base font-medium whitespace-nowrap text-neutral-900">
              <div>{renderContent(address_type)}</div>
            </div>
            <div className="mt-2 text-neutral-400 text-start">{address}</div>
          </div>
          {default_address === "1" &&
            <div className='text-amber-500 whitespace-nowrap text-xs font-bold'>
              <i className="fa fa-check border border-amber-500 rounded-full px-0.5 py-0.5 me-2"></i>
              Set as Default
            </div>
          }
        </div>
      </section>
      );
    }

    useEffect(() => {
      if (!selectedAddressData && addressData?.length > 0) {
        const defaultAddress = addressData.find(address => address.default_address === "1");
        if (defaultAddress) {
          setSelectedAddressData(defaultAddress);
        }
      }
    }, [selectedAddressData, addressData]);

    const handleContinueClick = () => {
      let finalSelectedAddress = selectedAddressData;
      if (!selectedAddressData) {
        finalSelectedAddress = addressData?.find((address) => address.default_address === "1");
      }

      console.log("Selected address for Continue:", finalSelectedAddress);
      if (finalSelectedAddress) {
        fetchListAddressCityWise(finalSelectedAddress); // Call the API
        setAddressId(finalSelectedAddress?.id); // Update the address ID
      }
      // fetchListAddressCityWise(finalSelectedAddress)
      // setAddressId(finalSelectedAddress?.id)
    };

    const fetchListAddressCityWise = async (selectedAddressData) => {
      console.log("fetchListAddressCityWise", selectedAddressData);
      const tokenValue = localStorage?.getItem('login api response token');

      const storedToken = JSON?.parse(tokenValue);
      if (!storedToken) {
        navigate('/register');
        // return;
      }

      const user_id = localStorage?.getItem('user_data');
      const userId = JSON.parse(user_id);
      const abortController = new AbortController();

      try {
        const formdata = new FormData();
        formdata.append("city", selectedAddressData?.city);
        formdata.append("city_id", selectedAddressData?.city_id);

        const result = await fetchWithFormDataToken(`api/v1/list_address_city_wise`, formdata, storedToken, abortController);
        if (result?.status === true) {
          setIsPaymentMethod(true);
          setIsAddressOpen(false);
          setPickupData(result?.data)
          console.log("list_address_city_wise", result?.data);
        }
        else {
        }
      } catch (error) {
      }
      finally {
        abortController.abort();
      }
    };

    const AddressButton = () => {
      return (
        <div className="flex gap-4">
          <Link
            to={`/profile`}
            state={{ openAddressTab: true }}
          >
            <button
              type="button"
              className="focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-12 py-3.5 text-red-700 whitespace-nowrap rounded-lg border border-red-700 border-solid max-md:px-5"
            >
              Add Address
            </button>
          </Link>

          <button
            type="button"
            className="focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-8 py-3.5 text-white bg-red-700 rounded-lg max-md:px-5"
            onClick={handleContinueClick}
          >
            Continue
          </button>
        </div>
      )
    }

    return (
      <>
        <div className='flex flex-col max-h-full h-full'>
        <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 overflow-y-auto max-h-full h-full'>
            {addressData?.length > 0 && addressData?.map((addressData, index) => (
              <AddressCard key={index}
                {...addressData}
                addressAddData={addressData}
                selectedAddressData={selectedAddressData}
                setSelectedAddressData={setSelectedAddressData}
              />
            ))}
          </div>

          <div className="flex flex-col justify-center items-end mt-8 text-base font-semibold leading-6 max-md:pl-5 max-md:max-w-full max-md:px-0">
            <AddressButton />
          </div>
        </div >
      </>
    )
  }

  const PaymentMethodList = () => {

    const [selectedStoreOrder, setSelectedStoreOrder] = useState(null);

    useEffect(() => {
      if (pickupData?.length > 0) {
        setSelectedStoreOrder(pickupData[0]);
      }
    }, [pickupData]);


    const handleSelectedStorePickup = (item) => {
      setSelectedStoreOrder(item)
    }

    const placePickUpPaymentOrder = () => {
      if (selectedStoreOrder) {
        console.log("Placing order for store:", selectedStoreOrder);
        // Call your place order function here
        placePickUpOrder(selectedStoreOrder)
      }
    };

    const placePickUpOrder = async (selectedStoreOrder) => {

      console.log("selectedStoreOrder", selectedStoreOrder?.id, selectedStoreOrder, selectedStoreOrder?.store_id);

      const tokenValue = localStorage?.getItem('login api response token');
      const storedToken = JSON?.parse(tokenValue);
      if (!storedToken) {
        navigate('/');
        // return;
      }

      const user_id = localStorage?.getItem('user_data');
      const userId = JSON.parse(user_id);

      const abortController = new AbortController();

      try {

        const formdata = new FormData();
        formdata.append("user_id", userId?.user_id);
        formdata.append("note", note || '');
        formdata.append("payment_id", '');
        formdata.append("store_address_id", selectedStoreOrder?.id || '');
        formdata.append("store_id", selectedStoreOrder?.store_id || '');
        formdata.append("address_id", addressId);
        formdata.append("order_type", 0);

        const result = await fetchWithFormDataToken(`api/v1/place_order`, formdata, storedToken, abortController, null);
        if (result?.status === true) {
          setOrderId(result?.data?.order_id || '');
          setIsModalCodOpen(true)
          setIsPaymentMethod(false)
          // setTimeout(() => {
          //   navigate('/profile', { state: { openOrderTab: true } });
          // }, 2000)
        }
        else {
        }
      } catch (error) {
      }
      finally {
        abortController.abort();
      }
    };

    return (
      <>

        <div className='mybzb_bg_pinkclr rounded-lg p-1 text-start ps-3 text-sm'>
          <span className='mybzb_text_clr '>Note:</span> Select preferable store in case of pickup from store
        </div>
        <div
          className="flex flex-col p-4 mt-4 text-base leading-5 bg-white rounded-lg border border-gray-200 border-solid w-full items-center gap-3 "
        >
          <div className='flex items-center gap-3 justify-start w-full'>
            <input
              type="radio"
              id="0"
              name="paymentMethod"
              value="0"
              checked="0"
              className=' focus:border-0 focus:outline-0 focus:ring-0'
            />
            <div>
              Pick up from Store
            </div>
          </div>

          <div className="flex flex-row grid grid-cols-1 ml-12 sm:grid-cols-1 md:grid-cols-2 md:ml-2 lg:grid-cols-3 lg:ml-2 xl:grid-cols-3 xl:ml-2 gap-3 sm:ml-12 w-full">
          {pickupData?.length > 0 && pickupData.map((item, index) => {

            const mergeAddressData = [
              item?.flatno_building,
              item?.street_area,
              item?.landmark,
              item?.city,
              item?.state,
              item?.country,
              item?.pincode
            ].filter(Boolean).join(', ');

            console.log("mergeAddressData", item);
            return (
              <>
                <div
                  className={`flex flex-col cursor-pointer justify-start items-start border p-3 rounded-lg w-72 h-30 ${selectedStoreOrder === item ? "border-red-700" : ""}`}
                  onClick={() => handleSelectedStorePickup(item)}
                >
                  <div className="font-bold text-base truncate w-full">
                    {item?.storename}
                  </div>
                  <div className="text-sm text-neutral-400 overflow-hidden text-ellipsis w-full">
                    {mergeAddressData}
                  </div>
                </div>
              </>
            );

            // return (
            //   <>
            //     <div className={`flex  flex-col cursor-pointer justify-start items-start border p-5 rounded-lg ${selectedStoreOrder === item ? "border-red-700" : ""}`} onClick={() => handleSelectedStorePickup(item)}>
            //       <div className='font-bold text-base'>{item?.storename}</div>
            //       <div className={`text-sm text-neutral-400`}>
            //         {mergeAddressData}
            //       </div>
            //     </div>
            //   </>
            // )
          })}
          </div>
        </div>
        <footer className="flex flex-col justify-center items-end mt-8 text-base font-semibold leading-6 max-md:pl-5 max-md:max-w-full max-md:px-0">
          <div className="flex gap-4">
            <Link
              to={`/profile`}
              state={{ openAddressTab: true }}
            >
              <button
                type="button"
                className="focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-12 py-3.5 text-red-700 whitespace-nowrap rounded-lg border border-red-700 border-solid max-md:px-5"
                onClick={() => setIsAddressOpen(false) && setIsPaymentMethod(false)}

              >
                Cancel
              </button>
            </Link>

            <button
              type="submit"
              className="focus:border-red-700 focus:outline-0 focus:ring-0  justify-center px-8 py-3.5 text-white bg-red-700 rounded-lg max-md:px-5"
              // onClick={() => placePickUpOrder()}
              onClick={() => placePickUpPaymentOrder()}
            >
              Place Order
            </button>
          </div>
        </footer>
      </>
    )
  }

  const addressRounting = () => {

    // const getProfile = localStorage?.getItem('get user profile');
    // const getProfileData = JSON.parse(getProfile);

    // if (getProfileData?.firstname === "") {
    //   navigate('/profile', { state: { openEditProfileTab: true } });
    // }
    // else {
    //   if (addressData?.length > 0) {
    //     setIsAddressOpen(true)

    //   } else {
    //     navigate('/profile', { state: { openAddressTab: true } });
    //   }
    // }
    if (isEmptyObject(addressData)) {
      navigate('/profile', { state: { openAddressTab: true } });
    } else {
      setIsAddressOpen(true);
    }
  }

  return (
    <>
      <TwoBreadcrumb
        href3={`/`}
        name3={`Cart`}
      />
      <div className="flex flex-col py-20 max-md:py-10">
        <section
          className={`flex flex-col items-center self-center w-full max-w-${width} max-md:max-w-full  max-lg:px-5`}
        >
          {isEmptyObject(data) ?
            <>
              <ViewShoppingCart />
            </>
            :
            <>
              {data?.cart_items?.length > 0 ?
                <div className="flex gap-5 max-lg:flex-col max-w-full w-full">
                  <article className="flex flex-col max-md:ml-0 max-md:w-full max-w-full w-full">
                    <div className="flex flex-col grow items-start max-md:mt-5 max-md:max-w-full max-w-full">
                      <div className="flex flex-col self-stretch px-6 py-5 rounded-2xl border border-solid border-black border-opacity-10 max-md:px-5 max-md:max-w-full">

                        {data?.cart_items?.length > 0 ?
                          (
                            data?.cart_items.map((product, index) => (
                              <ProductItem key={index} product={product} fetchData={fetchData} />
                            ))
                          ) : (
                            <>
                              <div>Cart Is empty</div>
                            </>
                          )}
                      </div>
                      <div className="flex flex-col items-start self-stretch rounded-2xl mt-5">
                        <section className="self-stretch w-full max-md:max-w-full">
                          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
                              <div className="flex flex-col grow leading-[150%] text-neutral-400 max-md:mt-4 max-md:max-w-full">
                                {!showNote &&
                                  <button className="focus:border-gray-200 focus:outline-0 focus:ring-0 flex gap-2 justify-between px-4 py-3.5 text-sm rounded-lg border border-gray-200 border-solid font-[510] text-neutral-400 max-md:flex-wrap max-md:max-w-full">
                                    <span className="my-auto">Add Instruction</span>
                                    <div onClick={() => setShowNote(!showNote)}>
                                      <i className='fa fa-plus'></i>
                                    </div>
                                  </button>
                                }
                                {showNote &&
                                  <div className="flex flex-col justify-center px-3 py-5 rounded-lg border border-gray-200 border-solid max-md:max-w-full">
                                    <div className="flex gap-5 justify-between text-sm font-[510] max-md:pr-5">
                                      <span>Add Instruction</span>
                                      <div className="cursor-pointer" onClick={() => setShowNote(false)}>
                                        <i className='fa fa-minus'></i>
                                      </div>
                                    </div>
                                    <input
                                      placeholder=' Add instruction'
                                      className="focus:border-gray-200 text-black focus:outline-0 focus:ring-0  justify-center p-4 mt-2 text-xs bg-white rounded-lg border border-gray-200 border-solid"
                                      value={note}
                                      onChange={(e) => setNote(e.target.value)}
                                    />
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </article>
                  <aside className="flex flex-col max-md:ml-0 max-md:w-full 2xl:w-[40%] xl:w-[40%] lg:w-[80%]">
                    {data?.order_summary && (
                      <section className="flex flex-col px-6 pt-5 pb-8 text-base max-sm:text-sm rounded-2xl border border-solid border-black border-opacity-10 max-md:px-5 max-md:mt-5">
                        <header className="text-2xl font-bold text-neutral-900">Order Summary</header>
                        <div className="flex gap-5 justify-between mt-6">
                          <p className="leading-[150%] text-neutral-400">Sub Total </p>
                          <p className="font-semibold text-neutral-900">
                            ₹ {Number(data?.order_summary?.Sub_total).toLocaleString('en-IN')}
                          </p>
                        </div>
                        <div className="flex gap-5 justify-between mt-3">
                          <p className="leading-[150%] text-neutral-400">Discount</p>
                          <p className="font-semibold text-red-700">
                            - ₹ {Number(data?.order_summary?.discount).toLocaleString('en-IN')}
                          </p>
                        </div>
                        <div className="flex gap-5 justify-between mt-3">
                          <p className="leading-[150%] text-neutral-400">Coupon Discount</p>
                          <p className="font-semibold text-red-700">
                            - ₹ {Number(data?.order_summary?.coupon_discount).toLocaleString('en-IN')}
                          </p>
                        </div>

                        <div className="flex gap-5 justify-between mt-3 text-neutral-900">
                          <p className="leading-[150%]">Sub total After Discount</p>
                          <p className="font-semibold"
                          >
                            ₹ {Number(data?.order_summary?.Sub_total_after_discount).toLocaleString('en-IN')}
                          </p>
                        </div>
                        <div className="flex gap-5 justify-between mt-3 text-neutral-900">
                          <p className="leading-[150%]">Tax</p>
                          <p className="font-semibold">
                            ₹ {Number(data?.order_summary?.tax).toLocaleString('en-IN')}
                          </p>
                        </div>

                        <div className="flex gap-5 justify-between mt-3">
                          <p className="font-semibold leading-[150%] text-neutral-900">Grand total</p>
                          <p className="font-bold text-red-700">
                            ₹ {Number(data?.order_summary?.grand_total).toLocaleString('en-IN')}
                          </p>
                        </div>
                        <button className="focus:border-red-700 focus:outline-0 focus:ring-0 flex gap-3 justify-center px-8 py-4 mt-6 font-bold tracking-normal text-white bg-red-700 rounded max-md:px-5"
                          onClick={addressRounting}
                        >
                          Proceed to Pay
                          <i className='fa fa-arrow-right text-white text-lg'></i>
                        </button>
                      </section>
                    )}

                  </aside>
                </div>
                :
                <>
                  <ViewShoppingCart />
                </>
              }
            </>
          }
        </section>
      </div>

      {isModalOpen && (
        <PaymentSuccess
          onClose={() => setIsModalOpen(false)}
          modalWidth={`max-w-xl`}
          orderId={orderId || ''}
        />
      )}

      {isModalFailOpen && (
        <PaymentFail
          onClose={() => setIsModalFailOpen(false)}
          modalWidth={`max-w-xl`}
          buttonContent={failedButton}
        />
      )}

      {isModalCodOpen &&
        <Thankyou
          modalWidth={`max-w-xl`}
          onClose={() => setIsModalCodOpen(false)}
          orderId={orderId || '#'}
        />
      }

      {isAddressOpen &&
        <AddressModalBox
          title={`My Addresses`}
          modalContent={<AddressModalList
          // addressId={addressId}
          />}
          modalWidth={`max-w-6xl`}
          onClose={() => {
            setIsAddressOpen(false);
            setAddressId(null);
          }
          }
        />
      }

      {isPaymentMethod &&
        <AddressModalBox
          title={`Payment Method`}
          modalContent={<PaymentMethodList />}
          modalWidth={`max-w-6xl`}
          onClose={() => {
            setIsPaymentMethod(false)
            setSelectedValue(null)
          }
          }
        />
      }


    </>
  )
}

export default ShoppingCartContent