import React, { useEffect, useState } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import { fetchWithFormDataToken } from '../../../../../src/apiGeneralFunction';

function NavbarComponent() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [navbarOffsetTop, setNavbarOffsetTop] = useState(0);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    const [animateHeader, setAnimateHeader] = useState(false);
    const [data, setData] = useState(null);

      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
      useEffect(() => {
        fetchData();
      }, []);

    useEffect(() => {
        const navbarElement = document.querySelector('.navbar-container');
        if (navbarElement) {
            setNavbarOffsetTop(navbarElement.offsetTop);
        }

        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleScroll = () => {
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (isDesktop && currentScrollTop > navbarOffsetTop) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }

        if (currentScrollTop === 0) {
            setShowHeader(true);
        } else {
            if (currentScrollTop > lastScrollTop) {
                setShowHeader(false);
            } else {
                setShowHeader(true);
                setAnimateHeader(true);
            }
        }

        setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    useEffect(() => {
        if (isDesktop) {
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (isDesktop) {
                window.removeEventListener('scroll', handleScroll);
            }
        };
    }, [lastScrollTop, navbarOffsetTop, isDesktop]);

    const fetchData = async () => {


        const user_id = localStorage?.getItem('user_data') || '';
        const userId = user_id ? JSON.parse(user_id) : {};

        const abortController = new AbortController();
    
        try {
          const formdata = new FormData();
          formdata.append("device_type", "web");
          formdata.append("user_id", userId?.user_id);
    
          const result = await fetchWithFormDataToken(`api/v1/general_settings-app-data`, formdata, abortController, null);
          if (result?.status === true) {
            console.log("General API",result?.data?.cart_count)
            setData(result?.data?.cart_count)
          }
          else {
    
          }
        } catch (error) {
    
        }
        finally {
    
          abortController.abort();
        }
      };

    return (
        <div
            className={`flex flex-col w-full z-10 ${isScrolled && isDesktop ? 'fixed' : ''
                }`}
            style={{
                position: isScrolled && isDesktop ? 'fixed' : 'relative',
                top: isScrolled && isDesktop ? 0 : '',
            }}
        >
             <div className={`navbar-container ${animateHeader ? '' : ''}`}>
                    <Header />
                    <Navbar data={data} />
                </div>
        </div>
    );
}

export default NavbarComponent;





// import React, { useEffect, useState } from "react";
// import Header from "./Header";
// import Navbar from "./Navbar";

// function NavbarComponent() {
//     const [isScrolled, setIsScrolled] = useState(false);
//     const [animate, setAnimate] = useState(false);
//     const [scrollDirection, setScrollDirection] = useState(null);
//     const [showHeader, setShowHeader] = useState(true);
//     const [lastScrollTop, setLastScrollTop] = useState(0);
//     const [animateHeader, setAnimateHeader] = useState(false);
//     const [animateNavbar, setAnimateNavbar] = useState(false);
//     const [navbarOffsetTop, setNavbarOffsetTop] = useState(0);
//     const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    

//     useEffect(() => {
//         const navbarElement = document.querySelector('.navbar-container');
//         if (navbarElement) {
//             setNavbarOffsetTop(navbarElement.offsetTop);
//         }

//         const handleResize = () => {
//             setIsDesktop(window.innerWidth > 768);
//         };

//         window.addEventListener('resize', handleResize);

//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     const handleScroll = () => {
//         const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

//         if (currentScrollTop > navbarOffsetTop) {
//             setIsScrolled(true);
//         } else {
//             setIsScrolled(false);
//         }

//         if (currentScrollTop === 0) {
//             // At the top of the page
//             setShowHeader(true);
//             setAnimateHeader(true);
//             setAnimateNavbar(true);
//         } else {
//             if (currentScrollTop > lastScrollTop) {
//                 // Scrolling down
//                 setShowHeader(false);
//                 setAnimateNavbar(true);
//             } else {
//                 // Scrolling up
//                 setShowHeader(true);
//                 setAnimateNavbar(true);
//             }
//         }

//         setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
//     };

//     useEffect(() => {
//         window.addEventListener('scroll', handleScroll);

//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, [lastScrollTop, navbarOffsetTop]);

//     return (
//         <div
//             className={`flex flex-col w-full z-10 ${isScrolled ? 'fixed' : ''
//                 }`}
//             style={{
//                 position: isScrolled ? 'fixed' : 'relative',
//                 top: isScrolled ? 0 : '',
//             }}
//         >
//             {showHeader ? (
//                 <div className={`header-container ${animateHeader ? '' : ''}`}>
//                     <Header />
//                     <Navbar />
//                 </div>
//             )
//                 :
//                 <div className={`navbar-container ${animateNavbar ? ' ' : ''}`}>
//                     <Header />
//                     <Navbar />
//                 </div>
//             }


//         </div>
//     );
// }

// export default NavbarComponent;