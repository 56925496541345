import React, { useState } from "react";
import Layout from "../../ReusableComp/Layout";
import * as Images from '../../../assets';
import { post, postFormData } from "../../../apiGeneralFunction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImgComp from '../../ReusableComp/ImgComp';
import { useNavigate } from "react-router-dom";
import { BgButton } from "../../ReusableComp/Buttons";

const BecomeASeller = () => {

    // const [formData, setFormData] = useState(new FormData());
    const [formData, setFormData] = useState({
        storeName: '',
        email: '',
        phoneNumber: '',
        gstNumber: '',
        ownerFirstName: '',
        ownerLastName: '',
        panNumber: '',
        isAgree: 0,
        password: '',
        confirmpassword: '',
    });

    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const navigate = useNavigate();

    const handleSubmitSeller = async () => {
        try {

            const formdata = new FormData();
            formdata.append("firstname", formData?.ownerFirstName);
            formdata.append("lastname", formData?.ownerLastName);
            formdata.append("email", formData?.email);
            formdata.append("phone", formData?.phoneNumber);
            formdata.append("gst_no", formData?.gstNumber);
            formdata.append("pan_no", formData?.panNumber);
            formdata.append("is_agreed", formData?.isAgree);
            formdata.append("firm_name", formData?.storeName);
            formdata.append("password", formData?.password);
            formdata.append("password_confirmation", formData?.confirmpassword);
            formdata.append("country_code", "+91");
            formdata.append("latitude", "0.000");
            formdata.append("longitude", "0.000");

            const response = await postFormData('api/v1/seller/register', formdata);
            if (response.status === true) {
                toast.success(response.message, {
                    position: 'top-center',
                    autoClose: 3000,
                });
                navigate('/no_approved')
                setFormData("")
            }
            else {
                toast.error(response?.message, {
                    position: 'top-center',
                    autoClose: 3000,
                });
            }
        } catch (error) {
        }
    };

    const handleInputChange = (event) => {
        const { name, storeName, email, phoneNumber, gstNumber, ownerFirstName, ownerLastName, panNumber, password, confirmpassword, checked, isAgree, type, value } = event.target;
        if (type === 'checkbox') {
            setFormData((prevValues) => ({ ...prevValues, [name]: checked ? 1 : 0 }));
        } else {

            setFormData((prevValues) => ({
                ...prevValues,
                [name]: value,
                [storeName]: value,
                [email]: value,
                [phoneNumber]: value,
                [gstNumber]: value,
                [ownerFirstName]: value,
                [ownerLastName]: value,
                [panNumber]: value,
                [password]: value,
                [confirmpassword]: value,
                // [isAgree]: checked ? 0 : 1
            }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formErrors = validateForm(formData);
        if (Object.keys(formErrors)?.length === 0) {
            handleSubmitSeller();
        } else {
            setErrors(formErrors);
        }
    };

    const validateForm = (values) => {
        const errors = {};
        if (!values?.storeName) {
            errors.storeName = 'Store name is required';
        }
        if (!values?.email) {
            errors.email = 'Email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        if (!values?.phoneNumber) {
            errors.phoneNumber = 'Phone number is required';
        }
        if (!values?.gstNumber) {
            errors.gstNumber = 'GSTIN number is required';
        }
        // if (!values?.ownerName) {
        //     errors.ownerName = 'Owner name is required';
        // }
        if (!values?.ownerFirstName) {
            errors.ownerFirstName = 'Owner first name is required';
        }
        if (!values?.ownerLastName) {
            errors.ownerLastName = 'Owner last name is required';
        }
        if (!values?.password) {
            errors.password = 'Password is required';
        }
        if (!values?.confirmpassword) {
            errors.confirmpassword = 'Confirm password is required';
        }
        if (!values?.panNumber) {
            errors.panNumber = 'PAN number is required';
        }
        if (!values?.isAgree) {
            errors.isAgree = 'Please add the confirmation';
        }
        return errors;
    };

    const data = [{ id: 1, title: "Privacy Policy", },
    { id: 2, title: "Terms & Conditions", },];

    const InfoField = ({ label, value, id, name, onChange, error }) => {
        return (
            <div className="flex flex-col">
                {/* <label htmlFor={id} className="text-sm text-neutral-400">
                    {label} <span className="text-red-700">*</span>
                </label> */}
                <input
                    type="text"
                    className={`justify-center px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-neutral-900 ${error ? 'border-red-500' : ''
                        }`}
                    //id={id}
                    // name={name}
                    // defaultValue={value}
                    value={value}
                    onChange={onChange}

                />
                {error && <div className="text-red-500 text-sm">{error}</div>}
            </div>
        );
    };

    return (

        <Layout>
            <main className="flex justify-center items-center px-16 py-20 bg-white max-lg:px-5">
                <div className="max-w-full rounded-2xl shadow-2xl w-[1296px] ">
                    <div className="flex gap-5 max-lg:flex-col max-lg:gap-0 ">
                        <div className="flex flex-col w-6/12 max-lg:ml-0 max-lg:w-full ">
                            <section
                                className="flex flex-col grow justify-between rounded-2xl items-center self-stretch px-20 py-12 w-full bg-white max-lg:px-5 max-lg:max-w-full"
                            // className="rounded-2xl flex flex-col grow justify-between items-center self-stretch px-10 py-12 w-full bg-white max-lg:px-5 max-lg:max-w-full"
                            >

                                <div className="w-full">
                                    <ImgComp
                                        src={Images.logo}
                                        className="max-w-full aspect-[0.99] w-[114px] mx-auto" />
                                </div>
                                <h1 className="mt-10 text-3xl leading-10 text-center">
                                    <span className="">Become a </span> <span className="font-bold text-red-700">Seller</span> </h1>
                                <p className="self-stretch mt-3 text-base text-center text-neutral-400 max-lg:max-w-full">
                                    Vendor registration form
                                </p>

                                <div className="flex gap-4 grid grid-cols-2 w-full mt-8">

                                    <div className="flex flex-col">
                                        <div className="text-sm text-neutral-400">
                                            Store’s Name <span className="text-red-700">*</span>
                                        </div>
                                        <input
                                            type="text"
                                            className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-neutral-900 ${errors.storeName ? 'border-red-500' : ''
                                                }`}

                                            name="storeName"
                                            defaultValue={formData.storeName}
                                            onChange={handleInputChange}

                                        />
                                        {errors.storeName && <div className="text-red-500 text-sm">{errors.storeName}</div>}
                                    </div>


                                    <div className="flex flex-col">
                                        <div className="text-sm text-neutral-400">
                                            Email <span className="text-red-700">*</span>
                                        </div>
                                        <input
                                            type="text"
                                            className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-neutral-900 ${errors.email ? 'border-red-500' : ''
                                                }`}

                                            name="email"
                                            defaultValue={formData.email}
                                            onChange={handleInputChange}

                                        />
                                        {errors.email && <div className="text-red-500 text-sm">{errors.email}</div>}
                                    </div>


                                    <div
                                        className=" text-sm "
                                    >
                                        <label htmlFor="phoneNumber" className=" text-sm text-neutral-400" >
                                            Phone Number
                                        </label>
                                        <div
                                            className={`focus:border-red-800 focus:ring-0 flex gap-1.5 items-start py-1 mt-1.5 pl-2 text-base  rounded-lg border border-gray-200 border-solid text-zinc-800 max-lg:pr-5 ${errors.phoneNumber ? 'border-red-500' : ''
                                                }`}>
                                            <div className="flex  my-auto pe-3">

                                                <div className="flex gap-1 items-center">
                                                    <div className="ps-2">+91</div>
                                                    <i className="fa fa-angle-down"></i>

                                                </div>
                                                <input
                                                    type="number"
                                                    name="phoneNumber"
                                                    // id="phoneNumber"
                                                    defaultValue={formData.phoneNumber}
                                                    onChange={handleInputChange}
                                                    className={`w-40 focus:border-red-800 focus:ring-0 justify-center px-3.5 py-3 focus:border-0 text-sm rounded-lg border-0 text-neutral-900 `}
                                                />

                                            </div>
                                        </div>
                                        {errors.phoneNumber && <div className="text-red-500 text-sm">{errors.phoneNumber}</div>}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-sm text-neutral-400">
                                            GSTIN number <span className="text-red-700">*</span>
                                        </div>
                                        <input
                                            type="text"
                                            className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-neutral-900 ${errors.gstNumber ? 'border-red-500' : ''
                                                }`}

                                            name="gstNumber"
                                            defaultValue={formData.gstNumber}
                                            onChange={handleInputChange}

                                        />
                                        {errors.gstNumber && <div className="text-red-500 text-sm">{errors.gstNumber}</div>}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-sm text-neutral-400">
                                            Owner First Name <span className="text-red-700">*</span>
                                        </div>
                                        <input
                                            type="text"
                                            className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-neutral-900 ${errors.ownerFirstName ? 'border-red-500' : ''
                                                }`}

                                            name="ownerFirstName"
                                            defaultValue={formData.ownerFirstName}
                                            onChange={handleInputChange}

                                        />
                                        {errors.ownerFirstName && <div className="text-red-500 text-sm">{errors.ownerFirstName}</div>}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-sm text-neutral-400">
                                            Owner Last Name <span className="text-red-700">*</span>
                                        </div>
                                        <input
                                            type="text"
                                            className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-neutral-900 ${errors.ownerLastName ? 'border-red-500' : ''
                                                }`}

                                            name="ownerLastName"
                                            defaultValue={formData.ownerLastName}
                                            onChange={handleInputChange}

                                        />
                                        {errors.ownerLastName && <div className="text-red-500 text-sm">{errors.ownerLastName}</div>}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-sm text-neutral-400">
                                            PAN <span className="text-red-700">*</span>
                                        </div>
                                        <input
                                            type="text"
                                            className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-neutral-900 ${errors.panNumber ? 'border-red-500' : ''
                                                }`}

                                            name="panNumber"
                                            defaultValue={formData.panNumber}
                                            onChange={handleInputChange}

                                        />
                                        {errors.panNumber && <div className="text-red-500 text-sm">{errors.panNumber}</div>}
                                    </div>
                                    <div className="flex flex-col justify-between">
                                        {/* <label htmlFor="emailOrMobile" className="self-stretch   text-sm font-medium text-neutral-400 max-lg:mt-10 max-lg:mr-2.5 max-lg:max-w-full" >
                                            New Password
                                        </label> */}
                                        <div className="text-sm text-neutral-400">
                                            Password <span className="text-red-700">*</span>
                                        </div>
                                        <div
                                        >
                                            <div className="w-full relative" >
                                                <input
                                                    type={showNewPassword ? 'text' : 'password'}
                                                    name="password"
                                                    value={formData.password}
                                                    // placeholder="Enter your Password"
                                                    onChange={handleInputChange}

                                                    // onChange={(e) => setNewPassword(e.target.value)}
                                                    className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-3  text-base rounded-lg border w-full border-gray-200 border-solid text-neutral-900 ${errors.password ? 'border-red-500' : ''
                                                        }`}
                                                />
                                                <div
                                                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                >
                                                    <i className={`fa ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'} text-gray-500`}></i>
                                                </div>
                                            </div>
                                            {errors.password && <div className="text-red-500 text-sm">{errors.password}</div>}

                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-between">
                                        <div className="text-sm text-neutral-400">
                                            Confirm Password
                                            <span className="text-red-700">*</span>
                                        </div>
                                        <div className="w-full relative" >
                                            <input
                                                value={formData.confirmpassword}

                                                type={showPassword ? 'text' : 'password'}
                                                name="confirmpassword"
                                                onChange={handleInputChange}
                                                // placeholder="Enter your Confirm Password"
                                                className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-3 mt-1.5 text-base rounded-lg w-full border border-gray-200 border-solid text-neutral-900 ${errors.confirmpassword ? 'border-red-500' : ''
                                                    }`}
                                            />
                                            <div
                                                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'} text-gray-500`}></i>
                                            </div>
                                        </div>
                                        {errors.confirmpassword && <div className="text-red-500 text-sm">{errors.confirmpassword}</div>}
                                    </div>

                                </div>

                                <div className={`flex gap-2 text-sm leading-5 mt-5 w-full items-start`}
                                >
                                    <input type="checkbox" id="checkbox" name="isAgree"

                                        //value={formData?.isAgree}
                                        className={`rounded-md ${formData.isAgree === 1 ? 'mybzb_text_clr' : 'text-neutral-500'}`}
                                        checked={formData.isAgree === 1}
                                        onChange={handleInputChange}
                                    />
                                    <span
                                        className={`flex-1 max-md:max-w-full text-neutral-400`}
                                    >
                                        Agree with Terms & Conditions. here is the information of

                                        <strong className="font-semibold text-red-700"> Lifetime membership </strong>&
                                        <strong className="font-semibold text-red-700"> Pricing Plans</strong>

                                    </span>
                                    {errors.isAgree && <div className="text-red-500 text-sm">{errors.isAgree}</div>}

                                </div>

                                {/* <BgButton name={`Register`} /> */}
                                <button
                                    // type="submit"
                                    onClick={(event) => handleSubmit(event)}
                                    className="justify-center items-center self-center px-16 py-4 mt-6 max-w-full text-lg font-semibold text-center text-white whitespace-nowrap bg-red-700 rounded w-[228px] max-md:px-5">
                                    Register
                                </button>
                                {/* </form> */}
                                <footer className="flex flex-col items-center mt-8 text-sm text-neutral-400 max-md:flex-wrap max-md:max-w-full w-full">
                                    <nav className="flex gap-5 justify-between w-full">
                                        <a href="#privacy-policy">Privacy Policy</a>
                                        <a href="#terms-conditions">Terms & Conditions</a>
                                    </nav>
                                    <p className="self-center mt-8 text-sm text-center text-red-700"> Powered by Headway Business Solutions LLP </p>
                                </footer>
                            </section>
                        </div>
                        <div className="flex flex-col ml-5 w-6/12 max-lg:ml-0 max-lg:w-full ">
                            <div

                                className="flex flex-col grow justify-center w-full register_bg_clr max-lg:max-w-full rounded-r-2xl max-lg:rounded-b-2xl max-lg:rounded-t-none"
                            >
                                <div
                                    className="flex flex-col justify-center max-lg:max-w-full "
                                >

                                    <ImgComp
                                        src={Images.register}
                                        srcSet={Images.register}
                                        alt={'register'}
                                        className={'h-full w-full rounded-r-2xl max-lg:rounded-b-2xl max-lg:rounded-t-none '}

                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </main>
        </Layout>



    );
}

export default BecomeASeller