import React, { useEffect } from "react";
import ImgComp from "../../ImgComp";
import * as Images from '../../../../assets'
import { Link } from "react-router-dom";


const Footer = ({ width }) => {
       useEffect(() => {
           setTimeout(() => {
               window.scrollTo(0, 0);
           }, 0);
       }, []);

  const ContactItem = ({ icon, text }) => (
    <div className="flex gap-5 max-w-full">
      <div className="flex justify-center items-center px-3 w-12 h-12 bg-white rounded-[152px]">
        <img loading="lazy" src={icon} alt="" className="w-6 aspect-square " />
      </div>
      <div className="my-auto text-lg text-neutral-900">{text}</div>
    </div>
  );

  const SocialIcon = ({ icon }) => (
    <div className="flex justify-center items-center px-3 w-11 h-11 bg-white rounded-[360px]">
      <img loading="lazy" src={icon} alt="" className="w-5 aspect-square" />
    </div>
  );

  // const FooterSection = ({ title, links }) => (
  //   <div
  //     // className="flex flex-col text-sm text-neutral-900"
  //     className={`flex flex-col text-sm text-neutral-900 ${title === 'Help' ? 'mobile_help' : ''
  //       }  ${title === 'Contact' ? 'mobile_help' : ''
  //       }`}
  //   >
  //     <div className="text-xl font-semibold ">{title}</div>
  //     {links.map((link, index) => (
  //       <div key={index} className={`mt-${index === 0 ? 5 : 2.5}${index === 1 || index === 3 ? ' max-md:mr-2' : ''}`}>
  //         {link}
  //       </div>
  //     ))}
  //   </div>
  // );
  const FooterSection = ({ title, links }) => (
    <div
      className={`flex flex-col text-sm text-neutral-900 ${title === 'Help' ? 'mobile_help' : ''} ${title === 'Contact' ? 'mobile_help' : ''}`}
    >
      <div className="text-xl font-semibold ">{title}</div>
      {links.map((link, index) => (
        <div key={index} className={`mt-${index === 0 ? 5 : 2.5}${index === 1 || index === 3 ? ' max-md:mr-2' : ''}`}>
          {link.href.startsWith('/') ? (
            <Link to={link.href}>{link.text}</Link>
          ) : (
            <a href={link.href} target="_blank" rel="noopener noreferrer">{link.text}</a>
          )}
        </div>
      ))}
    </div>
  );
  

  // const footerSections = [
  //   {
  //     title: 'Get to Know us',
  //     links: ['Home', 'About MyBzB', 'Contact Us', 'Jobs at MyBzB'],
  //   },
  //   {
  //     title: 'Shop',
  //     links: ['Bracelet', 'Necklace', 'Earring', 'Ring', 'Mangalsutra'],
  //   },
  //   {
  //     title: 'Sell on MyBzB',
  //     links: ['Seller Guide', 'Seller Terms', 'Fulfilment by MyBzB', 'Build Your Brand', 'Store locator'],
  //   },
  //   {
  //     title: 'Help',
  //     links: ['FAQ', 'Purchase Protection', 'Shipping', 'Return & Cancellation', 'Payment & Refund', 'Grievance Redressal'],
  //   },
  // ];

  const footerSections = [
    {
      title: 'Get to Know us',
      links: [
        { text: 'Home', href: '/' },
        { text: 'About MyBzB', href: '/about_us' },
        { text: 'Contact Us', href: '/contact' },
        { text: 'Jobs at MyBzB', href: '/job-listing' },
      ],
    },
    {
      title: 'Sell on MyBzB',
      links: [
        { text: 'Seller Guide', href: '/seller-guide' },
        { text: 'Seller Terms', href: '/seller-terms' },
        { text: 'Fulfilment by MyBzB', href: '/fulfilment-by-mybzb' },
        { text: 'Build Your Brand', href: '/build-your-brand' },
        { text: 'Fraud warning Disclaimer', href: '/fraud-warning-disclaimer' },
        // { text: 'Store locator', href: '/' },
      ],
    },
    {
      title: 'Help',
      links: [
        { text: 'FAQ', href: '/faq' },
        { text: 'Purchase Protection', href: '/purchase-protection' },
        { text: 'Shipping', href: '/shipping' },
        { text: 'Cancellation & Return', href: '/cancellation-return' },
        { text: 'Payment & Refunds', href: '/payment-refunds' },
        { text: 'Grievance Redressal', href: '/grievance-redressal' },
      ],
    },
  ];
  

  const socialIcons = [
    'https://cdn.builder.io/api/v1/image/assets/TEMP/72fba35d6e48edc2bb6236a7cb39a9e7d79d113d1ce070093feda4ac0d3ad154?apiKey=8e5983036f284a13866aae97819cdb73&',
    'https://cdn.builder.io/api/v1/image/assets/TEMP/8bd7d6d49ea2f8a6f705508b835776df9f4d712b9fadbbe82ddbaaa3c643cffb?apiKey=8e5983036f284a13866aae97819cdb73&',
    'https://cdn.builder.io/api/v1/image/assets/TEMP/c36cf001111835760140cfecdd4ea33a3781fa975b521c39a500dbe1ebf79396?apiKey=8e5983036f284a13866aae97819cdb73&',
    'https://cdn.builder.io/api/v1/image/assets/TEMP/16b9829ea379b076d85d9f3f2ae67ea1a10f7a622e718834f66a7ef13dbd1a0f?apiKey=8e5983036f284a13866aae97819cdb73&',
    'https://cdn.builder.io/api/v1/image/assets/TEMP/b1fddf2371a4e056c85a115e61c77f7743e324840d21b8f6a92d5b8f3ad4963e?apiKey=8e5983036f284a13866aae97819cdb73&',
    'https://cdn.builder.io/api/v1/image/assets/TEMP/ce806cb9c607e93abc944dbc10353da9952ca01542aa8df2d7462e3c266b7d23?apiKey=8e5983036f284a13866aae97819cdb73&',
  ];

  return (
    <>
      {/* <div 
    className="flex gap-5 justify-between items-center pt-10 pb-8 bg-rose-50 max-md:flex-wrap border border-t-1 border-x-0 border-b-0 "
    > */}
      <div className="bg-rose-50 pb-8 ">
        <div
          //className={`flex flex-col self-center w-full max-w-${width} max-md:max-w-full mx-auto`}
          className={`flex flex-col items-center self-center mx-auto w-full max-w-${width} max-md:max-w-full`}
        >


          <div className="flex self-stretch max-md:flex-wrap px-5 grid max-lg:grid-col-4 max-md:grid-col-3 grid-cols-1">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e36f7d2d697d09c44b8e3a49930ccc43b340cb06bd72d24a51cc1f1b72eb977e?apiKey=8e5983036f284a13866aae97819cdb73&" alt="" className="shrink-0 my-auto aspect-square fill-red-200 w-[18px]" />
            <div className="flex flex-col max-md:max-w-full w-full">
              <div className="flex flex-col justify-center px-8 py-4 text-2xl bg-white rounded-2xl text-neutral-900 max-md:px-5 max-md:max-w-full">
                <div className="flex gap-5  items-center max-md:flex-wrap max-md:max-w-full">
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/cddc04288ad465cb72f7ed9125626e931107d305e54cb1d1088dac81facf26e2?apiKey=8e5983036f284a13866aae97819cdb73&" alt="" className="shrink-0 max-w-full aspect-square w-[116px]" />
                  <div className="flex flex-col gap-1">
                    <div
                    // className="my-auto max-md:max-w-full"
                    >
                      Discover Elegance Redefined: New Collection Now Available! 💎✨
                    </div>
                    <div className="text-neutral-400 text-base">
                      Explore our exquisite new arrivals and find your perfect piece today!
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 justify-between items-start px-5 mt-12 max-md:flex-wrap max-md:mt-10 max-md:max-w-full grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1">
                {footerSections.map((section, index) => (
                  <FooterSection key={index} title={section.title} links={section.links} />
                ))}
                <div className="flex gap-3 flex-col max-sm:w-full ">
                  <div
                    // className="flex gap-5 justify-between items-start text-xl font-semibold whitespace-nowrap text-neutral-900"
                    className="flex gap-5 justify-between items-start text-xl font-semibold text-neutral-900 w-full"
                  >
                    <div className="self-start">Contact</div>
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e36f7d2d697d09c44b8e3a49930ccc43b340cb06bd72d24a51cc1f1b72eb977e?apiKey=8e5983036f284a13866aae97819cdb73&" alt="" className="shrink-0 self-end mt-6 aspect-square fill-red-200 w-[18px]" />
                  </div>
                  <ContactItem icon="https://cdn.builder.io/api/v1/image/assets/TEMP/47ee97569992a4f09140738d294784007486971cee8bf38d5c6eab4da7f5a859?apiKey=8e5983036f284a13866aae97819cdb73&" text="+91 97376 00096" />
                  <ContactItem icon="https://cdn.builder.io/api/v1/image/assets/TEMP/8815b0c24f20e69ed1d71f4c2d5c1fc463b36a141461a6e57af8455ee33d3d6c?apiKey=8e5983036f284a13866aae97819cdb73&" text="info@mybzb.com" />
                </div>
              </div>
              <div className="flex gap-5 justify-between px-5 mt-12 w-full max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
                <div className="my-auto text-base text-center text-neutral-900">
                  Copyright © 2024 MyBzB. All rights reserved
                </div>
                <div className="flex gap-5 my-auto text-base text-center text-neutral-900">
                  <Link to={`/privacy-policy`}>
                    Privacy Policy
                  </Link>
                  <Link to={`/terms-conditions`}>
                    Terms & Conditions
                  </Link>
                </div>
                <div className="flex gap-2">
                  {/* {socialIcons.map((icon, index) => (
                  <SocialIcon key={index} icon={icon} />
                ))} */}
                  <div className="flex gap-3 my-auto items-center">
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('https://www.facebook.com/mybzb', '_blank');
                      }}
                      className='focus:outline-none'
                    >
                      <ImgComp
                        src={Images.footer_fb}
                        className="w-10 aspect-square"
                      />
                    </button>

                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('https://twitter.com/my_bzb', '_blank');
                      }}
                      className='focus:outline-none'
                    >
                      <ImgComp
                        src={Images.footer_tw}
                        className="w-10 aspect-square"
                      />
                    </button>

                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('https://in.pinterest.com/my_bzb/', '_blank');
                      }}
                      className='focus:outline-none'
                    >
                      <ImgComp
                        src={Images.footer_pin}
                        className="w-10 aspect-square"
                      />
                    </button>

                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('https://www.instagram.com/my_bzb?igsh=MWN6bmE3N3c5MmN1bQ==', '_blank');
                      }}
                      className='focus:outline-none'
                    >
                      <ImgComp
                        src={Images.footer_insta}
                        className="w-10 aspect-square"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default Footer;