import React, { useEffect, useState } from 'react'
import Layout from '../../ReusableComp/Layout'
import Categories from './Categories'
import TopSeller from './TopSeller'
import OurProducts from './OurProducts'
import BaseMaterial from './BaseMaterial'
import ExploreCollection from './ExploreCollection'
import PremiumProducts from './PremiumProducts'
import PopularProducts from './PopularProducts'
import TrendingProducts from './TrendingProducts'
import HomeBanner from './HomeBanner'
import PopularCategories from './PopularCategories'
import { fetchWithFormDataToken } from '../../../apiGeneralFunction'
import { useNavigate } from 'react-router-dom'
import CategoriesSlider from './CategoriesSlider'
import { useTranslation } from 'react-i18next'
import '../../../translationService'


const Homepage = () => {


  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [feature, setFeature] = useState(null);
  const [sectionsToRender, setSectionsToRender] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    fetchData();
  }, [])

  const fetchData = async () => {

    const user_id = localStorage?.getItem('user_data') || '';
    const userId = user_id ? JSON.parse(user_id) : {};

    const tokenValue = localStorage?.getItem('login api response token');

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/');
      // return;
    }

    const abortController = new AbortController();

    try {
      const formdata = new FormData();
      formdata.append("device_type", "web");
      formdata.append("user_id", userId?.user_id);
      
      const result = await fetchWithFormDataToken(`api/v1/get_home_data`, formdata, abortController, null);
      if (result?.status === true) {
        setData(result?.data);
        // const updatedPromotionalsData = result?.data?.PromotionalsData?.slice(0, -1);

        // setData({
        //   ...result?.data,
        //   PromotionalsData: updatedPromotionalsData, // Update state with modified array
        // });
        console.log(result?.data?.PromotionalsData);
        // Exclude index 0 from the PromotionalsData array
        const filteredPromotionalsData = result?.data?.PromotionalsData?.slice(1);
        console.log(filteredPromotionalsData);

        if (filteredPromotionalsData?.length > 0) {
          setSectionsToRender(filteredPromotionalsData.map((_, index) => index));
        } else {
          console.error("PromotionalsData is missing or empty after filtering");
        }
        // if (result?.data?.PromotionalsData?.length > 0) {
        //   setSectionsToRender(result.data.PromotionalsData.map((_, index) => index));
        // } else {
        //   console.error("PromotionalsData is missing or empty");
        // }
      }
      else {
      }
    } catch (error) {
    }
    finally {
      abortController.abort();
    }
  };

  const width = "[1296px]";

  return (
    <>
      <Layout>
        {/* <h1>{t('welcome')}</h1>
      <p>{t('description')}</p> */}
        <HomeBanner data={data?.banners} />
        <CategoriesSlider />
        <OurProducts width={width} data={data?.PromotionalsData[0]?.product || ''} promotinalId={data?.PromotionalsData[0]?.id} title={data?.PromotionalsData[0]?.title} />
        <BaseMaterial width={width} />
        {/* {sectionsToRender.map((index, i) => (
                <ExploreCollection
                    key={data?.PromotionalsData[index]?.id || i} // Use a unique key
                    width={width}
                    data={data?.PromotionalsData[index]?.product || []}
                    title={data?.PromotionalsData[index]?.title || ''}
                />
            ))} */}
        {sectionsToRender.map((index) => {
          const section = data?.PromotionalsData[index + 1]; // Adjust index to skip 0
          if (!section || !section.product || section.product.length === 0) {
            // If there is no product or products array is empty, skip rendering
            return null;
          }

          return (
            <ExploreCollection
             promotinalId={section.id || index} // Use unique key
              width={width}
              data={section.product} // Pass the product data
              title={section.title || ''} // Set the title
            />
          );
        })}

        <PremiumProducts width={width} />
        {/* <TrendingProducts width={width} /> */}
        {/* <TopSeller width={width} data={data?.topSeller}/> */}
        {/* <Categories /> */}
        {/* <PopularCategories width={width} data={data?.topCategory}/> */}
      </Layout>
    </>
  )
}

export default Homepage