import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const PurchaseProtectionImgSection = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5 max-md:px-5`}>
                <div className='flex flex-row max-md:flex-col'>
                    <div className='my-auto mx-6'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            Purchase Protection
                        </div>
                        <div className="my-2  text-4xl font-bold capitalize text-neutral-900 max-md:text-3xl  home_heading  ">
                        Purchase Protection at
                        </div>
                        <HeadingTextImageWrapper
                        text={`My Bharatiya Zaveri Bazaar`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />

                    
                        <div className="mt-6 text-lg  max-md:max-w-full">
                        At {" "}
                            <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar (MyBzB)
                                </span>, we prioritize your satisfaction and trust in every jewellery purchase. Our Purchase Protection is designed to provide you with confidence and assurance throughout your shopping journey. Below, you'll find detailed information about how {" "}
                            <span className="font-semibold text-red-700">MyBzB</span> safeguards your purchases, ensuring a secure and enjoyable experience.
                        </div>
                    </div>    
                    <div className='footer-purchase-protection-image'>
                        <ImgComp
                        src={Images.purchase_protection_policy}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default PurchaseProtectionImgSection