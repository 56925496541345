import React, { useState } from "react";
import * as Images from '../../../../assets';
import ImgComp from "../../../ReusableComp/ImgComp";
import { fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import HeadingTextImageWrapper from "../../../ReusableComp/HeadingTextImageWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { BgButton } from "../../../ReusableComp/Buttons";
import { ToastContainer, toast } from 'react-toastify';


const OurProducts = ({ width, data, title, promotinalId}) => {

    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false);


    // console.log("title",title);
    // console.log("promotionalId",promotinalId.id);
    // const promotionalId = localStorage?.getItem('promotional_id');

    // const handleClick = () => {
    //     if (promotionalId) {
    //         navigate(`/listing/${promotionalId}`);
    //     } else {
    //         console.error('Promotional ID is not available');
    //     }
    // };


    // const PromotionalId = "";
    // const handleSeeAllClick = (PromotionalId) => {
    //     console.log("see all",PromotionalId);
    //     navigate(`/listing`, { state: { selectedPromotionalValue: PromotionalId } });
    // };


    const ProductCard = ({ product_image, is_favorite: initialFavorite, name, price, selling_price, discount, total_review, rating_avg, total_rating, id, discount_type }) => {
        const [isFavorite, setIsFavorite] = useState(initialFavorite);

        const Star = ({ filling }) => {
            if (typeof filling === 'undefined' || filling === 1) {
                return <i className="fa fa-star text-amber-500" />;
            }

            if (filling === 0) {
                return <i className="fa fa-star-o text-amber-500" />;
            }

            if (filling === 0.5) {
                return (
                    // <div className="half-star">
                    <i className="fa fa-star-half-o text-amber-500" />
                    // </div>
                );
            }

            const width = filling * 100 + '%';

            return (
                <div className="star">
                    <div className="fill" style={{ width }}>
                        <i className="fa fa-star text-amber-500" />
                    </div>
                    <div className="empty">
                        <i className="fa fa-star-o text-amber-500" />
                    </div>
                </div>
            );
        };

        const StarRating = ({ value = 0 }) => {
            if (!value || value <= 0) {
                return null;
            }
            return (
                <div className="rating flex items-center gap-1">
                    {Array.from(Array(5)).map((_, index) => {
                        const starSerialNumber = index + 1;

                        if (starSerialNumber <= Math.floor(value)) {
                            return <Star key={starSerialNumber} filling={1} />;
                        }

                        if (starSerialNumber > Math.ceil(value)) {
                            return <Star key={starSerialNumber} filling={0} />;
                        }

                        const filling = value - index;
                        //  if (filling > 0 && filling < 1) {
                        //     return <Star key={starSerialNumber} filling={filling} />;
                        //   }

                        return <Star key={starSerialNumber} filling={filling} />;
                    })}
                </div>
            );
        };

        // const addData = async (id) => {
        //     const tokenValue = localStorage?.getItem('login api response token');
        //     const storedToken = JSON?.parse(tokenValue);
        //     if (!storedToken) {
        //         navigate('/register');
        //         return;
        //     }
        
        //     const user_id = localStorage?.getItem('user_data');
        //     const userId = JSON.parse(user_id);
        
        //     // Prevent multiple clicks
        //     if (isProcessing) {
        //         return;
        //     }
        
        //     // Set the processing state
        //     setIsProcessing(true);
        
        //     const abortController = new AbortController();
        //     try {
        //         const formdata = new FormData();
        //         formdata.append("user_id", userId?.user_id);
        //         formdata.append("product_id", id);
        //         formdata.append("is_wish", "1");
        //         formdata.append("device_type", "Web");
        
        //         const result = await fetchWithFormDataToken(
        //             `api/v1/add_wishlist`,
        //             formdata,
        //             storedToken,
        //             abortController,
        //             null
        //         );
        
        //         toast.dismiss();
        //         if (result?.status === true) {
        //             toast.success(result?.message || 'Product added successfully', {
        //                 position: 'top-center',
        //                 autoClose: 2000,
        //             });
        //             setIsFavorite(true);
        //         } else {
        //             toast.error(result?.message || 'Failed to add product', {
        //                 position: 'top-center',
        //                 autoClose: 2000,
        //             });
        //         }
        //     } catch (error) {
        //         console.error('Error adding product:', error);
        //     } finally {
        //         setIsProcessing(false); // Reset the processing state
        //         abortController.abort();
        //     }
        // };
        

        const addData = async (id) => {
            const tokenValue = localStorage?.getItem('login api response token');
            const storedToken = JSON?.parse(tokenValue);
            if (!storedToken) {
                navigate('/register');
                return;
            }
            
            const user_id = localStorage?.getItem('user_data');
            const userId = JSON.parse(user_id);
            const abortController = new AbortController();
            try {
                const formdata = new FormData();
                formdata.append("user_id", userId?.user_id);
                formdata.append("product_id", id);
                formdata.append("is_wish", "1");
                formdata.append("device_type", "Web");


                const result = await fetchWithFormDataToken(
                    `api/v1/add_wishlist`,
                    formdata,
                    storedToken,
                    abortController,
                    null
                );

                toast.dismiss();
                if (result?.status === true) {
                    // Display success toast
                    toast.success(result?.message || 'Product added successfully', {
                        position: 'top-center',
                        autoClose: 2000,
                    });
                    setIsFavorite(true);
                } else {
                    toast.error(result?.message || 'Failed to add product', {
                        position: 'top-center',
                        autoClose: 2000,
                    });
                }
            } catch (error) {
                console.error('Error adding product:', error);
            } finally {
                abortController.abort();
            }
        };

        const removeData = async (id) => {
            const tokenValue = localStorage?.getItem('login api response token');
            const storedToken = JSON?.parse(tokenValue);
            if (!storedToken) {
                navigate('/register');
                return;
            }
            
            const user_id = localStorage?.getItem('user_data');
            const userId = JSON.parse(user_id);
            const abortController = new AbortController();
            try {
                const formdata = new FormData();
                formdata.append("user_id", userId?.user_id);
                formdata.append("product_id", id);
                formdata.append("is_wish", "0");
                formdata.append("device_type", "Web");


                const result = await fetchWithFormDataToken(
                    `api/v1/remove_wishlist`,
                    formdata,
                    storedToken,
                    abortController,
                    null
                );

                toast.dismiss();
                if (result?.status === true) {
                    // Display success toast
                    toast.success(result?.message || 'Product removed successfully', {
                        position: 'top-center',
                        autoClose: 2000,
                    });
                    setIsFavorite(false);
                } else {
                    toast.error(result?.message || 'Failed to remove product', {
                        position: 'top-center',
                        autoClose: 2000,
                    });
                }
            } catch (error) {
                console.error('Error removing product:', error);
            } finally {
                abortController.abort();
            }
        };

        const handleDetail = (id) => {
        }

        return (
            <>
                <div
                    className="cursor-pointer"
                    onClick={() => navigate('/product_detail', { state: { id } })}
                //onClick={() => handleDetail(id)}
                >
                    <div className="flex flex-col  max-md:ml-0 max-md:w-full h-full">

                        <div
                            className="relative flex justify-between flex-col grow mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid hover:shadow-xl hover:border-0 max-md:pl-5 max-md:mt-6"
                        // className="relative flex justify-between flex-col grow mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid hover:shadow-2xl hover:border-0 max-md:pl-5 max-md:mt-6"
                        >
                            {/* {(discount !== 0 || discount === "0") && (
                                <div className="text-base font-medium bg-red-700 text-white absolute px-3 py-1 right-0 rounded-bl-lg">
                                    {discount}

                                </div>
                            )} */}
                            {(discount > 0 || discount === "") && (
                                <div className="text-base font-medium bg-red-700 text-white absolute px-3 py-1 right-0 rounded-bl-lg">
                                    {/* {data?.discount}% off */}
                                    {discount_type === 'flat' || discount_type === "" ? (
                                        <span>
                                            {discount < 0 ? '' : 'Flat ₹'}
                                            {Math.abs(discount)} off
                                        </span>
                                    )
                                        :

                                        <span>

                                            {Math.abs(discount)}
                                            {discount < 0 ? '' : '% off'}
                                        </span>
                                    }
                                </div>
                            )}
                            {isFavorite ?
                                <div
                                    className="cursor-pointer flex gap-1.5 justify-left text-sm ml-3 font-medium leading-5 text-neutral-900" aria-label="Add to Wishlist">
                                    <i className="fa fa-heart text-red-800 text-lg"
                                        //   onClick={() => removeWishlistItem()}
                                        onClick={(event) => {
                                            event.stopPropagation(); // Prevent event bubbling
                                            removeData(id);
                                        }}
                                    ></i>
                                </div>
                                :
                                <div
                                    className="cursor-pointer flex gap-1.5 justify-left text-sm ml-3 font-medium leading-5 text-neutral-900" aria-label="Add to Wishlist">
                                    <i className="fa fa-heart-o text-red-800 text-lg"
                                        //   onClick={() => addWishlistItem()}
                                        onClick={(event) => {
                                            event.stopPropagation(); // Prevent event bubbling
                                            addData(id);
                                        }}
                                    ></i>
                                </div>
                            }

                            <div className=" p-5 flex  flex-col h-full ">
                                <div
                                // className="object-contain xl:w-full  xl:h-60 2xl:w-full 2xl:h-60 max-sm:w-40 max-sm:h-40 md:w-48 md:h-48 lg:w-full lg:h-40 max-sm:mx-auto"
                                // className={`max-sm:w-40 md:w-48 lg:w-48 lg:h-40 xl:w-full xl:h-60 2xl:w-full  xl:h-60  max-sm:mx-auto `}
                                >
                                    <ImgComp
                                        src={product_image}
                                        alt={name}
                                        className="object-contain xl:w-full  xl:h-60 2xl:w-full 2xl:h-60 max-sm:w-40 max-sm:h-40 md:w-48 md:h-48 lg:w-full lg:h-40 max-sm:mx-auto mx-auto"
                                    // className="object-contain xl:w-full  xl:h-60 2xl:w-full 2xl:h-60 max-sm:w-40 md:w-48 lg:w-48 lg:h-40 max-sm:mx-auto"
                                    //   className={`max-sm:w-40 w-96 max-sm:mx-auto`}
                                    // className={`max-sm:w-40 md:w-48 lg:w-48 lg:h-40 xl:w-full xl:h-60 2xl:w-full  xl:h-60  max-sm:mx-auto`}
                                    />
                                </div>
                                <div className="  flex  flex-col h-full pt-0">
                                    <div className="mt-2.5 text-xl font-semibold text-neutral-900 w-full md:text-base">
                                        {name}
                                    </div>
                                    <div className="flex gap-2 justify-between mt-2">
                                        <div className="text-lg text-neutral-900 md:text-base">
                                            {price !== selling_price &&
                                                <>
                                                    <span className="line-through text-neutral-400">
                                                        {price?.toLocaleString('en-US')}
                                                    </span>{" "} &nbsp;
                                                    <span className="line-through text-neutral-400">₹</span>{" "}
                                                </>
                                            }

                                            <>
                                                <span className="font-[510] text-neutral-900">
                                                    {selling_price?.toLocaleString('en-US')} ₹</span>
                                            </>

                                        </div>
                                    </div>
                                    <div className="flex gap-2 justify-between mt-2">

                                        <div className="w-fit">
                                            <StarRating value={rating_avg} />
                                        </div>
                                        {(total_rating !== 0 || total_rating === "0" || total_rating === "") && (

                                            <div className="text-base leading-6 text-center text-neutral-400">
                                                ({total_rating}) reviews
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="flex flex-col bg-rose-50 pb-10 pt-5">
            <div className={`flex flex-col items-center self-center px-5 w-full max-w-${width} max-md:max-w-full`}>
                {/* <div className="text-lg font-semibold tracking-wider text-center text-red-700 uppercase">
                    Basic and Exquisite
                </div> */}
                {/* <h1 className="mt-2 text-5xl font-medium capitalize text-neutral-900 max-md:text-4xl">
                    Our Products
                </h1> */}
                {/* <div className=" w-auto text-center ">
                    <h1 className="mt-2 text-5xl font-medium capitalize text-neutral-900 max-md:text-4xl home_heading">
                        Our Products
                    </h1>
                    <ImgComp
                        src={Images.heading_underline}
                        alt={''}
                        className={'w-[40%] max-w-full mx-auto'}
                    />
                </div> */}
                <div className="flex gap-2 justify-between mt-2 w-full max-md:flex-wrap max-md:max-w-full lg:mt-5">
                    <HeadingTextImageWrapper
                        // text=" Our Products" 
                        // text=" Featured Item"
                        text={title}
                        className="w-auto text-center max-sm:text-left"
                        textClassName='pe-10 ps-5 '
                        alignTextWrapperClassName="text-center max-sm:text-left"
                    />
                    <div className="flex gap-3 my-auto" onClick={() =>  navigate(`/listing`, { state: { selectedPromotionalValue: promotinalId } })} >

                        <BgButton name={`See all`} />
                    </div>
                </div>
                <section className="self-stretch mt-12 max-md:mt-10 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col grid max-2xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2  max-sm:grid-cols-1 grid-cols-4 max-sm:gap-0 md:gap-3">
                        {/* <FeatureItem {...featureItem} /> */}
                        {/* {productCards.map((card, index) => (<ProductCard key={index} {...card} />))} */}
                        {data && data?.length > 0 && data?.slice(0, 4).reverse().map((card, index) => (
                            <ProductCard key={index} {...card} />
                        ))}
                    </div>
                </section>
            </div>

        </div>
    );
}

export default OurProducts