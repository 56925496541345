import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../ReusableComp/Layout";
import * as Images from '../../../assets';
import { post, postFormData } from "../../../apiGeneralFunction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImgComp from '../../ReusableComp/ImgComp';
import { useNavigate } from "react-router-dom";
import toastPromise from "../../../services/toastService";
import debounce from 'lodash.debounce';
import Footer from "../../ReusableComp/Layout/Footer";
import LogInNavbar from "../../ReusableComp/LogInNavbar";

const Register = ({ setShowVerify = () => { }, setShowLocation = () => { } }) => {


    const [messageVisible, setMessageVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false); // Controls visibility of the error message
    const [number, setNumber] = useState('');
    const navigate = useNavigate();

    // Reset the error state when component mounts
    useEffect(() => {
        setErrorVisible(false);
    }, []);

    const handleSubmit = async () => {
        if (!number) {
            if (!errorVisible) {
                setErrorVisible(true);
                toast.error("Please Enter Mobile Number", {
                    position: 'top-center',
                    autoClose: 1500,
                });

                // Reset error visibility after 2 seconds
                setTimeout(() => setErrorVisible(false), 2000);
            }
            return;
        } else if (number?.length !== 10) {
            if (!errorVisible) {
                setErrorVisible(true);
                toast.error("Mobile number must be 10 digits", {
                    position: 'top-center',
                    autoClose: 1500,
                });

                setTimeout(() => setErrorVisible(false), 2000);
            }
            return;
        }

        const formdata = new FormData();
        formdata.append("phone", number);
        formdata.append("country_code", "+91");

        if (messageVisible) return;

        try {
            setMessageVisible(true);

            const response = await toastPromise(
                postFormData('api/v1/sendotp', formdata),
                {
                    success: (data) => data.message || "OTP sent successfully!",
                    error: (data) => data.message || "Failed to send OTP.",
                }
            );

            if (response.status === true) {
                localStorage.setItem("register api response", JSON.stringify(response?.data?.phone));
                setTimeout(() => {
                    navigate('/verification');
                }, 3000);
                setShowVerify(true);
                setShowLocation(false);
            }
        } catch (error) {
            toast.error("An unexpected error occurred. Please try again.", {
                position: 'top-center',
                autoClose: 3000,
            });
        } finally {
            setTimeout(() => setMessageVisible(false), 3000);
        }
    };






    // const [messageVisible, setMessageVisible] = useState(false);
    // const [errorVisible, setErrorVisible] = useState(false);

    // const navigate = useNavigate();

    // const [number, setNumber] = useState('');



    // const handleSubmit = async () => {

    //     if (number?.length === 0) {
    //         if (!errorVisible) {
                
    //             setErrorVisible(true); // Set error visible to prevent multiple toasts
    //             toast.error("Please Enter Mobile Number", {
    //                 position: 'top-center',
    //                 autoClose: 1500,
    //             });

    //             // Reset error visibility after 2 seconds
    //             setTimeout(() => setErrorVisible(false), 2000);
    //         }
    //         return;
    //     } else if (number?.length !== 10) {
    //         // Show error only if it's not already visible
    //         if (!errorVisible) {
    //             setErrorVisible(true); // Set error visible to prevent multiple toasts
    //             toast.error("Mobile number must be 10 digits", {
    //                 position: 'top-center',
    //                 autoClose: 1500,
    //             });

    //             // Reset error visibility after 2 seconds
    //             setTimeout(() => setErrorVisible(false), 2000);
    //         }
    //         return;
    //     }
    //     const formdata = new FormData();
    //     formdata.append("phone", number);
    //     formdata.append("country_code", "+91");

    //     if (messageVisible) return;

    //     try {
    //         setMessageVisible(true);

    //         const response = await toastPromise(
    //             postFormData('api/v1/sendotp', formdata),
    //             {
    //                 // pending: "Sending OTP...",
    //                 success: (data) => data.message || "OTP sent successfully!",
    //                 error: (data) => data.message || "Failed to send OTP.",
    //             }
    //         );
    //         if (response.status === true) {
    //             localStorage.setItem("register api response", JSON.stringify(response?.data?.phone));
    //             setTimeout(() => {
    //                 navigate('/verification');
    //             }, 3000);
    //             setShowVerify && setShowVerify(true);
    //             setShowLocation && setShowLocation(false);
    //         }

    //     } catch (error) {
    //         toast.error("An unexpected error occurred. Please try again.", {
    //             position: 'top-center',
    //             autoClose: 3000,
    //         });
    //     }
    //     finally {
    //         // Reset message visibility after messages are done displaying
    //         setTimeout(() => setMessageVisible(false), 3000);
    //     }
    // };

    const PolicyLinks = () => (
        <>
            <div className="flex gap-5 justify-between self-stretch mt-9 text-sm text-center text-neutral-400 max-lg:flex-wrap max-lg:max-w-full">
                <a href="/#/privacy-policy">Privacy Policy</a>
                <a href="/#/terms-conditions">Terms &amp; Conditions</a>
            </div>
            <span className="text-red-800 text-sm mt-5">Powered by Headway Business Solutions LLP</span>
        </>
    );

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            handleSubmit();
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* <LogInNavbar /> */}
            <main className="flex justify-center items-center px-16 py-40 bg-white max-lg:px-5">
                <div className="max-w-full rounded-2xl shadow-2xl w-[926px] ">
                    <div className="flex gap-5 max-lg:flex-col max-lg:gap-0 ">
                    <div className="hide-at-1021px flex flex-col w-6/12 max-lg:ml-0 max-lg:w-full">
                            <ImgComp
                                src={Images.register}
                                srcSet={Images.register}
                                alt={'register'}
                                className={'h-full w-full rounded-r-2xl max-lg:rounded-b-2xl max-lg:rounded-t-none '}

                            />
                        </div>
                        <div className="flex flex-col w-6/12 max-lg:ml-0 max-lg:w-full ">
                        
                            <section
                                className="flex flex-col grow justify-between rounded-2xl items-center self-stretch px-10 py-4 max-lg:px-14 max-sm:px-5 w-full bg-white max-lg:max-w-full"
                            // className="rounded-2xl flex flex-col grow justify-between items-center self-stretch px-10 py-12 w-full bg-white max-lg:px-5 max-lg:max-w-full"
                            >
                
                                
                                <div className="px-10 hide-logo-at-larger-screens">
                                {/* <div className="w-full"> */}
                                    {/* <img loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/33182bb4a34d51254c94257e2b3e52809ccb4863687eb854d868e5b9fc8a3488?apiKey=8e5983036f284a13866aae97819cdb73&"
                                        alt="Company logo" className="max-w-full aspect-[0.99] w-[114px] mx-auto" /> */}
                                    <ImgComp
                                        src={Images.logo}
                                        className={`w-36 mx-auto`}
                                    />
                                </div>
                                <h1 className="mt-1 text-3xl leading-10 text-center ">
                                    <span className="">Welcome to </span> <span className="font-bold text-red-700">My BzB</span> </h1>
                                <p className="self-stretch mt-3 text-sm text-center text-neutral-400 max-lg:max-w-full">
                                    India's First My Bharatiya Zaveri Bazar Retail Culture Jewellery Store </p>
                                <div
                                    className="pt-4 self-stretch "
                                >
                                    <label htmlFor="emailOrMobile" className="self-stretch   text-base font-medium text-neutral-400 max-lg:mt-10 max-lg:mr-2.5 max-lg:max-w-full" >
                                        Mobile no.
                                        <span className="text-red-800">*</span>
                                    </label>
                                    <div className="flex gap-1.5 items-start py-px  pl-5 mt-3.5 max-w-full text-base  rounded-lg border border-gray-200 border-solid text-zinc-800 w-full ">
                                        <div className="flex  my-auto pe-3">
                                            <div className="flex ">
                                                <img loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/300a3765c34b6b6d185f8430266221867c7fbc1bc54175e41b4ca73b19a1f5e5?apiKey=8e5983036f284a13866aae97819cdb73&" alt="India flag" className="shrink-0 my-auto w-6 aspect-[1.33]" />

                                            </div>
                                            <div className="flex gap-1">
                                                <div className="ps-2">+91</div>
                                                <img loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/ccbeddf94b3fe91b18ab065a70628d22f9f5bb3a97ce0bd54efa6d0e706ff1fb?apiKey=8e5983036f284a13866aae97819cdb73&"
                                                    alt="Down arrow" className="shrink-0 my-auto w-4 aspect-square" />
                                            </div>

                                        </div>
                                        <div className="w-full" >
                                            <input
                                                id="phone"
                                                type="text"
                                                name="phone"
                                                // onChange={handleInputChange}
                                                value={number}
                                                // onChange={handleChange}
                                                // onInput={(e) => {
                                                //     const inputValue = e.target.value;
                                                //     if (inputValue.length > 10) {
                                                //         setNumber(inputValue.slice(0, 10));
                                                //     }
                                                // }}
                                                // onInput={handleInput}
                                                onChange={(e) => setNumber(e.target.value)}
                                                //   onChange={(e) => setNumber(e.target.value.replace(/\D+/g, ''))}
                                                // onInput={(e) => {
                                                //     const inputValue = e.target.value;
                                                //     if (inputValue.length > 10) {
                                                //       setNumber(inputValue.slice(0, 10));
                                                //     }
                                                //   }}
                                                // onChange={onChange}
                                                //  onChange={handleInputChange}
                                                // onChange={handleChange}
                                                required
                                                inputMode="numeric"
                                                // inputMode="tel"
                                                pattern="[0-9]{1,10}"
                                                maxLength={10}
                                                onKeyPress={(e) => {
                                                    const charCode = e.which ? e.which : e.keyCode;
                                                    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                                        e.preventDefault();
                                                    }
                                                    handleKeyPress(e);
                                                }}
                                                //  onKeyPress={handleKeyPress}
                                                autoFocus
                                                className=" h-14 border-l-1 border-y-0 rounded-e-lg border border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] max-lg:max-w-full focus:border-gray-200 focus:ring-0 w-full"

                                            />
                                        </div>
                                        {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e41831aebca18a2cdfc1eb182fddf221e908f0eb08e9c1d88a66811c552d79b?apiKey=8e5983036f284a13866aae97819cdb73&"
                                    alt=""
                                    className="shrink-0 self-start w-px border border-gray-200 border-solid aspect-[0.02] stroke-[1px] stroke-gray-200" /> */}
                                    </div>
                                </div>
                                <div className="flex flex-col px-20 pb-2 mt-6 max-w-full text-center lg:px-0">
                                    <button
                                        onClick={handleSubmit}
                                        className="justify-center items-center px-20 py-4 text-lg font-medium bg-red-700 text-white rounded max-sm:px-5 max-lg:py-2" >
                                        Get OTP
                                    </button>
                                    <button className="flex gap-2  items-center self-center px-px mt-4 text-sm font-semibold leading-5 text-red-700 whitespace-nowrap text-red-700 ">
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => navigate('/')}>Skip</div>
                                        <i className="fa fa-arrow-right text-red-800"></i>
                                        {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/914c64e8caf43b02b624af135858e4016551e9ff9557352852c1ea5c6d60f43a?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Right arrow" className="shrink-0 w-6 aspect-square" /> */}
                                    </button>
                                </div>
                                <PolicyLinks />
                                {/* </div> */}
                            </section>
                        </div>
                     
                    </div>
                </div>
                <ToastContainer />
            </main>
            {/* <Footer /> */}
        </>
    );
}

export default React.memo(Register)