import React, { useEffect, useState } from 'react'
import { fetchWithFormDataToken } from '../../../../../apiGeneralFunction';
import { useNavigate } from 'react-router-dom';
import * as Images from '../../../../../assets';
import ImgComp from '../../../../ReusableComp/ImgComp';


const LanguageTabContent = () => {

  const navigate = useNavigate();


  const languageJson = [
    {
      id: 1,
      title: 'English',
      images: `${Images.english_flag}`
    },
    {
      id: 2,
      title: 'Hindi',
      images: `${Images.india_flag}`
    },
    {
      id: 3,
      title: 'Gujarat',
      images: `${Images.india_flag}`
    },
    {
      id: 4,
      title: 'Arabic',
      images: `${Images.arabic_flag}`
    },
  ]




  return (
    <>
      {/* <section className="flex flex-col grow justify-center rounded-xl max-md:mt-10 max-md:max-w-full">
        <h1 className="text-3xl font-bold text-left text-zinc-800 max-md:max-w-full">
          Change Language
        </h1>

        <div className='font-bold py-5'>Select your language</div>
        <div className='flex justify-between grid grid-cols-4 gap-5'>
          {languageJson.map((item, index) => {
            return (
              <div
                className='border rounded-lg px-5 py-3 flex items-center gap-3 justify-between'
                key={index}
              >
                <div className='flex flex-row gap-2'>
                  <ImgComp
                    src={item.images}
                    className={` w-5`}
                  />
                  <div className='text-sm'>{item.title}</div>
                </div>
                <input
                  type="radio"
                  className=''
                  checked
                />
              </div>
            )
          })}
        </div>

      </section> */}
    </>
  )

}

export default LanguageTabContent