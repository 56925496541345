import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../ReusableComp/Layout'
import JobsAtMyBzBContent from './JobsAtMyBzBContent'
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../apiGeneralFunction';
import { SearchContext } from '../../ReusableComp/SearchContext';
import Loader from "react-js-loader";
import { TwoBreadcrumb } from '../../ReusableComp/Breadcrumb';

// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

const JobsAtMyBzBPage = () => {

    // const query = useQuery();
    //   const searchQuery = query.get('search')

    // // const { searchQuery, setSearchResults } = useContext(SearchContext);

    // const navigate = useNavigate();

    // const [data, setData] = useState(null);
    // const [categoryData, setCategoryData] = useState([]);
    // const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //     fetchData();
    //     fetchCategoryData();
    //     // fetchLoadingData();
    // }, []);

    //   const fetchLoadingData = async () => {
    //     const abortController = new AbortController();

    //     setLoading(true);
    //     // Simulate data fetching

    //     const formdata = new FormData();
    //     formdata.append("device_type", "web");

    //     const result = await fetchWithFormDataToken(`api/v1/category_list`, formdata, abortController, null);
    //     setData(result?.data); 
    //     setLoading(false);
    // };

    // const fetchData = async () => {

    //     const abortController = new AbortController();

    //     try {
    //         const formdata = new FormData();
    //         formdata.append("device_type", "web");

    //         const result = await fetchWithFormDataToken(`api/v1/general_settings-app-data`, formdata, abortController, null);
    //         if (result?.status === true) {
    //             setData(result?.data)
    //         }
    //         else {

    //         }
    //     } catch (error) {

    //     }
    //     finally {

    //         abortController.abort();
    //     }
    // };

    // const fetchCategoryData = async () => {

    //     const abortController = new AbortController();
    //     setLoading(true);

    //     try {
    //         const formdata = new FormData();
    //         formdata.append("device_type", "web");

    //         const result = await fetchWithFormDataToken(`api/v1/job_list`, formdata, abortController, null);
    //         if (result?.status === true) {
    //             setCategoryData(result?.data)
    //             // setData(result?.data); 
    //             setLoading(false);
    //         }
    //         else {

    //         }
    //     } catch (error) {

    //     }
    //     finally {

    //         abortController.abort();
    //     }
    // };

    return (
        <Layout>
            <TwoBreadcrumb
                href3={`/`}
                name3={`Jobs at MyBzB`}
            />
            <JobsAtMyBzBContent />
        </Layout>
    )
}

export default JobsAtMyBzBPage