import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../ReusableComp/Layout'
import ListingContent from './ListingContent'
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../apiGeneralFunction';
import { SearchContext } from '../../ReusableComp/SearchContext';
import Loader from "react-js-loader";

// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

const ListingPage = () => {

  // const query = useQuery();
  //   const searchQuery = query.get('search')

  // // const { searchQuery, setSearchResults } = useContext(SearchContext);

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [categoryData,setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
    fetchCategoryData();
    // fetchLoadingData();
  }, []);

//   const fetchLoadingData = async () => {
//     const abortController = new AbortController();

//     setLoading(true);
//     // Simulate data fetching

//     const formdata = new FormData();
//     formdata.append("device_type", "web");

//     const result = await fetchWithFormDataToken(`api/v1/category_list`, formdata, abortController, null);
//     setData(result?.data); 
//     setLoading(false);
// };

  const fetchData = async () => {

    const abortController = new AbortController();

    try {
      const formdata = new FormData();
      formdata.append("device_type", "web");

      const result = await fetchWithFormDataToken(`api/v1/general_settings-app-data`, formdata, abortController, null);
      if (result?.status === true) {
        setData(result?.data)
      }
      else {

      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  const fetchCategoryData = async () => {

    const abortController = new AbortController();
    setLoading(true);

    try {
      const formdata = new FormData();
      formdata.append("device_type", "web");

      const result = await fetchWithFormDataToken(`api/v1/category_list`, formdata, abortController, null);
      if (result?.status === true) {
        setCategoryData(result?.data)
        // setData(result?.data); 
    setLoading(false);
      }
      else {

      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };


    return (
        <Layout>
          {/* {loading ? (
                <div className='flex items-center justify-center h-full'>
                    <Loader type="bubble-scale" bgColor="rgb(185,28,28)" color="rgb(185,28,28)" title={"Loading...Please Wait"} size={100} />
                </div>
            ) : (
                <ListingContent 
                filterCategories={categoryData} 
                data={data?.filter_options || ''}
                />
            )} */}
            <ListingContent 
                filterCategories={categoryData} 
                data={data?.filter_options || ''}
                />
        </Layout>
    )
}

export default ListingPage