import React, { useEffect, useRef, useState } from 'react';
import { useDrag } from '@use-gesture/react';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';
import { width } from '../../../ReusableComp/Buttons';
// import '../PopularCategories/popularcat.css';
import { useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import { useGesture } from 'react-use-gesture';
import { Gallery, GalleryImage } from 'react-gesture-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './categoriesslider.css';
import MobileCategorySlider from './MobileCategorySlider';
import Loader from "react-js-loader";


const slideWidth = 10;

const sleep = (ms = 0) => new Promise((resolve) => setTimeout(resolve, ms));


const createItem = (position, idx, items) => {
    const item = {
        styles: {
            transform: `translateX(${position * slideWidth}rem)`,
        },
        player: items[idx],
    };

    switch (position) {
        case items.length / 2 - 1:
        case items.length / 2 + 1:
            item.styles = {
                ...item.styles,
                //  filter: 'grayscale(1)' 
            };
            break;
        case items.length / 2:
            break;
        default:
            item.styles = {
                ...item.styles,
                // opacity: 0 
            };
            break;
    }

    return item;
};


const CategoriesSlider = () => {

    const [index, setIndex] = useState(0);

    const navigate = useNavigate();

    const [items, setItems] = useState([]);
    const [mobileItems, setMobileItems] = useState([]);
    const [activeIdx, setActiveIdx] = useState(0);
    const [isTicking, setIsTicking] = useState(false);
    const [isHovered, setHovered] = useState(false);
    let sliderRef = useRef(null);
    const [isMediumOrSmaller, setIsMediumOrSmaller] = useState(false);


    useEffect(() => {

        const fetchData = async () => {

            const tokenValue = localStorage?.getItem('login api response token');

            const storedToken = JSON?.parse(tokenValue);
            if (!storedToken) {
                navigate('/');
                // return;
            }

            const abortController = new AbortController();

            try {


                const formdata = new FormData();
                formdata.append("device_type", "Web");

                const result = await fetchWithFormDataToken(`api/v1/get_home_data`, formdata, abortController, null);
                if (result?.status === true) {
                    const fetchedItems = result?.data?.topCategory;
                    setItems([...fetchedItems, ...fetchedItems]);
                    setMobileItems(fetchedItems);
                }
                else {

                }
            } catch (error) {

            }
            finally {

                abortController.abort();
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        if (isTicking) sleep(300).then(() => setIsTicking(false));
    }, [isTicking]);


    if (items.length === 0) return <div className='flex items-center justify-center h-full'>
        <div style={{ textAlign: 'center' }}>
            <Loader type="spinner-default" bgColor="rgb(185,28,28)" color="rgb(185,28,28)" size={50} />
            <p style={{ fontSize: '16px', color: 'rgb(185,28,28)' }}>Loading...Please Wait</p>
        </div>
    </div>;


    const handleItemClick = (item) => {
        navigate(`/listing`, { state: { selectedCategoryValue: item?.id } });
    };

    const getSlideItems = () => {
        const slides = [];
        // const slideSize = window.innerWidth <= 768 ? 4 : 8;
        let slideSize;

        if (window.innerWidth >= 1536) { // 2xl
            slideSize = 8;
        } else if (window.innerWidth >= 1280) { // xl
            slideSize = 8;
        } else if (window.innerWidth >= 1024) { // lg
            slideSize = 8;
        } else if (window.innerWidth >= 768) { // md
            slideSize = 6;
        } else if (window.innerWidth >= 640) { // sm
            slideSize = 5;
        } else if (window.innerWidth >= 540) { // sm
            slideSize = 5;
        }
        else if (window.innerWidth >= 440) { // sm
            slideSize = 4;
        } else if (window.innerWidth >= 340) { // sm
            slideSize = 3;
        } else if (window.innerWidth >= 240) { // sm
            slideSize = 2;
        }

        else { // xs
            slideSize = 4;
        }

        const loopedItems = [...items.slice(-slideSize), ...items, ...items.slice(0, slideSize)];

        for (let i = 0; i < loopedItems.length; i += slideSize) {
            slides.push(loopedItems.slice(i, i + slideSize));
        }
    
        return slides;

        // for (let i = 0; i < items.length; i += slideSize) {
        //     slides.push(items.slice(i, i + slideSize));
        // }

        // const lastSlide = slides[slides.length - 1];
        // if (lastSlide.length < slideSize) {
        //     const placeholders = Array(slideSize - lastSlide.length).fill({}); // Empty placeholders
        //     slides[slides.length - 1] = [...lastSlide, ...placeholders];
        // }
        // if (slides.length > 1) {
        //     slides.push(slides[0]);
        // }    

        // return slides;
    };

    // const handleSlideChange = (newIndex) => {
    //     const totalSlides = getSlideItems().length;
    
    //     // Loop back to the first slide when reaching the last one
    //     if (newIndex >= totalSlides) {
    //         setIndex(0);
    //     } else if (newIndex < 0) {
    //         setIndex(totalSlides - 1);
    //     } else {
    //         setIndex(newIndex);
    //     }
    // };
    
    const handleSlideChange = (newIndex) => {
        const totalSlides = getSlideItems().length;

        // If the user reaches the last slide, reset to the first slide
        if (newIndex === totalSlides - 1) {
            setIndex(0);
        } else {
            setIndex(newIndex);
        }
    };


    return (
        <>
            <section className="flex flex-col items-center pt-10 pb-16 max-sm:pb-8">
                <div className={` flex flex-col self-center px-5 w-full max-w-${width} max-md:max-w-full`}>
                    <div className='flex items-center'>
                        <div className="flex gap-5 justify-between w-full max-md:flex-wrap items-center max-md:max-w-full">

                            <HeadingTextImageWrapper
                                text="Categories"
                                className="w-auto text-left"
                                textClassName='pe-10 ps-5'
                                alignTextWrapperClassName="text-left"
                            />

                        </div>
                    </div>
                    {window?.innerWidth <= 1024 ?
                        <div className=''>
                            <MobileCategorySlider items={mobileItems} />
                        </div>
                        :

                        <div className=''>
                            <Gallery
                                index={index}
                                showIndicators={false}
                                // onRequestChange={i => setIndex(i)}
                                onRequestChange={handleSlideChange} 
                                className='mt-12'
                                style={{
                                    //  width: '100%',
                                    height: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                thumbnails={false}

                            >

                                {getSlideItems().map((slide, i) => (
                                    <div key={i}
                                        className={`mx-5 w-full flex-wrap justify-center items-center grid mobile-grid-classes 2xl:grid-cols-8 xl:grid-cols-8 lg:grid-cols-8 md:grid-cols-6 sm:grid-cols-5 max-xs:grid-cols-5 flex flex-row gap-5 max-sm:gap-3`}
                                    >
                                        {slide.map((item, j) => (
                                            <div key={j}
                                                className='cursor-pointer relative flex flex-col w-full '
                                                onClick={() => handleItemClick(item)}
                                            >
                                                <GalleryImage
                                                    src={item?.categoryImage}
                                                    alt={item?.categoryName}
                                                    className='rounded-lg max-sm:max-h-20 max-sm:w-20 max-sm:mx-auto'
                                                />

                                                {/* {window.innerWidth < 768 ? (
                                            <div className='w-full text-center   bottom-2 px-3 '>
                                                <div className="cursor-pointer truncate text-sm max-sm:text-[12px] text-center mt-2 px-3 text-black max-xs:px-0 leading-none"
                                                    onClick={() => handleItemClick(item)}
                                                >
                                                    {item?.categoryName}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='w-full text-center absolute bottom-2 px-3 '>
                                                <div className="cursor-pointer truncate text-sm max-sm:text-[8px] text-center mt-2  bg-black rounded-full px-3 text-white max-xs:px-0"
                                                    style={{ backgroundColor: 'rgb(0 0 0 / 50%)' }}
                                                    onClick={() => handleItemClick(item)}
                                                >
                                                    {item?.categoryName}
                                                </div>
                                            </div>
                                        )
                                        } */}

                                                <div className='w-full text-center absolute bottom-2 px-3 '>
                                                    <div className="cursor-pointer truncate text-sm max-sm:text-[8px] text-center mt-2  bg-black rounded-full px-3 text-white max-xs:px-0"
                                                        style={{ backgroundColor: 'rgb(0 0 0 / 50%)' }}
                                                    >
                                                        {item?.categoryName}
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </Gallery>
                        </div>
                    }
                </div>
            </section>

        </>
    );
};

export default CategoriesSlider

