import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ImgComp from "../../ReusableComp/ImgComp";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Images from '../../../assets';
import { useSearch } from "../SearchContext";


const LogInNavbar = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const SearchBar = ({ }) => {

        // const [query, setQuery] = useState('');
        const { query, setQuery } = useSearch();

        // const navigate = useNavigate();
        // const { handleSearch } = useContext(SearchContext);

        useEffect(() => {
            if (location.pathname !== '/listing') {
                setQuery('');
            }
        }, [location]);

        const onSearch = (e) => {
            e.preventDefault();
            if (query) {
                navigate(`/listing?search=${query}`);
            } else {
                navigate(`/listing?search=`);
            }
        };


        // const handleSearch = () => {
        //     navigate(`/listing`, { state: { searchNavbarValue: "" } });
        // };

        const handleKeyPress = (e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
                onSearch(e);
            }
        };



        return (
            <>
                <div
                    // className="w-[70%] max-md:w-full flex gap-3 self-stretch justify-between px-5 py-3.5 my-auto text-sm leading-5 bg-white rounded-lg  ms-0 border "
                    className="max-lg:w-full lg:w-full md:w-full sm:w-full flex gap-3 self-stretch justify-between px-5 py-3.5 my-auto text-sm leading-5 bg-white rounded-lg  ms-0 border "
                // onClick={() => navigate('/listing')}
                >
                    {/* <Link to={'/listing'}> */}
                    <div className="flex gap-5 items-center justify-between w-full max-md:w-full">
                        <input
                            className="text-sm p-0 border-0 w-full focus:border-0 focus:outline-0 focus:ring-0"
                            placeholder="Search for Jewellery"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            onKeyPress={(e) => {

                                handleKeyPress(e);
                            }}

                        />
                        {/* <div
                            onClick={onSearch}
                        >

                            <button className="px-3  focus:border-0 focus:outline-0 focus:ring-0">
                                <i className="fa fa-search text-stone-300"></i>
                            </button>
                        </div> */}
                        {query && (
                            <div onClick={onSearch}>
                                <button className="px-3  focus:border-0 focus:outline-0 focus:ring-0">
                                    <i className="fa fa-search text-stone-300"></i>
                                </button>
                            </div>
                        )}
                    </div>
                    {/* </Link> */}
                </div>
            </>
        );
    }

    const UserActions = () => {


        return (
            <div className="max-md:w-full flex justify-end items-end  w-full">
                {/* {userActions.map((action, index) => (<img key={index} loading="lazy" src={action.icon} alt={action.alt} className="shrink-0 w-5 aspect-square" />))} */}
                <div className="flex gap-5 items-center my-auto me-0 text-end ">
                  

                    <div className="flex flex-row justify-between gap-3 ">
                        <div className="flex self-stretch cursor-pointer items-center "
                        >
                            <Link 
                            // to={'http://3.83.101.94/#/seller'}
                            to={'https://seller.mybzb.com/#/seller'}
                            >

                                <div className="hover:text-red-800 max-md:text-xs">Become a Seller</div>

                            </Link>
                        </div>

                                {/* <Link to={'/login'}> */}
                                <div className="flex gap-1.5 self-stretch cursor-pointer"
                                    onClick={() => navigate('/register')}
                                >
                                    <div className="hover:text-red-800 max-md:text-xs">Login / Register</div>
                                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d25d9aa6dfc583330d683b7b957fa3baeaa7b497e51aec61bad6bec8461dab48?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Login/Register icon" className="shrink-0 self-start w-5 aspect-square max-sm:w-4" />
                                </div>

                                {/* </Link> */}



                    </div>
                </div>

            </div >
        );
    }
    return (
        <>

            <nav
                // className={` ${isScrolled ? 'fixed' : ''} flex z-10  justify-center items-center px-16 py-4 w-full text-base bg-rose-50 shadow-2xl text-neutral-900 max-md:px-5 max-md:max-w-full `}
                // style={{
                //     position: isScrolled ? 'fixed' : 'relative',
                //     top: isScrolled ? 0 : '',
                // }}
                className="flex   justify-center items-center px-16 py-4 w-full text-base bg-rose-50 shadow-xl text-neutral-900 max-md:px-5 max-md:max-w-full "
            >
                <div
                    className="my-auto flex lg:flex-row xl:flex-row 2xl:flex-row gap-5 justify-between lg:items-center  xl:items-center  2xl:items-center max-w-full w-[1296px]"
                // className={`flex flex-col items-center  max-w-${width} max-md:max-w-full mx-auto max-lg:px-5`}
                >
                    {/* <div className="max-md:flex-row"> */}
                    <div className="grow gap-5 max-md:gap-3 flex flex-row md:w-full lg:w-full md:justify-between max-sm:w-full">
                        <Link to={'/'}>
                            <ImgComp
                                src={Images.logo}
                                className="shrink-0 self-stretch aspect-square max-w-[74px]"
                            />
                        </Link>
                        {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b3911bae32f07d6edb1aeab8cc0ef8377f9419cfe118a2dd3f91437cbf1745c1?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Logo" className="shrink-0 self-stretch aspect-square w-[74px]" /> */}
                        <SearchBar />

                    </div>

                    <UserActions />

                </div>

                <ToastContainer />
            </nav>

        </>
    );
    }


export default React.memo(LogInNavbar)