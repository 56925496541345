import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const BuildBrandImgSection = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5 max-md:px-5`}>
                <div className='flex flex-row max-md:flex-col'>
                    <div className='my-auto mx-6'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            Build Your Brand
                        </div>
                        <div className="my-2 text-4xl font-bold capitalize text-neutral-900 max-md:text-3xl  home_heading  ">
                        Build Your
                        </div>
                        <HeadingTextImageWrapper
                        // text=" Our Products" 
                        // text=" Featured Item"
                        text={`Jewellery Brand With Us`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />
                    
                        <div className="mt-6 text-lg  max-md:max-w-full">
                        Welcome to{" "}<span className="font-semibold text-red-700">My Bharatiya Zaveri Bazaar (MyBzB)</span>, where we invite passionate and talented jewellers to build and showcase their unique brands to a global audience.
                        </div>
                    </div>    
                    <div>
                        <ImgComp
                        src={Images.build_your_brand}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default BuildBrandImgSection