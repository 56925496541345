import React from 'react'

const StarRating = ({ value = 0 }) => {
        if (!value || value <= 0) {
            return null;
        }
    const Star = ({ filling }) => {
        if (typeof filling === 'undefined' || filling === 1) {
            return <i className="fa fa-star text-amber-500" />;
        }

        if (filling === 0) {
            return <i className="fa fa-star-o text-amber-500" />;
        }

        if (filling === 0.5) {
            return (
                <>
                {/* <div className="half-star"> */}
                    <i className="fa fa-star-half-o text-amber-500" />
                {/* </div> */}
                </>
            );
        }

        const width = filling * 100 + '%';
 
        return (
            <div className="star">
                <div className="fill" style={{ width }}>
                    <i className="fa fa-star text-amber-500" />
                </div>
                <div className="empty">
                    <i className="fa fa-star-o text-amber-500" />
                </div>
            </div>
        );
    };

     // Ensure we always display stars, even if no rating is provided.
     const normalizedValue = value || 0; // Default to 0 if value is null/undefined

    return (
        <div className="rating flex gap-1">
            {Array.from(Array(5)).map((_, index) => {
                const starSerialNumber = index + 1;

                if (starSerialNumber <= Math.floor(normalizedValue)) {
                    return <Star key={starSerialNumber} filling={1} />;
                }

                if (starSerialNumber > Math.ceil(normalizedValue)) {
                    return <Star key={starSerialNumber} filling={0} />;
                }

                const filling = normalizedValue - index;

                return <Star key={starSerialNumber} filling={filling} />;
            })}
        </div>
    )
}

export default StarRating


// import React from 'react';

// const StarRating = ({ value = 0 }) => {
//     if (!value || value <= 0) {
//         return null;
//     }

//     const Star = ({ filling }) => {
//         if (filling === 1) {
//             return <i className="fa fa-star text-amber-500" />;
//         }

//         if (filling === 0.5) {
//             return <i className="fa fa-star-half-o text-amber-500" />;
//         }

//         return <i className="fa fa-star-o text-amber-500" />;
//     };

//     return (
//         <div className="rating flex gap-1">
//             {Array.from({ length: 5 }).map((_, index) => {
//                 const starIndex = index + 1;

//                 if (value >= starIndex) {
//                     // Full star
//                     return <Star key={index} filling={1} />;
//                 } else if (value > index && value < starIndex) {
//                     // Half star
//                     return <Star key={index} filling={0.5} />;
//                 } else {
//                     // Empty star
//                     return <Star key={index} filling={0} />;
//                 }
//             })}
//         </div>
//     );
// };

// export default StarRating;
