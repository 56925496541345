import React, { forwardRef, useEffect, useRef, useState, memo, useCallback } from 'react'
import Layout from '../../../../ReusableComp/Layout';
import { width } from '../../../../ReusableComp/Buttons';
import { fetchData, fetchWithFormDataToken } from '../../../../../apiGeneralFunction';
import { useNavigate } from 'react-router-dom';
import { AddressModalBox } from '../../../../ReusableComp/PaymentResponseModalBox';
import { CustomDropdown, FormInput, FormNumberInput, InputWithAsterisk, InputWithoutAsterisk } from '../../../../ReusableComp/InputType';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImgComp from '../../../../ReusableComp/ImgComp';
import * as Images from '../../../../../assets';
import toastPromise from '../../../../../services/toastService';



const MyAddressTabContent = ({ }) => {

  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [data, setData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [updateId, setUpdateId] = useState('');

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [addAddressAllData, setAddAddressAllData] = useState(null);

  const [apiSuccessResponse, setApiSuccessResponse] = useState('');
  const [apiFailResponse, setApiFailResponse] = useState('');

  const [countryID, setCountryID] = useState();
  const [stateID, setStateID] = useState();
  const [cityID, setCityID] = useState();
  const [isLoading, setIsLoading] = useState(false);


  const [formData, setFormData] = useState({
    flat: '',
    street: '',
    country: '',
    countryLabel: '',
    city: '',
    pincode: '',
    state: '',
    stateLabel: '',
    landmark: '',
    addressType: "Home",
    // isDefault: false,
    isDefault: data?.length === 0,
  });



  useEffect(() => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitudeCords = position.coords.latitude;
          setLatitude(latitudeCords)
          const longitudeCords = position.coords.longitude;
          setLongitude(longitudeCords)
        })
    } else {
    }

  }, [])

  const handleChange = useCallback((e) => {

    const { name, id, flat, value, area, city, state, country, pincode, type, checked, } = e.target;
    if (name === 'addressType') {
      setFormData((prevData) => ({ ...prevData, addressType: value }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        [flat]: value,
        [area]: value,
        [city]: value,
        [state]: value,
        [country]: value,
        [pincode]: value,
        [id]: type === "checkbox" ? checked : value,
      }));
    }
  }, [setFormData]);

  const [formEditData, setFormEditData] = useState({
    flat: '',
    street: '',
    country: '',
    countryLabel: '',
    city: '',
    pincode: '',
    state: '',
    stateLabel: '',
    landmark: '',
    addressType: '',
    isDefault: '',
  });


  const handleEditChange = (e) => {

    const { name, id, flat, value, area, city, state, country, pincode, type, checked, } = e.target;

    // if (name === 'addressType') {
    //   setFormEditData((prevData) => ({
    //     ...prevData,
    //     addressType: value,
    //   }));
    // }
    // else {
    setFormEditData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked ? "true" : "false") : value,
      [flat]: value,
      [area]: value,
      [city]: value,
      [state]: value,
      [country]: value,
      [pincode]: value,
      [id]: type === "checkbox" ? checked : value,
      // addressType: value,
    }));
    // }
  };

  const handleSubmit = async () => {

    // Prevent multiple submissions if a request is already in progress
  if (isLoading) return;
  setIsLoading(true); // Set loading state to true before starting the request

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    toast.dismiss();

    if (!storedToken) {
      navigate('/');
      setIsLoading(false);
      return;
    }

    if (!formData || Object.keys(formData).length === 0) {
      toast.error("Form data is missing!", {
        position: 'top-center',
        autoClose: 2000,
      });
      setIsLoading(false);
      return;
    }

if (!formData?.addressType) {
    toast.error("Address type is required", {
      position: 'top-center',
      autoClose: 2000,
    });
    setIsLoading(false);
    return;
  }

  if (!formData?.flat) {
    toast.error("Flat No./Building is required!", {
      position: 'top-center',
      autoClose: 2000,
    });
    setIsLoading(false);
    return;
  }

  if (!formData?.street) {
    toast.error("Street/Area is required!", {
      position: 'top-center',
      autoClose: 2000,
    });
    setIsLoading(false);
    return;
  }

  if (!formData?.city) {
    toast.error("City is required!", {
      position: 'top-center',
      autoClose: 2000,
    });
    setIsLoading(false);
    return;
  }

  if (!formData?.state) {
    toast.error("State is required!", {
      position: 'top-center',
      autoClose: 2000,
    });
    setIsLoading(false);
    return;
  }

  if (!formData?.country) {
    toast.error("Country is required!", {
      position: 'top-center',
      autoClose: 2000,
    });
    setIsLoading(false);
    return;
  }

  if (!formData?.pincode) {
    toast.error("Pincode is required!", {
      position: 'top-center',
      autoClose: 2000,
    });
    setIsLoading(false);
    return;
  }

    const abortController = new AbortController();

    const renderContent = (title) => {
      switch (title) {
        case 'Home':
          return "1";
        case 'Office':
          return "2";
        case 'Other':
          return "3";
        default:
          return null;
      }
    };

    try {

      const formdata = new FormData();

      formdata.append("user_id", userId?.user_id);
      formdata.append("address_type", renderContent(formData?.addressType));
      formdata.append("default_address", formData?.isDefault === true ? "1" : "0");
      formdata.append("landmark", formData?.landmark);
      formdata.append("city_id", formData?.city);
      formdata.append("state_id", formData?.state);
      formdata.append("country_id", formData?.country);
      formdata.append("latitude", latitude);
      formdata.append("longitude", longitude);
      formdata.append("flatno_building", formData?.flat);
      formdata.append("street_area", formData?.street);
      formdata.append("city", formData?.cityLabel);
      formdata.append("state", formData?.stateLabel);
      formdata.append("country", formData?.countryLabel);
      formdata.append("pincode", formData?.pincode);

      // const result = await fetchWithFormDataToken(`api/v1/add_user_address`, formdata, storedToken, abortController);

      // if (result?.status === true) {
      //   // toast.success(result?.message || 'Address added successfully');
      //   setIsModalOpen(false)
      //   addressData()
      //   setFormData({})

      // }
      // else {
      //   toast.error(result?.message || 'Failed to add address');
      // }
      const response = await toastPromise(
        fetchWithFormDataToken('api/v1/add_user_address', formdata, storedToken, abortController),
        {
          // pending: "Adding Address...",
          success: (data) => data?.message || "Address is added",
          error: (data) => data?.message || "Failed to add address",
        }
      );

      if (response.status === true) {
        setIsModalOpen(false)
        addressData()
        setFormData({})
      }

    } catch (error) {
      // toast.error("An error occurred while adding the address.");
    }
    finally {
      setIsLoading(false); 
      abortController.abort();
    }
  }

  const handleUpdateSubmit = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    toast.dismiss();

    if (!storedToken) {
      navigate('/');
    }

    const abortController = new AbortController();

    const renderContent = (title) => {
      switch (title) {
        case 'Home':
          return "1";
        case 'Office':
          return "2";
        case 'Other':
          return "3";
        default:
          return null;
      }
    };

    try {

      const formdata = new FormData();

      formdata.append("id", updateId);
      formdata.append("user_id", userId?.user_id);
      formdata.append("address_type", renderContent(formEditData?.addressType) || formEditData?.addressType);
      formdata.append("default_address", formEditData?.isDefault === "false" ? "0" : "1");
      formdata.append("landmark", formEditData?.landmark);

      formdata.append("city_id", formEditData?.city || cityID);
      formdata.append("state_id", formEditData?.state || stateID);
      formdata.append("country_id", formEditData?.country || countryID);
      formdata.append("latitude", latitude);
      formdata.append("longitude", longitude);
      formdata.append("flatno_building", formEditData?.flat);
      formdata.append("street_area", formEditData?.street);

      formdata.append("city", formEditData?.cityLabel);
      formdata.append("state", formEditData?.stateLabel);
      formdata.append("country", formEditData?.countryLabel);
      formdata.append("pincode", formEditData?.pincode);

      // const result = await fetchWithFormDataToken(`api/v1/update_user_address`, formdata, storedToken, abortController);

      //  if (result?.status === true) {
      //   //  setTimeout(() => {
      //   //    toast.success(result?.message || '', {
      //   //      position: 'top-center',
      //   //      autoClose: 3000,
      //   //    });
      //   //  }, 1000);
      //    setIsModalEditOpen(false)
      //    addressData()
      //    setFormEditData({})
      //    setShow(false)
      //  }
      //  else {
      //   //  setTimeout(() => {
      //   //    toast.error(result?.message || '', {
      //   //      position: 'top-center',
      //   //      autoClose: 3000,
      //   //    });
      //   //  }, 1000);
      //  }

      const response = await toastPromise(
        fetchWithFormDataToken('api/v1/update_user_address', formdata, storedToken, abortController),
        {
          // pending: "Updating Address...",
          success: (data) => data?.message || "Address is updated",
          error: (data) => data?.message || "Failed to update address",
        }
      );

      if (response?.status === true) {
        setIsModalEditOpen(false)
        addressData()
        setFormEditData({})
        setShow(false)
      }
    } catch (error) {
      // toast.error("An error occurred while adding the address.");
    }
    finally {

      abortController.abort();
    }
  }


  const handleDelete = async (Id) => {
    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    toast.dismiss();
  
    if (!storedToken) {
      navigate('/');
      return;
    }
  
    const abortController = new AbortController();
  
    try {
      const formdata = new FormData();
      formdata.append("id", Id);
  
      const response = await toastPromise(
        fetchWithFormDataToken('api/v1/delete_user_address', formdata, storedToken, abortController),
        {
          pending: "Deleting Address...",
          success: (data) => data?.message || "Address successfully deleted",
          error: (data) => data?.message || "Failed to delete address",
        }
      );
  
      if (response?.status === true) {
        // Remove the deleted address from the state without refreshing
        addressData((prevData) =>
          prevData.filter((address) => address.id !== Id)
        );
      }
    } catch (error) {
      console.error("Error deleting address:", error);
    } finally {
      abortController.abort();
    }
  };
  


  const handleSetAsDefault = async (Id) => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    if (!storedToken) {
      navigate('/');
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();

      formdata.append("user_id", userId.user_id);
      formdata.append("address_id", Id);

      const result = await fetchWithFormDataToken(`api/v1/set_user_address_default`, formdata, storedToken, abortController);

      if (result?.status === true) {
        // setTimeout(() => {
        //   toast.success(result?.message || '', {
        //     position: 'top-center',
        //     autoClose: 3000,
        //   });
        // }, 1000);
        addressData()

      }
      else {
        // setTimeout(() => {
        //   toast.error(result?.message || '', {
        //     position: 'top-center',
        //     autoClose: 3000,
        //   });
        // }, 1000);
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const controller = new AbortController();

  useEffect(() => {

    addressData();
    fetchCountry();

    return () => {

      controller.abort();
    };
  }, []);



  const fetchCountry = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);


    if (!storedToken) {
      navigate('/');
    }

    const abortController = new AbortController();

    try {

      const result = await fetchData(`api/v1/list_country`, storedToken, abortController);

      if (result?.status === true) {

        const formattedOptions = result?.data.map(item => ({

          value: item?.id,
          label: item?.title
        }));
        setCountryList(formattedOptions);
      }
      else {
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  }

  const addressData = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/');
      // return;
    }

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);

      const result = await fetchWithFormDataToken(`api/v1/list_user_address`, formdata,storedToken, abortController, null);
      if (result?.status === true) {
        setData(result?.data)
        if ((result?.data || []).length === 0) {
          setFormData(prevState => ({
            ...prevState,
            isDefault: true,
          }));
        } else {
          setFormData(prevState => ({
            ...prevState,
            isDefault: false,
          }));
        }
      }
      else {

      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  const AddressCard = ({ addressAddData, default_address, flatno_building, address_type, onEdit, onDelete, onSetDefault, street_area, landmark, city, state, country, pincode }) => {

    const address = [
      flatno_building || '',
      street_area || '',
      landmark || '',
      city || '',
      state || '',
      country || '',
      pincode || ''
    ].filter(part => part.trim() !== '').join(', ');


    const Home = () => {
      return (
        <div className='flex items-center gap-2'>
          <ImgComp
            src={Images.icn_home_black}
            className='w-5'
          />
          <div>Home</div>
        </div>
      )
    }

    const Office = () => {
      return (
        <div className='flex items-center gap-2'>
          <ImgComp
            src={Images.icn_office_black}
            className='w-5'
          />
          <div>Office</div>
        </div>
      )
    }

    const Others = () => {
      return (
        <div className='flex items-center gap-2'>
          <ImgComp
            src={Images.icn_location_black}
            className='w-5'
          />
          <div>Others</div>
        </div>
      )
    }

    const renderContent = (title) => {
      switch (title) {
        case '1':
          return <Home />;
        case '2':
          return <Office />;
        case '3':
          return <Others />;
        default:
          return null;
      }
    };

    return (
      <section className="flex flex-col p-4 mt-4 max-w-full text-xs leading-5 bg-white rounded-lg border border-gray-200 border-solid ">
        <div className="flex gap-2 self-start text-base font-medium whitespace-nowrap text-neutral-900">
          <div>{renderContent(address_type)}</div>
        </div>
        <div className="mt-2 text-neutral-400">{address}</div>
        <hr className='my-3' />
        <div className="flex  justify-between gap-1 grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3  2xl:grid-cols-3  max-md:pr-5">
          <div className="flex gap-1 text-neutral-400 cursor-pointer" onClick={() => editButton(addressAddData)}>
            <ImgComp
              src={Images.icn_edit}
              className={`shrink-0 self-start w-4 aspect-square`}
            />
            <div>Edit</div>
          </div>
          <div className="flex gap-1 text-red-700 cursor-pointer">
            <ImgComp
              src={Images.icn_delete}
              className={`shrink-0 self-start w-4 aspect-square`}
            />
            <div onClick={() => handleDelete(addressAddData?.id)}>Delete</div>
          </div>
          {addressAddData?.default_address === "0" &&
            <div className="flex gap-1 text-amber-500 cursor-pointer items-center"
              onClick={() => handleSetAsDefault(addressAddData?.id)}
            >
              <i className='fa fa-check text-amber-500 text-[10px] border border-amber-500 px-0.5 py-0.5 rounded-full'></i>
              <div>Set as Default</div>
            </div>
          }
        </div>
      </section>
    );
  }

  useEffect(() => {
    if (formData?.country) {
      fetchState(formData?.country);
    }
  }, [formData?.country]);

  useEffect(() => {
    if (addAddressAllData?.country) {
      fetchState(addAddressAllData?.country);
    }
  }, [addAddressAllData?.country]);

  useEffect(() => {
    if (formData?.country && formData?.state) {
      fetchCity(formData?.country, formData?.state,);
    }
  }, [formData?.country, formData?.state]);



  const fetchState = async (countryId) => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);

    if (!storedToken) {
      navigate('/');
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("country_id", countryId);

      const result = await fetchWithFormDataToken(`api/v1/list_state`, formdata, storedToken, abortController);

      if (result?.status === true) {

        const formattedOptions = result.data.map(item => ({

          value: item?.id,
          label: item?.title
        }));
        setStateList(formattedOptions);
      }
      else {
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  }

  const fetchCity = async (countryId, stateId) => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);

    if (!storedToken) {
      navigate('/');
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("country_id", countryId);
      formdata.append("state_id", stateId);

      const result = await fetchWithFormDataToken(`api/v1/list_city`, formdata, storedToken, abortController);

      if (result?.status === true) {

        const formattedOptions = result.data.map(item => ({

          value: item?.id,
          label: item?.title
        }));
        setCityList(formattedOptions);
      }
      else {
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  }



  const AddressOptions = ({ selectedOption, onChange, onKeyDown, onClick }) => {

    const [focusedOption, setFocusedOption] = useState(null);
    const [focused, setFocused] = useState(false);

    // const handleKeyDown = (e) => {
    //   if (e.key === 'Tab') {
    //     setFocused(true);
    //   }
    // };

    // const handleClick = () => {
    //   setFocused(true);
    // };
    const handleContainerClick = (e) => {
      const target = e.target.closest('input[type="radio"]');
      if (target) {
        onChange({ target });
      }
    };

    // const handleKeyDown = (e) => {
    //   if (e.key === 'Enter') {
    //     const target = e.target.closest('input');
    //     if (target) {
    //       onChange({ target });
    //     }
    //   }
    // };
    const handleKeyDown = (e, option) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onChange({ target: { name: 'addressType', value: option } });
      }
    };

    return (
      <div className="flex gap-4 self-start mt-4 "
        // onClick={handleClick}
        // tabIndex={0} 
        // onKeyDown={handleKeyDown}

        onClick={handleContainerClick}

        role="radiogroup"
        aria-labelledby="addressTypeLabel"
      >
        {["Home", "Office", "Other"].map((option, index) => (
          <div key={option}
            className="flex gap-2 text-sm leading-6 text-black whitespace-nowrap "
            onKeyDown={(e) => handleKeyDown(e, option)}

          >
            <input
              type="radio"
              id={option}
              name="addressType"
              value={option}
              checked={selectedOption === option}
              onChange={onChange}
              className="my-auto focus:border-red-800 active:border-red-800 focus:ring-red-800"
            // tabIndex={0} 


            />
            <label htmlFor={option} className="my-auto"
              tabIndex={0}
            >{option}</label>
          </div>
        ))}
      </div>
    )
  };

  const renderEditContent = (title) => {

    switch (title) {
      case '1':
        return "Home";
      case '2':
        return "Office";
      case '3':
        return "Other";
      default:
        return null;
    }
  };


  const AddressEditOptions = ({ selectedOption, onChange }) => {
    return (
      <div className="flex gap-4 self-start mt-4 ">
        {["Home", "Office", "Other"].map((option) => (
          <div key={option} className="flex gap-2 text-sm leading-6 text-black whitespace-nowrap " >
            <input
              type="radio"
              // id={option}
              id={`address-${option}`}
              // name="addressEditType"
              // name={`address-${option}`}
              name="addressType"
              value={option}
              checked={selectedOption === option}
              onChange={onChange}
              className="my-auto"
              tabIndex={0}
            />
            <label
              htmlFor={`address-${option}`}
              // htmlFor="addressEditType"
              className="my-auto"
              tabIndex={0}
            >{option}</label>
          </div>
        ))}
      </div>
    )

  };

  const onCancelBtn = () => {
    setIsModalOpen(false)
    setFormData({})

  }


  const modalContent = (
    <>
      <div className="flex flex-col rounded-2xl justify-between">
        <div>
          <div className="flex gap-4 grid xl:grid-cols-3 2xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 max-sm:grid-cols-1">
            <FormInput
              label="Flat No./Building"
              name="flat"
              value={formData.flat}
              onChange={handleChange}
            />

            <FormInput
              label="Street/Area"
              name="street"
              value={formData.street}
              onChange={handleChange}
            />
            <FormNumberInput
              label="Pincode"
              name="pincode"
              type="number"
              value={formData.pincode}
              onChange={handleChange}
            />
            <CustomDropdown
              value={formData?.country}
              onChange={(value) => {
                setFormData(prev => ({ ...prev, country: value.id, countryLabel: value.label }));
              }}
              options={countryList}
              placeholder="Select Country"
              labelName={`Country`}
            />
            <CustomDropdown
              value={formData?.state}
              onChange={(value) => {
                setFormData(prev => ({ ...prev, state: value.id, stateLabel: value.label }));
              }}
              options={stateList}
              placeholder="Select State"
              labelName={`State`}
            />

            <CustomDropdown
              value={formData?.city}
              onChange={(value) => {
                setFormData(prev => ({ ...prev, city: value.id, cityLabel: value.label }));
              }}
              options={cityList}
              placeholder="Select City"
              labelName={`City`}
            />

          </div>
          <div className="text-sm text-neutral-400 max-md:max-w-full mt-4 text-start">
            <label htmlFor="landmark" className="sr-only">
              Landmark (Optional)
            </label>
            Landmark (Optional)
          </div>
          <textarea
            name="landmark"
            id="landmark"
            className="w-full focus:border-red-800 focus:outline-0 active:border-0 focus:ring-0 max-sm:h-28 h-28 justify-center items-start px-3 py-2 mt-2 text-sm leading-5 bg-white rounded-md border border-gray-200 border-solid text-black max-md:pr-5 max-md:max-w-full"
            placeholder="Type message here..."
            value={formData.landmark}
            onChange={(e) => handleChange(e)}
            aria-label="Landmark (Optional)"
          ></textarea>
          <section className="self-start mt-4 text-base font-medium leading-7 text-black text-start">
            Type of Address
          </section>
          <AddressOptions selectedOption={formData.addressType}
            onChange={(e) => handleChange(e)}

          />
          <section className="flex gap-2 self-start mt-4 text-base leading-6 text-black">
            <input
              type="checkbox"
              id="isDefault"
              value={formData.isDefault}
              checked={formData.isDefault}
              onChange={(e) => handleChange(e)}
              className="shrink-0 my-auto w-4 aspect-square focus:border-red-800 active:border-red-800 focus:ring-red-800 "
            />
            <label htmlFor="isDefault" className="my-auto">Use as default address</label>
          </section>
        </div>
        <div className="flex flex-col justify-center items-end  mt-8 text-base font-semibold leading-6 max-md:pl-5 max-md:max-w-full max-md:px-0">
          <div className="flex gap-4">
            <button
              type="button"
              className="focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-12 py-3.5 text-red-700 whitespace-nowrap rounded-lg border border-red-700 border-solid max-md:px-5"
              onClick={() => onCancelBtn()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="focus:border-0 focus:outline-0 focus:ring-0 justify-center px-8 py-3.5 text-white bg-red-700 rounded-lg max-md:px-5"
              onClick={() => handleSubmit()}
            >
              Save Address
            </button>
          </div>

          {apiSuccessResponse ? (
            <div
              className={`text-green-500 text-base font-medium mt-5 w-full text-center `}
            >
              {apiSuccessResponse}
            </div>
          ) :
            <div
              className={`text-red-500 text-base font-medium mt-5 w-full`}
            >
              {apiFailResponse}
            </div>
          }
        </div>
      </div>
    </>
  )


  const modalEditContent = (
    <>
      <div className="flex flex-col rounded-2xl">
        <div className="flex gap-4 grid  xl:grid-cols-3 2xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 max-sm:grid-cols-1">
          <FormInput
            label="Flat No./Building"
            name="flat"
            value={formEditData.flat}
            onChange={handleEditChange}
          />

          <FormInput
            label="Street/Area"
            name="street"
            placeholder="Enter street or area"
            value={formEditData.street}
            onChange={handleEditChange}
          />
          <FormInput
            label="Pincode"
            name="pincode"
            placeholder="Enter pincode"
            value={formEditData.pincode}
            onChange={handleEditChange}
          />


          {!show ?
            <>
              {/* <div className="flex gap-4 grid  xl:grid-cols-3 2xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 max-sm:grid-cols-1 w-full mt-5" onClick={() => setShow(true)}> */}
              <FormInput
                label="Country"
                name="country"
                value={formEditData.countryLabel}
                onClick={() => setShow(true)}
              />
              <FormInput
                label="State"
                name="state"
                value={formEditData.stateLabel}
                onClick={() => setShow(true)}
              />
              <FormInput
                label="City"
                name="city"
                value={formEditData.cityLabel}
                onClick={() => setShow(true)}
              />
              {/* </div> */}
            </>
            :
            <>
              {/* <div className="flex gap-4 grid  xl:grid-cols-3 2xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 max-sm:grid-cols-1 w-full mt-5"> */}
              <CustomDropdown
                value={formEditData?.country}
                onChange={(value) => {
                  setFormEditData(prev => ({ ...prev, country: value.id, countryLabel: value.label }));
                }}
                options={countryList}
                placeholder="Select Country"
                labelName={`Country`}
              />
              <CustomDropdown
                value={formEditData?.state}
                onChange={(value) => {
                  setFormEditData(prev => ({ ...prev, state: value.id, stateLabel: value.label }));
                }}
                options={stateList}
                placeholder="Select State"
                labelName={`State`}
              />

              <CustomDropdown
                value={formEditData?.city}
                onChange={(value) => {
                  setFormEditData(prev => ({ ...prev, city: value.id, cityLabel: value.label }));
                }}
                options={cityList}
                placeholder="Select City"
                labelName={`City`}
              />
              {/* </div> */}
            </>
          }
        </div>
        <div className="text-sm text-neutral-400 max-md:max-w-full mt-4 text-start">
          <label htmlFor="landmark" className="sr-only">
            Landmark (Optional)
          </label>
          Landmark (Optional)
        </div>
        <textarea
          name="landmark"
          className="w-full justify-center h-20 items-start px-3 py-2 mt-2 text-sm leading-5 bg-white rounded-md border border-gray-200 border-solid text-black max-md:pr-5 "
          placeholder="Type message here..."
          value={formEditData.landmark}
          onChange={(e) => handleEditChange(e)}
          aria-label="Landmark (Optional)"
        ></textarea>
        <section className="self-start mt-4 text-base font-medium leading-7 text-black">
          Type of Address
        </section>
        <AddressEditOptions selectedOption={formEditData.addressType} onChange={handleEditChange} />
        <section className="flex gap-2 self-start mt-4 text-base leading-6 text-black">
          <input
            type="checkbox"
            id="isEditDefault"
            name="isDefault"
            checked={formEditData.isDefault === "true"}
            // onChange={(e) => handleEditChange(e)}
            onChange={handleEditChange}
            className="shrink-0 my-auto w-4 aspect-square"
          />
          <label htmlFor="isEditDefault" className="my-auto">Use as default address</label>
        </section>
        <footer className="flex flex-col justify-center items-end px-16 mt-8 text-base font-semibold leading-6 max-md:pl-5 max-md:max-w-full max-md:px-0">
          <div className="flex gap-4">
            <button
              type="button"
              className="focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-12 py-3.5 text-red-700 whitespace-nowrap rounded-lg border border-red-700 border-solid max-md:px-5"
              onClick={() => setIsModalEditOpen(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-8 py-3.5 text-white bg-red-700 rounded-lg max-md:px-5"
              onClick={() => handleUpdateSubmit()}
            >
              Update Address
            </button>
          </div>
        </footer>
      </div>
    </>
  )


  const onCloseEditIcon = () => {
    setIsModalEditOpen(false)
    setFormEditData({})
    setShow(false)
  }

  useEffect(() => {


    if (addAddressAllData) {
      setFormEditData({
        flat: addAddressAllData.flatno_building,
        street: addAddressAllData.street_area,
        country: matchingCountry?.value || countryID,
        countryLabel: addAddressAllData.country,
        cityLabel: addAddressAllData.city,
        city: matchingCity?.value || cityID,
        pincode: addAddressAllData.pincode,
        state: matchingState?.value || stateID,
        stateLabel: addAddressAllData.state,
        landmark: addAddressAllData.landmark,
        addressType: renderEditContent(addAddressAllData.address_type),
        // isDefault: addAddressAllData.isDefault,
        isDefault: addAddressAllData.default_address === "1" ? "true" : "false"
      });
    }
  }, [addAddressAllData]);

  useEffect(() => {
    if (addAddressAllData?.country && addAddressAllData?.state) {
      fetchCity(addAddressAllData?.country, addAddressAllData?.state,);
    }
  }, [addAddressAllData?.country, addAddressAllData?.state]);


  useEffect(() => {
    if (formEditData?.country) {
      fetchState(formEditData?.country);
    }
  }, [formEditData?.country]);

  useEffect(() => {
    if (formEditData?.country && formEditData?.state) {
      fetchCity(formEditData?.country, formEditData?.state);
    }
  }, [formEditData?.country, formEditData?.state]);

  useEffect(() => {
    if (addAddressAllData?.country && addAddressAllData?.state) {
      fetchCity(addAddressAllData?.country, addAddressAllData?.state);
    }
  }, [addAddressAllData?.country, addAddressAllData?.state]);

  // let defaultAddressShown = false;
  // let savedAddressShown = false;



  const editButton = async (addressAddData) => {

    setUpdateId(addressAddData?.id)
    setIsModalEditOpen(true)
    setAddAddressAllData(addressAddData)

    setCountryID(addressAddData?.country_id);
    setStateID(addressAddData?.state_id);
    setCityID(addressAddData?.city_id);
  }

  const matchingCountry = countryList.find((country) => country?.label === formEditData?.countryLabel);

  const matchingState = stateList.find((state) => state?.label === formEditData?.stateLabel);

  const matchingCity = cityList.find((city) => city?.label === formEditData?.cityLabel);


  const [defaultAddressShown, setDefaultAddressShown] = useState(false);
  const [savedAddressShown, setSavedAddressShown] = useState(false);

  const hasDefaultAddress = data?.some(address => address?.default_address === "1");
  const hasSavedAddress = data?.some(address => address?.default_address === "0");

  const defaultAddresses = data?.filter(address => address?.default_address === "1") || [];
  const savedAddresses = data?.filter(address => address?.default_address === "0") || [];

  useEffect(() => {
    if (hasDefaultAddress) {
      setDefaultAddressShown(true);
    }
    if (hasSavedAddress) {
      setSavedAddressShown(true);
    }
  }, [data]);


  return (
    <>
      <main className={`flex flex-col justify-center rounded-xl max-w-${width} mx-auto`}>
        <header className="w-full  text-3xl font-semibold tracking-wider text-start text-neutral-900 max-md:max-w-full">
          My Addresses
        </header>
        <section className="flex gap-4 justify-end  mt-8 w-full leading-[150%] max-md:flex-wrap max-md:max-w-full">

          <button className="focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-8 py-1.5 text-sm text-center text-white whitespace-nowrap bg-red-700 rounded-md font-[590] max-md:px-5"
            onClick={() => setIsModalOpen(true)}
          >
            Add
          </button>
        </section>
        {/* {data?.length > 0 ?
          <div className=''>

            {data?.sort((b, a) => a.default_address - b.default_address)?.map((addressData, index) => (

              addressData?.default_address === "1" ?
                <>
                  {data?.length > 0 && data?.map((addressData, index) => {
                    if (addressData?.default_address === "1" && !defaultAddressShown) {
                      defaultAddressShown = true;
                      return (
                        <div key={index} className="my-auto text-base font-semibold text-neutral-900 w-full">
                          Default Address
                        </div>
                      );
                    }
                    return null;
                  })}
                  <AddressCard key={index}
                    {...addressData}
                    addressAddData={addressData}
                  />
                </>
                :
                <>
                  {data?.length > 0 && data?.map((addressData, index) => {
                    if (addressData?.default_address === "0" && !savedAddressShown) {
                      savedAddressShown = true;
                      return (
                        <div key={index} className="my-auto text-base font-semibold text-neutral-900 w-full mt-5">
                          Saved Address
                        </div>
                      );
                    }
                    return null;
                  })}
                  <div className='flex gap-5 '>
                    <AddressCard key={index} {...addressData} addressAddData={addressData} />
                  </div>
                </>
            ))}
          </div>
          :
          <></>
        } */}
        {/* {defaultAddressShown && (
          <div className="col-span-3 my-auto text-base font-semibold text-neutral-900 w-full">
            Default Address
          </div>
        )} */}

{defaultAddresses.length > 0 && (
          <>
            <div className="col-span-3 my-auto text-base font-semibold text-neutral-900 w-full">
              Default Address
            </div>
            <div className="grid grid-cols-2 gap-5 max-md:grid-cols-1">
              {defaultAddresses.map((addressData, index) => (
                <AddressCard key={`default-${index}`} {...addressData} addressAddData={addressData} />
              ))}
            </div>
          </>
        )}

        {/* <div className='grid grid-cols-2 gap-5 max-md:grid-cols-1'>
          {data?.filter(address => address?.default_address === "1").map((addressData, index) => (

            <AddressCard key={`default-${index}`} {...addressData} addressAddData={addressData} />
          ))}
        </div> */}

        {/* {savedAddressShown && (
          <div className="col-span-3 my-auto text-base font-semibold text-neutral-900 w-full mt-5">
            Saved Address
          </div>
        )} */}
                {savedAddresses.length > 0 && (
          <>
            <div className="col-span-3 my-auto text-base font-semibold text-neutral-900 w-full mt-5">
              Saved Address
            </div>
            <div className="grid grid-cols-2 w-full gap-5 max-md:grid-cols-1">
              {savedAddresses.map((addressData, index) => (
                <AddressCard key={`saved-${index}`} {...addressData} addressAddData={addressData} />
              ))}
            </div>
          </>
        )}

        {/* <div className='grid grid-cols-2 w-full gap-5 max-md:grid-cols-1'>
          {data?.filter(address => address?.default_address === "0").map((addressData, index) => (
            <AddressCard key={`saved-${index}`} {...addressData} addressAddData={addressData} />
          ))}
        </div> */}
      </main>
      {isModalOpen &&
        <AddressModalBox
          title={`Add Address`}
          modalContent={modalContent}
          modalWidth={`max-w-5xl`}
          onClose={() => setIsModalOpen(false)}
        />
      }

      {isModalEditOpen &&
        <AddressModalBox
          title={`Edit Address`}
          modalContent={modalEditContent}
          modalWidth={`max-w-5xl`}
          onClose={() => onCloseEditIcon()}
        />
      }
      <ToastContainer />
    </>
  )
}

export default MyAddressTabContent