import React, { useState } from 'react';
import ImgComp from '../ImgComp';
import * as Images from '../../../assets';
import { BgButton, OutlineButton } from '../Buttons';
import { Link, useNavigate } from 'react-router-dom';


export const PaymentSuccess = ({ title, content, onClose, buttonContent, modalWidth, orderId }) => {

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    return (
        <div
            style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
            onClick={closeModal}
            //     className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full"
            className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-hidden fixed right-0 left-0  z-50 justify-center items-center w-full md:inset-0 "
        >

            <div
                // className=' bg-white rounded-2xl max-md:h-full w-full max-w-2xl zoom_in_pp_modal' 
                className={`relative  w-full ${modalWidth}  zoom_in_pp_modal`}
                onClick={(e) => e.stopPropagation()}
            // style={{ margin: '2rem 0 !important' }}
            >
                <div
                    // className={` bg-white w-full ${modalWidth}  rounded-2xl p-6 h-full overflow-y-auto mt-16 mb-16`}
                    className='my-16  bg-white overflow-y-auto p-6 rounded-2xl'
                // style={{ height: '-webkit-fill-available' }}
                >

                    <div className='flex items-center justify-center pb-5'>
                        <div className=' text-2xl font-semibold w-full text-left '>

                        </div>
                        <div className='w-auto text-end '>
                            <button type="button" className="bg-white text-black hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-10 h-10 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={closeModal}
                                data-modal-hide="popup-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                    </div>

                    <div className='w-full flex flex-col px-10 text-center'>

                        <ImgComp
                            src={Images.payment_sucess}
                            className='w-[50%] mx-auto'
                        />
                        <div
                            className='text-xl font-bold text-center mt-5'
                        >Order Placed Successfully</div>
                        <div
                            className='text-base mt-5 text-neutral-500'
                        >Your Satisfaction is Our Priority -Watch Outfor Delivery Updates!</div>
                        <div
                            className='text-base my-5 text-neutral-500'
                        >
                            Order ID :- {orderId}
                        </div>

                        {/* <div onClick={() => navigate('/profile')}>
                            <BgButton name={`View Order`} />
                        </div > */}
                        <Link
                            to={`/profile`}
                            state={{ openOrderTab: true }}
                        >
                            <BgButton name={`View Order`} />
                        </Link>
                        <OutlineButton name={`Continue shopping`}
                            onClick={() => navigate('/listing')}
                        />
                    </div>


                </div>
            </div>
        </div>
    )
}

export const PaymentFail = ({ title, content, onClose, buttonContent, modalWidth }) => {

    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        // setIsOpen(false);
        onClose();
    };

    return (
        <div
            style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
            onClick={closeModal}
            className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-hidden fixed right-0 left-0  z-50 justify-center items-center w-full md:inset-0 "
        >

            <div
                className={`relative  w-full ${modalWidth}  zoom_in_pp_modal`}
                onClick={(e) => e.stopPropagation()}

            >
                <div
                    className='my-16  bg-white overflow-y-auto p-6 rounded-2xl'
                    style={{ height: '-webkit-fill-available' }}
                >

                    <div className='flex items-center justify-center pb-5'>
                        <div className=' text-2xl font-semibold w-full text-left '>

                        </div>
                        <div className='w-auto text-end '>
                            <button type="button" className="bg-white text-black hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-10 h-10 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={closeModal}
                                data-modal-hide="popup-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                    </div>

                    <div className='w-full flex flex-col px-10 text-center'>

                        <ImgComp
                            src={Images.payment_failed}
                            className='w-[50%] mx-auto'
                        />
                        <div
                            className='text-xl font-bold text-center mt-5'
                        >Payment Failed</div>
                        <div
                            className='text-base my-5'
                        >Please try again later</div>
                        {buttonContent}
                    </div>


                </div>
            </div>
        </div>
    )
}

export const Thankyou = ({ title, content, onClose, buttonContent, modalWidth, orderId }) => {
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate();

    const closeModal = () => {
        setIsOpen(false);
        onClose();
        navigate('/profile', { state: { openOrderTab: true } });
    };

    return (
        isOpen && (
            <div
                style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
                className="backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-hidden fixed right-0 left-0 z-50 w-full md:inset-0"
                onClick={closeModal} // Close modal when clicking outside the content
            >
                <div
                    className={`${modalWidth} bg-white overflow-y-auto p-6 rounded-2xl zoom_in_pp_modal max-sm:h-fit overflow-y-auto max-h-full`}
                    onClick={(e) => e.stopPropagation()} // Prevent closing the modal when clicking inside
                >
                    <div className="flex items-center justify-center pb-5">
                        <div className="text-2xl font-semibold w-full text-left"></div>
                        <div className="w-auto text-end">
                            <button
                                type="button"
                                className="bg-white text-black hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-10 h-10 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={closeModal}
                                data-modal-hide="popup-modal"
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                    </div>

                    <div className="w-full flex flex-col px-10 text-center">
                        <ImgComp src={Images.payment_sucess} className="w-[50%] mx-auto" />
                        <div className="text-4xl font-bold text-center mt-5">Thank You !</div>
                        <div className="text-base my-5 text-neutral-500">
                            Your Satisfaction is Our Priority - Watch Out for Delivery Updates!
                        </div>
                        <div className="text-base my-5 text-neutral-500">Order ID :- {orderId}</div>

                        <div>
                            {/* Redirect to Order View */}
                            <BgButton
                                name="View Order"
                                onClick={() => {
                                    closeModal();
                                    navigate('/profile', { state: { openOrderTab: true } }) // Adjust the URL as needed
                                }}
                            />
                        </div>
                        <div>
                            {/* Redirect to Continue Shopping */}
                            <OutlineButton
                                name="Continue Shopping"
                                onClick={() => {
                                    closeModal();
                                    navigate('/') // Adjust the URL as needed
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};


// export const Thankyou = ({ title, content, onClose, buttonContent, modalWidth, orderId }) => {

//     const [isOpen, setIsOpen] = useState(true);
//     const navigate = useNavigate();

//     const closeModal = () => {
//         setIsOpen(false);
//         onClose();
//     };

//     return (
//         <div
//             style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
//             onClick={closeModal}
//             //     className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full"
//             className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-hidden fixed right-0 left-0  z-50 justify-center items-center w-full md:inset-0 "
//         >

//             {/* <div
//                 // className=' bg-white rounded-2xl max-md:h-full w-full max-w-2xl zoom_in_pp_modal' 
//                 className={`relative  w-full ${modalWidth}  zoom_in_pp_modal`}
//                 onClick={(e) => e.stopPropagation()}
//             // style={{ margin: '2rem 0 !important' }}
//             > */}
//             <div
//                 // className={` bg-white w-full ${modalWidth}  rounded-2xl p-6 h-full overflow-y-auto mt-16 mb-16`}
//                 // className='my-16  bg-white overflow-y-auto p-6 rounded-2xl '
//                 className={`${modalWidth} bg-white overflow-y-auto p-6 rounded-2xl zoom_in_pp_modal max-sm:h-fit  overflow-y-auto max-h-full`}
//             // style={{ height: '-webkit-fill-available' }}
//             >

//                 <div className='flex items-center justify-center pb-5'>
//                     <div className=' text-2xl font-semibold w-full text-left '>

//                     </div>
//                     <div className='w-auto text-end '>
//                         <button type="button" className="bg-white text-black hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-10 h-10 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
//                             onClick={closeModal}
//                             data-modal-hide="popup-modal">
//                             <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
//                                 <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
//                             </svg>
//                             <span className="sr-only">Close modal</span>
//                         </button>
//                     </div>
//                 </div>

//                 <div className='w-full flex flex-col px-10 text-center'>

//                     <ImgComp
//                         src={Images.payment_sucess}
//                         className='w-[50%] mx-auto'
//                     />
//                     <div
//                         className='text-4xl font-bold text-center mt-5'
//                     >Thank You !</div>
//                     <div
//                             className='text-base my-5 text-neutral-500'
//                         >Your Satisfaction is Our Priority -Watch Outfor Delivery Updates!</div>
//                          <div
//                             className='text-base my-5 text-neutral-500'
//                         >
//                             Order ID :- {orderId}
//                         </div>
//                         <Link
//                             to={`/profile`}
//                             state={{ openOrderTab: true }}
//                         >
//                             <BgButton name={`View Order`} />
//                         </Link>
//                         <OutlineButton name={`Continue shopping`}
//                             onClick={() => navigate('/listing')}
//                         />
                       
//                         {/* <div>
//                             <BgButton name={`View Order`} />
//                         </div>
//                         <OutlineButton name={`Continue shopping`} /> */}
//                 </div>


//             </div>
//             {/* </div> */}
//         </div>
//     )
// }

export const AddressModalBox = ({ title, content, onClose, buttonContent, modalWidth, modalContent }) => {

    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    return (
        <>
            <div
                style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
                // onClick={closeModal}
                className="overflow-y-auto  max-h-full backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-hidden fixed right-0 left-0  z-50 justify-center items-center w-full md:inset-0 "
            >

                {/* <div
                 className={`relative flex items-center   zoom_in_pp_modal  max-md:max-h-full`}
                // className={`modals relative bg-white w-full ${modalWidth} max-w-xl max-h-full rounded-2xl zoom_in_pp_modal `}
                onClick={(e) => e.stopPropagation()}
            > */}
                <div
                    className={`${modalWidth} bg-white overflow-y-auto p-6 rounded-2xl zoom_in_pp_modal max-sm:h-fit  overflow-y-auto max-h-full`}
                // style={{ height: '-webkit-fill-available' }}
                >

                    <div className='flex items-center justify-center pb-5'>
                        <div className=' text-2xl font-semibold w-full text-left '>
                            {title}
                        </div>
                        <div className='w-auto text-end '>
                            <button type="button" className="bg-white text-black hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-10 h-10 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={closeModal}
                                data-modal-hide="popup-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                    </div>
                    <hr className='mb-5' />

                    <div className='w-full flex flex-col text-center max-sm:h-full overflow-y-auto'>
                        {modalContent}
                    </div>


                </div>
                {/* </div> */}
            </div>
        </>
    )
}