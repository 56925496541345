import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const PaymentRefundImgSection = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5 max-md:px-5`}>
                <div className='flex flex-row max-md:flex-col'>
                    <div className='my-auto mx-6'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            Payment & Refunds
                        </div>
                        <HeadingTextImageWrapper
                        text={`Payments & Refunds Policy`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />
                    
                        <div className="mt-6 text-lg max-md:max-w-full">
                        Welcome to{" "}
                            <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar (MyBzB)
                                </span>
                            ,  we understand the importance of a seamless and secure payment experience for our users. Here's a detailed guide to help you navigate through our payment options, ensuring convenience and trust with every purchase.
                        </div>
                    </div>    
                    <div className='footer-privacy-image'>
                        <ImgComp
                        src={Images.payment_refund}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default PaymentRefundImgSection