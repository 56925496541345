import { React, useState, useEffect } from 'react';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const ContactFormSection = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCity, setSelectedCity] = useState("Select");
    const [topicType, setTopicType] = useState("");
    const [otherType, setOtherType] = useState("");
    const [errors, setErrors] = useState({});
    const gujaratCities = [
        "Ahmedabad", "Surat", "Vadodara", "Rajkot", "Bhavnagar", "Jamnagar",
        "Gandhinagar", "Junagadh", "Anand", "Navsari", "Morbi", "Nadiad",
        "Surendranagar", "Bharuch", "Vapi", "Porbandar", "Mehsana", "Bhuj",
        "Gondal", "Veraval", "Patan", "Godhra", "Dahod", "Palanpur",
        "Valsad", "Amreli", "Deesa", "Jetpur", "Modasa", "Dholka",
        "Kalol", "Botad", "Kapadvanj", "Lunawada", "Mangrol", "Mahuva",
        "Khambhat", "Mansa", "Unjha", "Savarkundla", "Sihor"
    ];

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (city) => {
        setSelectedCity(city);
        setIsOpen(false);
    };

    const handleTopicChange = (e) => {
        setTopicType(e.target.value);
        if (e.target.value !== "other") {
            setOtherType(""); // Clear the otherType input if not selecting "Other"
        }
    };


    return (
        <div className={`w-full max-w-${width} max-md:max-w-full py-5 mb-16 mx-auto max-md:px-5`}>
            <div className="flex flex-row max-md:flex-col">

                {/* Left Section with Contact Info */}
                <div className="w-full my-auto col-md-6 p-6 max-md:w-full">
                    <div className='mybzb_text_clr font-bold uppercase mb-3'>
                        Contact Us
                    </div>
                    <HeadingTextImageWrapper
                        // text=" Our Products" 
                        // text=" Featured Item"
                        text={`Welcome to My Bharatiya Zaveri Bazaar`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                    />
                    <div className="mt-3 text-neutral-500 text-base max-md:max-w-full">
                        where we value your inquiries, feedback, and concerns. Our dedicated Customer Support team is here to assist you and ensure your experience with MyBzB is seamless and enjoyable.
                    </div>

                    <div className="grid grid-cols-1 gap-4 mt-12 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">

                        {/* Card for Email Support */}
                        <div className="flex p-5 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow space-x-4">
                            <div className="flex-shrink-0 flex items-center justify-center w-8 h-8 bg-red-100 rounded-full text-red-500">
                                <i className="fa fa-envelope text-md mybzb_text_clr" />
                            </div>

                            <div className="flex flex-col w-full">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-md font-semibold text-gray-800">Email Support</h2>
                                    <p className="text-sm text-gray-600">info@mybzb.com</p>
                                </div>
                                <p className="text-sm text-gray-500 mt-2">
                                    If you prefer written communication, you can reach out to us via email. Send us your questions, concerns, or feedback to the following email address, and our team will respond promptly.
                                </p>
                            </div>
                        </div>



                        {/* Card for Website */}
                        <div className="flex p-5 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow space-x-4">
                            <div className="flex-shrink-0 flex items-center justify-center w-8 h-8 bg-red-100 rounded-full text-red-500">
                                <i className="fa fa-globe mybzb_text_clr text-md" />
                            </div>

                            <div className="flex flex-col">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-md font-semibold text-gray-800">Website</h2>
                                    <p className="text-sm text-gray-600">www.mybzb.com</p>
                                </div>
                                <p className="text-sm text-gray-500 mt-2">
                                    If you prefer written communication, you can reach out to us via email. Send us your questions, concerns, or feedback to the following email address, and our team will respond promptly.
                                </p>
                            </div>
                        </div>




                        {/* Card for Phone Support */}
                        <div className="flex p-5 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow space-x-4">
                            <div className="flex-shrink-0 flex items-center justify-center w-8 h-8 bg-red-100 rounded-full text-red-500">
                                <i className="fa fa-phone mybzb_text_clr text-md" />
                            </div>

                            <div className="flex flex-col">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-md font-semibold text-gray-800">Contact</h2>
                                    <p className="text-sm text-gray-600">+91 9737600096</p>
                                </div>
                                <p className="text-sm text-gray-500 mt-2">
                                    If you prefer written communication, you can reach out to us via email. Send us your questions, concerns, or feedback to the following email address, and our team will respond promptly.
                                </p>
                            </div>
                        </div>


                        {/* Card for Address */}
                        <div className="flex p-5 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow space-x-4">
                            <div className="flex-shrink-0 flex items-center justify-center w-8 h-8 bg-red-100 rounded-full text-red-500">
                                <i className="fa fa-map-marker mybzb_text_clr text-md" />
                            </div>

                            <div className="flex flex-col">
                                <div className="flex items-center space-x-4">
                                    <h2 className="text-md font-semibold text-gray-800">Address</h2>
                                </div>
                                <p className="text-sm font-semibold text-gray-600">Headway Business Solutions LLP, Managing Partner</p>
                                <p className="text-sm text-gray-500 mt-2">
                                    101-Shree Kashi Parekh Complex, First Floor, Behind Bhagavati Chambers, Near Swastik Cross Rd, C G Road, Navrangpura, Ahmedabad, Gujarat 380009
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div
                    className="w-1/2 shadow-lg rounded-xl border-0 p-6 bg-white max-md:w-full"
                >
                    <div className="flex gap-5 max-lg:flex-col max-lg:gap-0 ">

                        <div className="flex flex-col max-lg:ml-0 max-lg:w-full ">
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col max-lg:ml-0 max-lg:w-full ">
                                    <section
                                        className="flex flex-col grow justify-between rounded-2xl items-center self-stretch w-full bg-white max-lg:px-5 max-lg:max-w-full"

                                    >

                                        <h1 className=" text-3xl leading-10 text-center">
                                            <span className="font-bold">How Can we</span> <span className="font-bold text-red-700">help?</span> </h1>

                                        <div className="flex gap-4 grid grid-cols-2 max-sm:grid-cols-1 w-full mt-2">
                                            <div className="flex flex-col">
                                                <div className="text-sm text-neutral-400">
                                                    First Name <span className="text-red-700">*</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-2 mt-1.5 text-sm rounded-lg border border-gray-200 border-solid text-neutral-900 ${errors.firstName ? 'border-red-500' : ''
                                                        }`}
                                                    placeholder="Enter First Name"
                                                    name="storeName"
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                <div className="text-sm text-neutral-400">
                                                    Last Name <span className="text-red-700">*</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-2 mt-1.5 text-sm rounded-lg border border-gray-200 border-solid text-neutral-900 ${errors.lastName ? 'border-red-500' : ''
                                                        }`}
                                                    placeholder="Enter Last Name"
                                                    name="storeName"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex gap-4 grid grid-cols-1 max-sm:grid-cols-1 w-full mt-2">
                                            <div className="flex flex-col">
                                                <div className="text-sm text-neutral-400">
                                                    Email
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-2 mt-1.5 text-sm rounded-lg border border-gray-200 border-solid text-neutral-900 ${errors.email ? 'border-red-500' : ''
                                                        }`}
                                                    placeholder="test@gmail.com"
                                                    name="email"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex gap-4 grid grid-cols-2 max-sm:grid-cols-1 w-full mt-2">
                                            <div
                                                className=" text-sm "
                                            >
                                                <label htmlFor="phoneNumber" className=" text-sm text-neutral-400" >
                                                    Phone Number
                                                </label>
                                                <div
                                                    className={`w-full focus:border-red-800 focus:ring-0 flex gap-1.5 items-start py-0 mt-1.5 px-2 text-base  rounded-lg border border-gray-200 border-solid text-zinc-800  ${errors.phoneNumber ? 'border-red-500' : ''
                                                        }`}>
                                                    <div className="flex w-full">

                                                        <div className="flex gap-1 items-center text-sm">
                                                            <div className="ps-2">+91</div>
                                                            <i className="fa fa-angle-down"></i>

                                                        </div>
                                                        <input
                                                            type="text"
                                                            name="phoneNumber"
                                                            inputMode="numeric"
                                                            pattern="[0-9]*"
                                                            onInput={(e) => (e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1").replace(/^0[^.]/, "0"))}
                                                            className={`w-full focus:border-red-800 focus:ring-0 justify-center ps-3.5 py-2 focus:border-0 text-sm rounded-lg border-0 text-neutral-900 `}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative">
                                                <div className="text-sm text-neutral-400">
                                                    City
                                                </div>
                                                {/* Selected Option */}
                                                <div
                                                    // className={`input-field focus:border-red-800 focus:ring-0 justify-center px-3.5 py-2 mt-1.5 text-sm rounded-lg border border-gray-200 border-solid text-neutral-600 ${errors.city ? 'border-red-500' : ''
                                                    // }`}
                                                    className="border border-gray-300 mt-1 rounded-lg px-3 py-2 text-sm cursor-pointer flex justify-between items-center"
                                                    onClick={toggleDropdown}
                                                >
                                                    <span className='text-sm text-neutral-700'>{selectedCity}</span>
                                                    <span className="material-icons text-neutral-400">arrow_drop_down</span>
                                                </div>

                                                {/* Dropdown Options */}
                                                {isOpen && (
                                                    <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-40 overflow-y-auto z-10">
                                                        {gujaratCities.map((city, index) => (
                                                            <div
                                                                key={index}
                                                                className="px-3 py-2 text-sm hover:bg-gray-200 cursor-pointer"
                                                                onClick={() => handleSelect(city)}
                                                            >
                                                                {city}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>

                                            {/* <div className="flex flex-col">
                                                <div className="text-sm text-neutral-400">
                                                    City
                                                </div>
                                                <select className={`input-field focus:border-red-800 focus:ring-0 justify-center px-3.5 py-2 mt-1.5 text-sm rounded-lg border border-gray-200 border-solid text-neutral-600 ${errors.city ? 'border-red-500' : ''
                                                    }`}>
                                                    <option className='text-sm text-neutral-400'>Select</option>
                                                    {gujaratCities.map((city, index) => (
                                                        <option key={index} value={city}>{city}</option>
                                                    ))}
                                                </select>
                                            </div> */}
                                        </div>
                                        <div className="flex gap-4 grid grid-cols-2 max-sm:grid-cols-1 w-full mt-2">
                                            <div className="flex flex-col">
                                                <div className="text-sm text-neutral-400">
                                                    Buyer Or Seller <span className="text-red-700">*</span>
                                                </div>
                                                <select className={`input-field focus:border-red-800 focus:ring-0 justify-center px-3.5 py-2 mt-1.5 text-sm rounded-lg border border-gray-200 border-solid text-neutral-600 ${errors.buyer ? 'border-red-500' : ''
                                                    }`}>
                                                    <option className='text-sm text-neutral-400'>Select</option>
                                                    <option className='text-sm text-neutral-400'>Buyer</option>
                                                    <option className='text-sm text-neutral-400'>Seller</option>
                                                </select>
                                            </div>

                                            <div className="flex flex-col">
                                                <div className="text-sm text-neutral-400">
                                                    Choose Topic <span className="text-red-700">*</span>
                                                </div>
                                                <select
                                                    name="topicType"
                                                    id="topicType"
                                                    value={topicType}
                                                    onChange={handleTopicChange}
                                                    className={`input-field focus:border-red-800 focus:ring-0 justify-center px-3.5 py-2 mt-1.5 text-sm rounded-lg border border-gray-200 border-solid text-neutral-600 ${errors.topic ? 'border-red-500' : ''
                                                        }`}>
                                                    <option className='text-sm text-neutral-400'>Select</option>
                                                    <option className='text-sm text-neutral-400'>Order</option>
                                                    <option className='text-sm text-neutral-400'>Inquiry</option>
                                                    <option className='text-sm text-neutral-400'>Need Support</option>
                                                    <option className='text-sm text-neutral-400'>Payment</option>
                                                    <option className='text-sm text-neutral-400'>Delivery</option>
                                                    <option className='text-sm text-neutral-400' value="other">Other</option>
                                                </select>
                                                {topicType === "other" && (
                                                    <input
                                                        type="text"
                                                        name="otherType"
                                                        id="otherType"
                                                        value={otherType}
                                                        onChange={(e) => setOtherType(e.target.value)}
                                                        placeholder="Please specify"
                                                        className="text-start focus:border-gray-200 focus:ring-0 focus:outline-0 h-12 px-3.5 py-3 mt-3 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex gap-3 grid grid-cols-1 max-sm:grid-cols-1 w-full mt-2">
                                            <div className="text-sm text-neutral-400">
                                                Tell Us what you need help with <span className="text-red-700">*</span>
                                            </div>
                                            <textarea
                                                type="number"
                                                className={`focus:border-red-800 focus:ring-0 justify-center px-3.5 py-2 text-sm rounded-lg border border-gray-200 border-solid text-neutral-900 ${errors.message ? 'border-red-500' : ''
                                                    }`}
                                                placeholder="type message here.."
                                                name="pincode"
                                                rows="6"
                                            />
                                            {errors?.message && <div className="text-red-500 text-sm">{errors?.message}</div>}
                                        </div>



                                        <button


                                            className="justify-center items-center self-center px-16 py-4 mt-9 max-w-full text-lg font-semibold text-center text-white whitespace-nowrap bg-red-700 rounded w-[228px] max-md:px-5">
                                            Submit
                                        </button>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ContactFormSection;
