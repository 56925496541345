import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const SellerGuideImgSection = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5 max-md:px-5`}>
                <div className='flex flex-row max-md:flex-col'>
                    <div className='my-auto mx-6'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            Seller Guide
                        </div>
                        <HeadingTextImageWrapper
                        // text=" Our Products" 
                        // text=" Featured Item"
                        text={`Guide For Sellers`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />
                    
                        <div className="mt-6 text-lg  max-md:max-w-full">
                        
                                Welcome to My Bharatiya Zaveri Bazaar (MyBzB), where we empower jewellers to seamlessly navigate our platform and showcase their exquisite creations to a global audience. This Platform Usage Guide is designed to provide sellers with comprehensive information on how to {" "}
                            <span className="font-semibold text-red-700">
                            effectively use MyBzB to maximize their online presence and grow their businesses.
                                </span>
                        </div>
                    </div>    
                    <div className='footer-seller-image'>
                        <ImgComp
                        src={Images.seller_guide}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default SellerGuideImgSection