import React, { useEffect } from 'react'
import Layout from '../../ReusableComp/Layout'
import { TwoBreadcrumb } from '../../ReusableComp/Breadcrumb'
import ContactUsContent from './ContactUsContent'

const ContactUs = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }, []);
    
    return (
        <>
            <Layout>
                <TwoBreadcrumb
                    href3={`/`}
                    name3={`Contact Us`}
                />

                <ContactUsContent />

            </Layout>
        </>
    )
}

export default ContactUs