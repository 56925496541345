import React, { useState } from 'react'
import Pagination from '../../../ReusableComp/Pagination';
import { OutlineButton } from "../../../ReusableComp/Buttons";

const JobsAtMyBzBContent = () => {
    const [activeTab, setActiveTab] = useState('Job Details');
    return (
        <div className="container mx-auto p-4 mb-16">
            <header className="text-center my-16">
                <h1 className="text-4xl font-bold">Job <span className="text-red-700">Listing</span></h1>
                <p className="text-gray-700 mt-2">Currently, no job opportunities are available.</p>
            </header>
            {/* <div className="flex justify-center mb-6">
                <div className="flex items-center bg-white shadow-md rounded-md w-full max-w-4xl">
                    <input type="text" placeholder="What position are you looking for ?" className="flex-grow p-4 border-r border-gray-200 focus:outline-none"/>
                    <select className="p-4 border-r border-gray-200 focus:outline-none">
                        <option>Location</option>
                    </select>
                    <button className="mybzb_bg_clr text-white px-6 py-4">Search job</button>
                </div>
            </div>
            <div className="flex justify-between mb-4">
                <div>
                    <p className="text-gray-700">Number of jobs found: 10</p>
                </div>
                <div>
                    <button className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md">Filter by</button>
                </div>
            </div>
            <div className="flex">
                <div className="w-3/4">
                    {[...Array(10)].map((_, index) => (
                        <div key={index} className="bg-white p-4 mb-4 shadow-md rounded-md flex">
                            <img src="https://placehold.co/100x100" alt="Company logo" className="w-16 h-16 rounded-full mr-4"/>
                            <div className="flex-grow">
                                <div className="flex justify-between items-center">
                                    <div>
                                        <p className="text-red-700 text-sm">Lorem Ipsum</p>
                                        <h2 className="text-xl font-bold">Full-stack Developer - Senior <span className="bg-red-100 text-red-700 text-xs px-2 py-1 rounded-md ml-2">New post</span></h2>
                                    </div>
                                    <div className="flex gap-3 my-auto" >
<button className='border border-red-700 text-red-700 px-4 py-2 rounded hover:text-white hover:bg-red-700'>View Job Details</button>
                    
                    </div>
                                </div>
                                <div className="flex items-center text-gray-500 text-sm mt-2">
                                    <i className="fas fa-map-marker-alt mr-2"></i> Ahmedabad, Gujarat, India
                                    <i className="fas fa-clock ml-4 mr-2"></i> Full time
                                    <i className="fas fa-calendar-alt ml-4 mr-2"></i> 29 min ago
                                </div>
                                <p className="text-gray-700 mt-2">Helps build and maintain both the front-end and the back-end of a website. <a href="#" className="text-red-700">Read more</a></p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-1/4 ml-4">
                    <div className="bg-white p-4 shadow-md rounded-md mb-4">
                        <h3 className="text-lg font-bold mb-2">📧 Email Us for jobs</h3>
                        <p className="text-gray-500 mb-4">Ut esse accusandae. Sit enim labore dolore. Aut re ea fugiat commodi ea eos.</p>
                        <input type="email" placeholder="name@email.com" className="w-full p-2 border border-gray-300 rounded-md mb-4"/>
                        <button className="mybzb_bg_clr text-white w-full py-2 rounded-md">Subscribe</button>
                    </div>
                    <div className="bg-white p-4 shadow-md rounded-md">
                        <h3 className="text-lg font-bold mb-2">🚀 Get noticed faster</h3>
                        <p className="text-gray-500 mb-4">Quis eiusmod deserunt ullam laboris magna cupidatat esse labore iure quis cupidatat in.</p>
                        <button className="mybzb_bg_clr text-white w-full py-2 rounded-md">Upload your resume</button>
                    </div>
                </div>
            </div>
            <div className='flex w-full justify-center items-center my-5'>
                                    <Pagination />
                                </div> */}
            {/* {(isEmptyObject(totalPages) || totalPages === null) ?
                        <></>
                        :
                        <>
                            {data?.length > 0 &&
                                <div className='flex w-full justify-center items-center my-5'>
                                    <Pagination
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            }
                        </>
                    } */}
        </div>
    );
}

export default JobsAtMyBzBContent