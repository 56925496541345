import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const GrievanceRedressalImgSection = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5 max-md:px-5`}>
                <div className='flex flex-row max-md:flex-col'>
                    <div className='my-auto mx-6'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            Grievance Redressal
                        </div>
                        <HeadingTextImageWrapper
                        // text=" Our Products" 
                        // text=" Featured Item"
                        text={`Grievance Redressal`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />
                    <div className="my-2  text-4xl font-bold capitalize text-neutral-900 max-md:text-3xl  home_heading  ">
                           Mechanism
                        </div>
                        <div className="mt-6 text-lg  max-md:max-w-full">
                        At{" "}
                            <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar (MyBzB)
                                </span>
                            ,  we are committed to providing fair treatment to our valued consumers and addressing their grievances promptly. Your satisfaction is our priority, and we have established a Grievance Redressal Mechanism to ensure a transparent and effective resolution process.
                        </div>
                    </div>    
                    <div className='footer-cancellation-image'>
                        <ImgComp
                        src={Images.grievance_redressal}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default GrievanceRedressalImgSection