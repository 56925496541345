//  const BASE_URL =  'http://192.168.1.12/mybzb/public'; // local URL
export const BASE_URL =  'https://api.mybzb.com';  //live URL

// const BASE_URL = 'http://127.0.0.1:8000/';

export const get = async (endpoint) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
  
export const post = async (endpoint, body) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const put = async (endpoint, body) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const remove = async (endpoint) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'DELETE',
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};


export const postFormData = async (endpoint, formData) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

const handleResponse = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
};

export const fetchData = async (endpoint, token = null) => {

  // if (!checkInternetConnection()) {
  //     // throw new Error('No internet connection');
  //     //   alert('No internet connection');
  //       return <CustomAlert message="No internet connection" />;

  // }
  const url = `${BASE_URL}/${endpoint}`;
  const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
  try {
    const response = await fetch(url, { headers });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await handleResponse(response);
  } catch (error) {
    throw error;
  }
};

export const addData = async (endpoint, data, token = null) => {
  const url = `${BASE_URL}/${endpoint}`;
  const headers = token ? { 'Authorization': `Bearer  ${token}` } : {};
  return fetch(url, {
    method: 'POST',
    headers: { 
      ...headers,
      'Content-Type': 'application/json', 
    },
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(
    );
};

export const deleteData = async (endpoint, id, token = null) => {
  const url = `${BASE_URL}/${endpoint}/${id}`;
  const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
  return fetch(url, {
    method: 'DELETE',
    headers,
  })
    .then(handleResponse)
    .catch(
    );
};

export const updateData = async (endpoint, id, newData, token = null) => {
  const url = `${BASE_URL}/${endpoint}/${id}`;
  const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
  return fetch(url, {
    method: 'PUT',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newData),
  })
    .then(handleResponse)
    .catch(
    );
};


export const fetchWithFormData = async (endpoint, formData, token = null) => {
  const url = `${BASE_URL}/${endpoint}`;

  try {
    const headers = token ? { 'Authorization': `Bearer ${token}` } : {};

    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow"
    };

    const response = await fetch(url, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};


export const fetchWithFormDataToken = async (endpoint, formdata, token = null) => {
  const url = `${BASE_URL}/${endpoint}`;

  try {
    const headers = { 'Authorization': `Bearer ${token}` }

    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: headers, 
      redirect: "follow"
    };

    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchDataWithToken = async (endpoint, token = null,) => {

  const url = `${BASE_URL}/${endpoint}`;
  const headers = { 'Authorization': `Bearer ${token}` };
  try {
    const response = await fetch(url, { headers });
     if (!response.ok) {
       throw new Error('Network response was not ok');
     }
     return await handleResponse(response);
  //   if (!response.ok) {
  //     if (response.status === 401) {
  //         window.location.href = '/register';
  //     } else {
  //         throw new Error('Network response was not ok');
  //     }
  // }
  // return await handleResponse(response);
  } catch (error) {
    throw error;
  }
};

export const fetchWithFormDataWithoutBody = async (endpoint, token = null) => {
  const url = `${BASE_URL}/${endpoint}`;

  try {
    const headers = { 'Authorization': `Bearer ${token}` };
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: headers,
    };

    const response = await fetch(url, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};
