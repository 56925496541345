import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const ShippingTermsImgSection = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5 max-md:px-5`}>
                <div className='flex flex-row max-md:flex-col'>
                    <div className='my-auto mx-6'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            Shipping
                        </div>
                        <HeadingTextImageWrapper
                        text={`Shipping Terms`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />
                    
                        <div className="mt-6 text-lg  max-md:max-w-full">
                        
                                Welcome to{" "}
                            <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar (MyBzB)
                                </span>
                                , where we are dedicated to providing you with a delightful and reliable shopping experience. Our shipping terms are designed to ensure that your cherished jewellery reaches you promptly, securely, and in perfect condition. Below, you'll find detailed information about our shipping processes and terms:
                        </div>
                    </div>    
                    <div className='footer-shipping-image'>
                        <ImgComp
                        src={Images.shipping_policy}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default ShippingTermsImgSection