import React from 'react';
import { useNavigate } from 'react-router-dom';


const BuildBrandTextSection = () => {
    const navigate = useNavigate();

    const handleContact = () => {
        navigate('/contact');
    };
    return (
        <>
            <div className="flex flex-col ">
            <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                    Discover the opportunities that await you as we provide a platform designed to elevate your brand, connect with customers, and unlock exclusive benefits for your business.
                    </div>
                </div>
                <div className="flex flex-col mt-12 max-md:mt-10 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Why Choose MyBzB to </span> Build Your Jewellery Brand?
                    </div>
                    <div className="w-full flex flex-col grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Global Exposure:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                        <span className="font-semibold text-red-700">MyBzB</span> offers jewellers a global stage to showcase their craftsmanship. Reach customers worldwide and expand the reach of your brand beyond geographical boundaries.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>

                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>User-Friendly Platform:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Our intuitive and user-friendly platform makes it easy for you to create and manage your brand presence. List your jewellery, customize your storefront, and engage with customers effortlessly.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Professional Presentation:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Present your jewellery in the best light with our professional and visually appealing product listings. High-quality images and detailed descriptions showcase the elegance and craftsmanship of your creations.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col p-6 bg-white rounded-xl border border-gray-200 border-solid  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Fulfilment by MyBzB:</span>
                                    </div>
                                    <div className="flex-1 shrink gap-2.5 mt-3 w-full text-base text-zinc-600">
                                    Leverage our Fulfilment by {" "}<span className="font-semibold text-red-700">MyBzB</span> program to streamline your order fulfilment process. Let us handle the logistics, allowing you to focus on crafting stunning pieces for your customers.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 max-md:mt-10 w-full max-md:max-w-full">
                <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Exclusive Benefits for</span> Sellers
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                    Unlock additional benefits to grow your business by partnering with{" "}<span className="font-semibold text-red-700">MyBzB</span>. As the managing partner of{" "}<span className="font-semibold text-red-700">MyBzB</span>,{" "}<span className="font-semibold text-red-700">Headway Business Solutions LLP</span>, a renowned name in jewellery business brand building, coaching, and consulting, brings a wealth of expertise to our platform.
                    </div>
                    <div className="text-2xl mt-4 font-semibold text-red-700">
                        <span className="text-neutral-900">Benefit from </span> Headway Business Solutions LLP
                    </div>
                    <div className="w-full flex flex-col grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-5 justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Brand Building Expertise:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    <span className="font-semibold text-red-700">MyBzB</span> offers jewellers a global stage to showcase their craftsmanship. Reach customers worldwide and expand the reach of your brand beyond geographical boundaries.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>

                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Coaching and Consulting:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Our intuitive and user-friendly platform makes it easy for you to create and manage your brand presence. List your jewellery, customize your storefront, and engage with customers effortlessly.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Exclusive Workshops and Webinars:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Participate in exclusive workshops and webinars conducted by{" "}<span className="font-semibold text-red-700">Headway Business Solutions LLP</span>. Gain valuable insights into the latest industry trends, marketing strategies, and business growth tactics.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col p-6 bg-white rounded-xl border border-gray-200 border-solid  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Strategic Guidance:</span>
                                    </div>
                                    <div className="flex-1 shrink gap-2.5 mt-3 w-full text-base text-zinc-600">
                                    Receive strategic guidance tailored to your brand's unique goals and challenges. Navigate the evolving landscape of the jewellery market with confidence.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 max-md:mt-10 w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">How to </span> Get Started
                    </div>
                    <div className="w-full flex flex-col grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Register as a Seller:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Join the MyBzB community by registering as a seller. Provide accurate information to create your account and start building your brand.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>

                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>List Your Jewellery:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    Showcase your creations by creating detailed and captivating product listings. Highlight the uniqueness and craftsmanship that sets your brand apart.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Explore Exclusive Benefits:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                    As a {" "}<span className="font-semibold text-red-700">MyBzB</span> seller, explore the exclusive benefits offered through our partnership with{" "}<span className="font-semibold text-red-700">Headway Business Solutions LLP</span>. Elevate your brand and accelerate your business growth.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold">
                        <span className="text-neutral-900">Contact</span> Us
                    </div>
                    <div className='flex flex-row mt-5 items-start gap-2 '>
                        <i className="fa fa-circle-o mybzb_text_clr mt-1 text-xs" ></i>
                        <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                        or inquiries or to learn more about building your jewellery brand with{" "}<span className="font-semibold text-red-700">MyBzB</span> and accessing exclusive benefits from{" "}<span className="font-semibold text-red-700">Headway Business Solutions LLP</span>, please contact our Seller Support team through the <span className="font-semibold text-red-700">'Contact Us'</span>{" "}page.
                        </div>
                    </div>
                    <div className="mt-4 text-lg max-md:max-w-full text-neutral-900">
                    <span className="font-semibold text-red-700">My Bharatiya Zaveri Bazaar</span> is excited to be your partner in building a successful and globally recognized jewellery brand. Join us in the journey of creating beauty, elegance, and lasting impressions with your unique creations. Happy crafting and selling!
                    </div>
                </div>
                <div className="flex gap-5 mt-6 me-4 mb-16 my-auto" >
                    <button className="focus:border-red-800 justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 me-2" onClick={handleContact}>
                        Let's Build Your Brand<i className="fa fa-arrow-right ml-2" style={{ color: '#ffffff' }}></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default BuildBrandTextSection