import React from 'react';
import { useNavigate } from 'react-router-dom';


const ShippingTermsTextSection = () => {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/');
    };
    return (
        <>

            <div className="flex flex-col ">
                <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="flex flex-col w-full max-md:max-w-full gap-5">
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-2xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; Order Processing
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                               <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Once you place an order on{" "}
                                <span className="font-semibold text-red-700">MyBzB</span>, our team initiates the order processing immediately.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Order processing times may vary based on the nature of the product, its availability, and any customization requirements.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-2xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Shipping Destinations
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                <span className="font-semibold text-red-700">MyBzB</span> offers shipping to a wide range of destinations globally.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Shipping costs and delivery times may vary depending on your location.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">3.</span>&nbsp; Shipping Methods
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                We utilize reliable and reputable shipping carriers to ensure the safe and timely delivery of your jewellery.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Multiple shipping methods may be available, and you can choose the one that suits your preferences during the checkout process.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">4.</span>&nbsp; Estimated Delivery Times
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                The estimated delivery time for each product is displayed on the product page.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Actual delivery times may vary based on factors such as location, shipping method, and any unforeseen circumstances.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">5.</span>&nbsp; Shipping Notifications
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Once your order is shipped, you will receive a confirmation email containing tracking information.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                You can use this information to monitor the status and expected delivery date of your order.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">6.</span>&nbsp; Shipping Charges
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Shipping charges are calculated based on factors such as the shipping destination, weight of the package, and chosen shipping method.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                The shipping charges will be displayed during the checkout process for your review before completing the purchase.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">7.</span>&nbsp; Customs and Import Duties
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                For international orders, customs and import duties may be applicable.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                These charges are the responsibility of the customer and will be determined by the customs authority in the destination country.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">8.</span>&nbsp; Shipping Insurance
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                To ensure the safety of your valuable jewellery, <span className="font-semibold text-red-700">MyBzB</span> may include shipping insurance for certain orders.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                This insurance provides coverage against loss or damage during transit.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">9.</span>&nbsp; Order Tracking
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                You can track your order in real-time using the provided tracking information.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                For any assistance with tracking or if you have concerns about your delivery, please reach out to our Customer Care team.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">10.</span>&nbsp; Shipping Partners
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                <span className="font-semibold text-red-700">MyBzB</span>{" "}collaborates with trusted shipping partners to provide a secure and reliable delivery experience.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                We continuously evaluate and update our shipping partnerships to ensure the highest standards of service.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">11.</span>&nbsp; Shipping to Multiple Addresses
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                At this time, we do not offer shipping to multiple addresses within a single order. Each order can only be shipped to one address.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">12.</span>&nbsp; Delayed Shipments
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                In the rare event of a delayed shipment, we apologize for any inconvenience and assure you that we will make every effort to expedite the process.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold">
                        <span className="text-red-700">Contact Us </span>
                    </div>
                    <div className='flex flex-row mt-5 items-start gap-2 '>
                        <i className="fa fa-circle-o mybzb_text_clr mt-1 text-xs" ></i>
                        <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                        For inquiries or to learn more about building your jewellery brand with <span className="font-semibold text-red-700">MyBzB</span>{" "}and accessing exclusive benefits from <span className="font-semibold text-red-700">Headway Business Solutions LLP</span>{" "}, please contact our Seller Support team through the <span className="font-semibold text-red-700">'Contact Us'</span>{" "}page.
                        </div>
                    </div>
                    <div className="mt-4 text-lg text-base text-zinc-600 max-md:max-w-full">
                    <span className="font-semibold text-red-700">My Bharatiya Zaveri Bazaar</span> is committed to delivering not just jewellery but a delightful experience to your doorstep. Thank you for choosing <span className="font-semibold text-red-700">MyBzB</span> for your jewellery needs. <span className="font-semibold text-red-700">Happy shopping!</span> 
                    </div>
                </div>
                <div className="flex gap-5 mt-6 me-4 mb-16 my-auto" >
                    <button className="focus:border-red-800 justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 me-2" onClick={handleHome}>
                        Back to Home<i className="fa fa-arrow-right ml-2" style={{ color: '#ffffff' }}></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default ShippingTermsTextSection