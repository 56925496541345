import React, {useEffect} from 'react'
import Layout from '../../ReusableComp/Layout'
import AboutMyBzBContent from './AboutMyBzBContent'
import { TwoBreadcrumb } from '../../ReusableComp/Breadcrumb'
import { width } from '../../ReusableComp/Buttons'

const AboutMyBzB = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }, []);
    
    return (
        <>
            <Layout>
                <TwoBreadcrumb
                    href3={`/`}
                    name3={`About Us`}
                />

                <AboutMyBzBContent />

            </Layout>
        </>
    )
}

export default AboutMyBzB
