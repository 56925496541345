import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const FraudWarningImgSection = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5 max-md:px-5`}>
                <div className='flex flex-row max-md:flex-col'>
                    <div className='my-auto mx-6'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            Fraud Warning Disclaimer
                        </div>
                        <HeadingTextImageWrapper
                        // text=" Our Products" 
                        // text=" Featured Item"
                        text={`Fraud Warning Disclaimer`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />
                    <div className="mt-6 text-lg  max-md:max-w-full">
                        Dear Valued Customer,
                        </div>
                        <div className="mt-6 text-lg  max-md:max-w-full">
                        Welcome to{" "}
                            <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar (MyBzB)
                                </span>
                            , we prioritize the safety and security of our community. It has come to our attention that certain individuals, falsely presenting themselves as our employees, affiliates, agents, or representatives, may attempt to engage with you under fraudulent pretenses.
                        </div>
                    </div>    
                    <div className='footer-fraud-warning-image'>
                        <ImgComp
                        src={Images.fraud_warning_disclaimer}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default FraudWarningImgSection