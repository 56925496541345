import React, { useEffect, useState } from 'react'
import NavbarComponent from './NavbarComp'
import Footer from './Footer'
import { SearchProvider } from '../SearchContext';



const Layout = ({ children }) => {

    const width = "[1296px]";
    const navbarHeight = '150px';


    const [isScrolled, setIsScrolled] = useState(false);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
            // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
                setAnimate(true);
            } else {
                setIsScrolled(false);
                setAnimate(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    

    return (
        <>
            {/* <SearchProvider> */}
            
            <NavbarComponent />
                <div
                //  style={{ paddingTop: navbarHeight }}
                // style={{
                //     paddingTop: navbarHeight,
                //     minHeight: `calc(100vh - ${navbarHeight})`,
                //   }}
                 >
                    {children}
                </div>
                <Footer width={width} />
            {/* </SearchProvider> */}
        </>
    )
}

export default Layout