import React from 'react';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const AboutImgSection = () => {
    return (
        <>
            <div>
            <div className={`w-full max-w-${width} max-md:max-w-full py-5 mb-16 mx-auto max-md:px-5`}>
                <div className='flex justify-center items-center text-center flex-row max-md:flex-col'>
                    <div className='my-auto'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            About Us
                        </div>
                        <HeadingTextImageWrapper
                        // text=" Our Products" 
                        // text=" Featured Item"
                        text={`About My Bharatiya Zaveri Bazaar`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-center"
                        />
                    </div>    
                </div>
                <div className="flex  flex-col my-auto mx-6">
        <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
            <div className="text-xl font-semibold">
            Discover the Essence of Bhartiya Culture in Jewellery
            </div>
            <div className="mt-3 text-neutral-500 text-base max-md:max-w-full">
            Welcome to India's first My Bhartiya Zaveri Bazaar Retail Culture Jewellery Store. We are a family jeweler dedicated to preserving and celebrating the rich heritage of our beloved nation through exquisite gold, silver, diamond, and platinum Jewellery. With us, you'll find limited jewellers but unlimited designs. 
            </div>
            <div className="mt-3 text-neutral-500 text-base max-md:max-w-full">
            Experience the rich heritage of India's diverse culture through our exquisite collection of gold, silver, diamond, and platinum jewellery. With My Bhartiya Zaveri Bazar, we bring you the perfect blend of traditional craftsmanship and contemporary designs. 
            </div>
        </div>
        <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
            <div className="text-xl font-semibold">
            Limited Jewellers, Unlimited Design
            </div>
            <div className="mt-3 text-neutral-500 text-base max-md:max-w-full">
            At My Bhartiya Zaveri Bazar, we believe in offering you a curated selection of jewellery pieces that are unique and exceptional. Our collection is carefully crafted by skilled artisans to ensure each piece tells a story and reflects your individuality.
            </div>
            <div className="mt-3 text-neutral-500 text-base max-md:max-w-full">
            Join us for India's first My Bhartiya Zaveri Bazar Retail Culture Jewellery Show and discover the beauty of Bhartiya culture through jewellery.
            </div>
        </div>
        <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
            <div className="text-xl font-semibold">
            From Traditional to Modern
            </div>
            <div className="mt-3 text-neutral-500 text-base max-md:max-w-full">
            From traditional temple jewellery to contemporary statement pieces, we have something for every occasion. Indulge in the brilliance of diamonds with our exquisite diamond jewellery collection.
            </div>
        </div>
        <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
            <div className="text-xl font-semibold">
            Immerse Yourself in the Beauty of Bhartiya Jewellery
            </div>
            <div className="mt-3 text-neutral-500 text-base max-md:max-w-full">
            At Suvarna Saubhagya Utsav, we believe that every piece of Jewellery tells a story. Explore our stunning collection that showcases the craftsmanship and artistry of Bhartiya culture. From intricately designed necklaces to sparkling rings, each piece is a testament to our rich heritage.
            </div>
        </div>
    </div>
            </div>
            </div>
        </>
    )
}

export default AboutImgSection