import React from 'react';
import { useNavigate } from 'react-router-dom';


const PurchaseProtectionTextSection = () => {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/');
    };
    return (
        <>

            <div className="flex flex-col ">
                <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="flex flex-col w-full max-md:max-w-full gap-5">
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-2xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; What is Purchase Protection?
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                <span className="font-semibold text-red-700">MyBzB's</span> Purchase Protection is a commitment to safeguard your purchases, offering you peace of mind when shopping for exquisite jewellery.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-2xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Eligibility Criteria
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                All eligible purchases made on {" "}<span className="font-semibold text-red-700">MyBzB</span> are covered by Purchase Protection.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Eligibility is determined based on the product, nature of the purchase, and compliance with our Purchase Protection terms.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">3.</span>&nbsp; Coverage Period
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Purchase Protection coverage begins from the date of delivery and extends for a specified period, ensuring ample time for you to assess and enjoy your jewellery.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">4.</span>&nbsp; Eligible Situations
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Purchase Protection covers situations such as damaged or defective items, items significantly different from the product description, and non-receipt of items.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">5.</span>&nbsp; How to File a Purchase Protection Claim
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                In the event of an issue, log in to your MyBzB account and navigate to the 'Order History' section.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Locate the specific order and click on {" "} <span className="font-semibold text-red-700">'File a Claim.'</span>
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Follow the provided instructions to submit your claim.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">6.</span>&nbsp; Claim Assessment
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                   Our dedicated Purchase Protection team will assess the submitted claim promptly.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Clear and detailed documentation, including images and descriptions, may be requested for a thorough assessment.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">7.</span>&nbsp; Resolution Process
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                If the claim is validated, <span className="font-semibold text-red-700">MyBzB</span>{" "} will initiate the necessary steps for resolution, which may include refunds, replacements, or other suitable solutions.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">8.</span>&nbsp; Refund Process
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Refunds for valid Purchase Protection claims are processed within a specified timeframe, providing you with a seamless resolution.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">9.</span>&nbsp; Non-Eligible Situations
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Some situations may not be eligible for Purchase Protection, such as buyer's remorse or issues arising after the coverage period.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">10.</span>&nbsp; Continuous Monitoring
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr mt-1 text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                <span className="font-semibold text-red-700">MyBzB</span>{" "}continuously monitors Purchase Protection claims to identify trends and implement improvements in our processes, ensuring a reliable and secure shopping environment.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">11.</span>&nbsp; Contact Us
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    For any questions or assistance related to Purchase Protection, please contact our Customer Care team through the <span className="font-semibold text-red-700">'Contact Us'</span>{" "}page.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold">
                        <span className="text-red-700">Our Commitment to You</span>
                    </div>
                    <div className='flex flex-row mt-5 items-center gap-2 '>
                        <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                        <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                        <span className="font-semibold text-red-700">My Bharatiya Zaveri Bazaar</span>{" "}is committed to ensuring that your jewellery shopping experience is not only delightful but also protected. 
                        </div>
                    </div>
                    <div className='flex flex-row mt-5 items-center gap-2 '>
                        <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                        <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                        Purchase Protection is a testament to our dedication to your satisfaction and trust in every purchase. 
                        </div>
                    </div>
                    <div className="mt-4 text-lg text-base text-zinc-600 max-md:max-w-full">
                    Thank you for choosing <span className="font-semibold text-red-700">MyBzB</span> for your jewellery needs. We look forward to providing you with a secure and enjoyable shopping experience.{" "}<span className="font-semibold text-red-700">Happy shopping!</span>
                    </div>
                </div>
                <div className="flex gap-5 mt-6 me-4 mb-16 my-auto" >
                    <button className="focus:border-red-800 justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 me-2" onClick={handleHome}>
                        Back to Home<i className="fa fa-arrow-right ml-2" style={{ color: '#ffffff' }}></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default PurchaseProtectionTextSection