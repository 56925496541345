import { React, useState } from 'react';
import { TransperentButton, width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';
import { useNavigate } from 'react-router-dom';


const FAQAccordianSection = () => {

    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    const handlePolicy = () => {
        navigate('/privacy-policy');
    };
    const handleSupport = () => {
        navigate('/contact');
    };

    const faqData = [
        {
            question: "What is MyBzB?",
            answer: "This is a great space to share more information about My Bharatiya Zaveri Bazar. We are a premier online destination for high-quality gems and Jewellery, sourced from the finest artisans and craftsmen in India. Our collection includes a wide range of designs, from traditional to modern, and we pride ourselves on offering exceptional customer service and unparalleled quality.",
        },
        {
            question: "How can I place an order with My Bharatiya Zaveri Bazar?",
            answer: "At My Bharatiya Zaveri Bazar, we make it easy for you to place an order. You can browse our collection of exquisite gems and jewelry online and add the items you like to your cart. Once you're ready to checkout, simply follow the prompts to complete your purchase.",
        },
        {
            question: "Can I get assistance?",
            answer: "If you have any questions or need assistance, our customer service team is always here to help. Contact us at info@mybzb.com",
        },
        {
            question: "Can you explain Payment and Shipping?",
            answer: "At My Bharatiya Zaveri Bazar, we offer multiple payment options to make your shopping experience as convenient as possible. You can pay for your order using a credit or debit card, net banking, or cash on delivery. We also offer free shipping on all orders within India.",
        },
        {
            question: "How can I order securely?",
            answer: "At My Bharatiya Zaveri Bazar, we take the security of your personal and financial information seriously. That's why we use the latest encryption technology to protect your data and offer multiple payment options to ensure that your transactions are safe and secure.",
        },
        {
            question: "What about Returns & Refunds?",
            answer: "We want you to be completely satisfied with your purchase from My Bharatiya Zaveri Bazar. If for any reason you are not happy with your order, we offer hassle-free returns and refunds. Simply contact our customer service team within 15 days of receiving your order to initiate a return.",
        },
        {
            question: "How is my privacy intact?",
            answer: "At My Bharatiya Zaveri Bazar, we respect your privacy and are committed to protecting your personal information. Our privacy policy outlines the measures we take to safeguard your data and ensure that it is used only for the purposes for which you provided it.",
        },
    ];

    return (
        <>
            <div>
                <div className={`w-full max-w-${width} max-md:max-w-full py-5 mb-16 mx-auto`}>
                    <div className='flex flex-row max-md:flex-col'>
                        <div className='my-auto col-md-6 p-16'>
                            <div className='mybzb_text_clr font-bold uppercase mb-3'>
                                Help & FAQs
                            </div>
                            <HeadingTextImageWrapper
                                // text=" Our Products" 
                                // text=" Featured Item"
                                text={`Frequently Asked Questions`}
                                className="w-auto "
                                textClassName=' '
                                alignTextWrapperClassName="text-left"
                            />
                            <div className="mt-3 text-neutral-500 text-base max-md:max-w-full">
                                Aenean quis est erat. Pellentesque pretium convallis ligula, vitae euismod nisi vehicula non.
                                In felis leo, faucibus vel sagittis pharetra, varius ullamcorper quam.
                            </div>
                            <div className='faq-buttons flex flex-row'>
                                <div className="flex gap-5 mt-6 me-4 my-auto" >
                                    <button className="focus:border-red-800 justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 me-2" onClick={handleSupport}>
                                        <i className="fa fa-headphones me-1" style={{fontSize:'22px'}}></i> Support
                                    </button>
                                </div>
                                <div className="flex gap-5 mt-6 my-auto" >
                                    <TransperentButton className="text-danger border border-dange" name={`Privacy Policy`} onClick={handlePolicy} />
                                </div>
                            </div>
                        </div>
                        <div className="accordion col-md-6 shadow-sm border-0" id="faqAccordion">
                            <div className="faq-container">
                                {faqData.map((item, index) => (
                                    <div key={index} className="faq-item">
                                        <div
                                            className="faq-question"
                                            onClick={() => handleToggle(index)}
                                        >
                                            {item.question}
                                            <span className={`faq-icon ${activeIndex === index ? 'open' : ''}`}>
                                                {activeIndex === index ? '-' : '+'}
                                            </span>
                                        </div>
                                        {activeIndex === index && (
                                            <div className="faq-answer">
                                                {item.answer}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default FAQAccordianSection