import React from 'react';
import * as Images from '../../../../assets';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';

const SellerTermsImgSection = () => {
    return (
        <>
            <div className="bg-rose-50">
            <div className={`w-full max-w-${width} max-md:max-w-full mx-auto py-5 max-md:px-5`}>
                <div className='flex flex-row max-md:flex-col'>
                    <div className='my-auto mx-6'>
                        <div className='mybzb_text_clr font-bold uppercase mb-3'>
                            Seller Terms
                        </div>
                        <HeadingTextImageWrapper
                        // text=" Our Products" 
                        // text=" Featured Item"
                        text={`Terms For Sellers`}
                        className="w-auto "
                        textClassName=' '
                        alignTextWrapperClassName="text-left"
                        />
                        <div className="my-2  text-4xl font-bold capitalize text-neutral-900 max-md:text-3xl  home_heading  ">
                            (Jewellers) At myBzB
                        </div>
                    
                        <div className="mt-6 text-lg  max-md:max-w-full">
                            Welcome to {" "}
                            <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar (MyBzB)
                                </span>, where we value the creativity and craftsmanship of jewellers. Our platform provides a unique {" "}
                            <span className="font-semibold text-red-700">
                            opportunity for jewellers to showcase and sell their jewellery to a global audience. 
                                </span>To ensure a transparent and mutually beneficial partnership, we have outlined the terms for sellers on MyBzB below:
                        </div>
                    </div>    
                    <div className='footer-seller-image'>
                        <ImgComp
                        src={Images.seller_terms}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default SellerTermsImgSection