import React from 'react';
import { useNavigate } from 'react-router-dom';


const SellerTermsTextSection = () => {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/');
    };
    return (
        <>

            <div className="flex flex-col ">
                <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="flex flex-col w-full max-md:max-w-full gap-5">
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-2xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; Seller Registration
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                To become a seller on {" "}
                                <span className="font-semibold text-red-700">MyBzB</span>, jewellers must complete the registration process, providing accurate and verifiable information.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Sellers are required to comply with our eligibility criteria and adhere to the standards set by {" "}
                                    <span className="font-semibold text-red-700">MyBzB</span>.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-2xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Product Listings
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Jewellers are responsible for creating accurate and detailed product listings, including high-quality images, precise descriptions, and other relevant information.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Listings must comply with {" "}
                                <span className="font-semibold text-red-700">MyBzB's</span> content policies and guidelines.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">3.</span>&nbsp; Pricing and Fees
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Sellers have the autonomy to set their own prices for the jewellery they list on{" "}
                                    <span className="font-semibold text-red-700">MyBzB</span>.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-semibold text-red-700">MyBzB</span>{" "}may charge a commission or other fees, the details of which are specified in the seller agreement.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">4.</span>&nbsp; Order Fulfilment
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Sellers are responsible for efficiently fulfilling orders, including timely shipping, professional packaging, and accurate order processing.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Adherence to {" "}
                                <span className="font-semibold text-red-700">MyBzB's</span> Fulfilment by {" "}
                                <span className="font-semibold text-red-700">MyBzB</span> programme is encouraged for a streamlined fulfilment process.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">5.</span>&nbsp; Communication with Buyers
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Jewellers are expected to maintain clear and open communication with buyers throughout the order process.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Respond promptly to buyer inquiries, concerns, and feedback.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">6.</span>&nbsp; Quality Standards
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-semibold text-red-700">MyBzB</span>{" "}upholds high-quality standards, and sellers are expected to deliver jewellery that meets these standards.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                In case of defective or substandard items, sellers may be subject to corrective actions as outlined in the seller agreement.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">7.</span>&nbsp; Intellectual Property
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Sellers must ensure that they have the legal rights to the images, descriptions, and other content used in their listings.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-semibold text-red-700">MyBzB</span>{" "}respects intellectual property rights and requires sellers to do the same.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">8.</span>&nbsp; Returns and Refunds
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Sellers must abide by <span className="font-semibold text-red-700">MyBzB's</span> Returns and Refunds policy.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Clearly communicate the return policy to buyers and promptly process eligible returns and refunds.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">9.</span>&nbsp; Account Security
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Sellers are responsible for maintaining the security of their {" "}
                                <span className="font-semibold text-red-700">MyBzB</span> accounts.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Report any unauthorized access or suspicious activities to {" "}<span className="font-semibold text-red-700">MyBzB's</span> support team.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">10.</span>&nbsp; Compliance with Laws
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Sellers must comply with all applicable laws and regulations, both regionally and internationally.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                This includes adherence to tax regulations, export/import laws, and product safety standards.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">11.</span>&nbsp; Termination of Partnership
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-semibold text-red-700">MyBzB</span>{" "}reserves the right to terminate the partnership with sellers who violate our terms or fail to meet our standards.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Sellers may terminate their association with {" "}
                                <span className="font-semibold text-red-700">MyBzB</span> by following the agreed-upon termination process.
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">12.</span>&nbsp; Updates and Changes
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    <span className="font-semibold text-red-700">MyBzB</span>{" "}may update and revise the terms for sellers periodically.
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                Sellers will be notified of any changes, and continued use of the platform implies acceptance of the updated terms.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold">
                        <span className="text-red-700">Contact Us </span>
                    </div>
                    <div className='flex flex-row mt-5 items-center gap-2 '>
                        <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                        <div className="w-full text-base text-zinc-600 max-md:max-w-full">
                        For any questions or clarifications regarding the terms for sellers, please contact our Seller Support team through the  <span className="font-semibold text-red-700">'Contact Us'</span>{" "}page.
                        </div>
                    </div>
                    <div className="mt-4 text-lg text-base text-zinc-600 max-md:max-w-full">
                    <span className="font-semibold text-red-700">My Bharatiya Zaveri Bazaar</span> looks forward to partnering with passionate and talented jewellers. Together, let's create a vibrant marketplace that celebrates the artistry and diversity of the jewellery world. Happy selling!
                    </div>
                </div>
                <div className="flex gap-5 mt-6 me-4 mb-16 my-auto" >
                    <button className="focus:border-red-800 justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1 me-2" onClick={handleHome}>
                        Back to Home<i className="fa fa-arrow-right ml-2" style={{ color: '#ffffff' }}></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default SellerTermsTextSection